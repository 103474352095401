//#region imports
import { Popup, SelectBox, TextArea, TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, { Column, ColumnChooser, DataGridRef, Editing, Export, FilterRow, GroupItem, HeaderFilter, Pager, Paging, Search, SearchPanel, SortByGroupSummaryInfo, Summary } from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FilePicker from "../../../../components/filePicker/filePicker";
import Permission from "../../../../components/permission/permision";
import getTitle from "../../../../components/title/getTitle";
import { CollisionPermissions, TMSPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { AuthApiUrl, CollisionApiUrl } from "../../../../environment/routeSettings";
import { ImportXmlRequestTask, XmlImportSetting } from "../../../../types/collision/collisionTypes";
import { XMLImportAction } from "../../../../types/collision/enums/collisionEnums";
import { VMStorage } from "../../../../types/fileManagement/dto/fileManagementDTO";
import { NameValue } from "../../../../types/general/generalTypes";
import { VMFindUser } from "../../../../types/identity/dto/identityDTO";
import { OnExporting, calculateFileTypeCell } from "../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../utils/enumTools";
import { TesGet, TesPost } from "../../../../utils/rest";

//#endregion imports
const XMLImport = () => {
  //#region consts
  const [initDataFieldSettings, setInitDataFieldSettings] = useState<XmlImportSetting[]>([]);
  const [selectedData, setSelectedData] = useState<ImportXmlRequestTask>(new ImportXmlRequestTask());
  const [emails, setEmails] = useState<string>("");
  const [lstLastFiles, setLstLastFiles] = useState<VMStorage[]>([]);
  const [lstXMLImportAction, setLstXMLImportAction] = useState<NameValue[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [initDataUsers, setInitDataUser] = useState<VMFindUser[]>([]);
  const [showImportMessage, setShowImportMessage] = useState<boolean>(false);
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  //#endregion consts

  //#region functions
  const title = getTitle('/collision/import/xml/xmlImport', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getFieldSettings();
        setLstXMLImportAction(Enum2Array(XMLImportAction))
        getInitDataUsers();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }

    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  async function getFieldSettings() {
    setInitDataFieldSettings(
      await TesGet(
        CollisionApiUrl() +
        "/api/XMLImport/GetXMLImportSettings/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }


  function addFromFileStorage(files: VMStorage[]) {
    setLstLastFiles([...lstLastFiles, ...files])
    const res = files.map(x => x.filePath);
    setSelectedData({
      ...selectedData ?? {},
      filePaths: [...selectedData?.filePaths, ...res]
    });
  }

  async function onImportCheck() {
    //check email regex
    const emailRegex = new RegExp(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    );
    var emailArray = emails.split(";") as string[];
    emailArray.forEach((element: string) => {
      if (!(emailRegex.test(element ?? ""))) {
        notify(t("invalidEmailFormat"), "error", 2500);
      }
    });
    const postObj: ImportXmlRequestTask = {
      ...selectedData,
      emails: emailArray,
      customerId: localStorage.getItem("selectedCustomerId") as string,
    }
    console.log(postObj)
    try {
      if (activeLoading) activeLoading(true);
      await TesPost(CollisionApiUrl() + "/api/XMLImport/ImportCheck", postObj, true)
      setShowImportMessage(true)
      setSelectedData({ ...selectedData, filePaths: [] });
      setLstLastFiles([]);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }






  async function getInitDataUsers() {
    try {
      var res = (await TesGet(AuthApiUrl() + "/api/user/findUser", true)) as VMFindUser[];
      setInitDataUser(res.filter(x => x.defaultCustomerId === localStorage.getItem("selectedCustomerId")));
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onValueChange(key: string, value: any) {
    setSelectedData({
      ...selectedData, [key]: value
    })
  }

  async function onValueChangeEmail(value: any) {
    setEmails(value as string);
  }

  //#endregion functions
  return (
    <Permission
      allowed={[TMSPermissions.TMS_Admin, CollisionPermissions.Collision_GeoCode]}
      hasFeedBackElement={true}
    >
      <div className={`xmlImport ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("xmlImport")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"} style={{ padding: "2rem 2rem 0rem 2rem" }}>
              <div className="row">
                <div>
                  <div style={{ marginLeft: "1rem" }}>
                  </div>
                  <Form colCount={7}>
                    <SimpleItem colSpan={2}>
                      <SelectBox
                        placeholder=""
                        label={t('fieldSettings')}
                        valueExpr="id"
                        displayExpr="title"
                        labelMode='floating'
                        value={selectedData?.xmlImportSettingId}
                        onValueChange={(e) => onValueChange("xmlImportSettingId", e)}
                        className="modalInput"
                        items={initDataFieldSettings}
                        showClearButton={true}
                        searchEnabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={2}>
                      <SelectBox
                        placeholder=""
                        label={t('xmlImportAction')}
                        valueExpr="value"
                        displayExpr="name"
                        labelMode='floating'
                        defaultValue={XMLImportAction.Merge}
                        value={selectedData?.xmlImportAction}
                        onValueChange={(e) => onValueChange("xmlImportAction", e)}
                        className="modalInput"
                        items={lstXMLImportAction}
                        showClearButton={true}
                        searchEnabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={2}>
                      <TextBox
                        labelMode='floating'
                        label={t('emails')}
                        value={emails}
                        onValueChange={(e) => onValueChangeEmail(e)}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <div id="filePicker">
                        <FilePicker
                          isLocked={false}
                          label={t("filePicker")}
                          addFromFileStorage={addFromFileStorage}
                          disable={false}
                          lstLastFiles={lstLastFiles}
                          multiFileMode={true}
                          AddFolderAllowed={true}
                        />
                      </div>

                    </SimpleItem>
                    <SimpleItem colSpan={7}>
                      <DataGrid
                        id="gridContainer"
                        ref={dataGridRef}
                        dataSource={lstLastFiles}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        selection={{ mode: 'single' }}
                        hoverStateEnabled={true}
                        remoteOperations={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        onExporting={OnExporting}
                      >
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging enabled={true} defaultPageSize={100} />
                        <Pager
                          showPageSizeSelector={true}
                          allowedPageSizes={[100, 200, 300, 400, 500]}
                          showNavigationButtons={true}
                          showInfo={true}
                          visible={true}
                        />
                        <FilterRow visible={true}
                          applyFilter="auto" />
                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true}
                          width={285}
                          placeholder={t("search...")} />
                        <ColumnChooser width={350} height={400}
                          enabled={true}
                          mode="select"
                          sortOrder="asc"
                        >
                          <Search enabled />
                        </ColumnChooser>
                        <SortByGroupSummaryInfo
                          summaryItem="Total Count"
                          sortOrder="desc"
                        />
                        <Summary>
                          <GroupItem
                            summaryType="count"
                            alignByColumn
                            name="Total Count"
                          />
                        </Summary>
                        <Editing
                          mode="row"
                          allowDeleting={true}
                        />



                        <Column alignment="left" dataField="fileType"
                          cellRender={calculateFileTypeCell}
                          caption={t("fileType")}>
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                        <Column alignment="left" dataField="fileName"
                          caption={t("fileName")}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      </DataGrid>
                    </SimpleItem>
                  </Form>
                  <Button
                    onClick={onImportCheck}
                    className='tes-modal-btn-add rightColumn'
                    text={t('start')}
                  />
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>



          <Popup
            width={"50%"}
            height={"auto"}
            visible={showImportMessage}
            resizeEnabled={true}
            showTitle={true}
            title={t("xmlImport")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowImportMessage(false)}
          >
            <div >
              <Form colCount={2}>
                <SimpleItem colSpan={2} >
                  <p className="title">{t('xmlImportMessage')}</p>
                </SimpleItem>
              </Form>
            </div>
            <div className="rightColumn" style={{ marginTop: "0.5rem" }}>
              <Button
                className="tes-modal-btn-add"
                onClick={() => setShowImportMessage(false)}
                text={t("close")}
              />
            </div>
          </Popup>

        </React.Fragment>
      </div>
    </Permission>
  );
};
export default XMLImport;