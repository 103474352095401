import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  getAveragePaths,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";
import { StudyType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import Expand from "@arcgis/core/widgets/Expand";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import { SimplifiedStudy } from "../../../../types/trafficStudy/trafficStudyTypes";

type TProps = {
  showLayer: boolean;
  legendExpand: Expand | null;
  map: Map | null;
  view: MapView | null;
  initDataGis: MapSetting | null | undefined;
  lstStudyLocations: SimplifiedStudy[];
  roadSegmentLayer: FeatureLayer | null;
  reCreateLayerEvenItExists?: boolean;
};
export const useLatestSpeedStudyLayer = ({
  showLayer,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
  reCreateLayerEvenItExists = false,
}: TProps) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "SpeedStudyLayer")) {
          removeLayer(map, "SpeedStudyLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations?.length !== 0 &&
      roadSegmentLayer
    ) {
      if (isLayerExist(map, "SpeedStudyLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "SpeedStudyLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);

      if (isCancelled.current) {
        setIsLayerLoading(false);
        isUnderConstruction.current = false;
        return;
      }

      const midblockLocations = lstStudyLocations.filter(
        (loc) =>
          loc.locationType.toString() === LocationType.Midblock.toString()
      );

      if (midblockLocations.length === 0) return;

      let queryIds = generateQueryIds(
        initDataGis,
        "midblockGeoIdType",
        midblockLocations
      );

      const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

      const roadSegmentQuery = {
        outFields: ["*"],
        where: strRoadSegmentQuery,
        returnGeometry: true,
      };

      const queryingAndCreateLayer = () => {
        setIsLayerLoading(true);
        isUnderConstruction.current = true;
        view?.whenLayerView(roadSegmentLayer).then(function (layerView) {
          reactiveUtils
            .whenOnce(() => !layerView.updating)
            .then(() => {
              if (isCancelled.current || !isTheLastMovement.current) {
                setIsLayerLoading(false);
                isUnderConstruction.current = false;
                return;
              }
              layerView
                .queryFeatures(roadSegmentQuery)
                .then(function (results) {
                  console.log("!!length", results.features.length);
                  if (isCancelled.current || !isTheLastMovement.current) {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                    return;
                  }
                  const lstNewRoadSegmentGraphics = results.features
                    .filter(
                      (feature) =>
                        (feature.geometry as Polyline)?.paths?.length > 0
                    )
                    .map((place) => {
                      const paths = (place.geometry as Polyline).paths[0];
                      const [avgX, avgY] = getAveragePaths(paths);
                      // Convert to Longitude/Latitude and back to Web Mercator coordinates
                      const [lng, lat] = webMercatorUtils.xyToLngLat(
                        avgX,
                        avgY
                      );
                      const [x, y] = webMercatorUtils.lngLatToXY(lng, lat);

                      // Find matching location's average speed
                      const matchingLocation = lstStudyLocations.find(
                        (loc) =>
                          loc.geoId.toString() ===
                          place.attributes[
                            initDataGis.midblockGeoIdName
                          ].toString()
                      );

                      return new Graphic({
                        attributes: {
                          [initDataGis.midblockGeoIdName]:
                            place.attributes[initDataGis.midblockGeoIdName],
                          northApproachVolume:
                            matchingLocation?.volumeSummary[
                              "northApproachVolume"
                            ],
                          southApproachVolume:
                            matchingLocation?.volumeSummary[
                              "southApproachVolume"
                            ],
                          westApproachVolume:
                            matchingLocation?.volumeSummary[
                              "westApproachVolume"
                            ],
                          eastApproachVolume:
                            matchingLocation?.volumeSummary[
                              "eastApproachVolume"
                            ],
                          totalVolume:
                            matchingLocation?.volumeSummary["totalVolume"],
                          studyType: matchingLocation?.studyType,
                        },
                        geometry: new Point({
                          x,
                          y,
                          spatialReference: {
                            wkid: place.geometry?.spatialReference?.wkid,
                          },
                        }),
                      });
                    });

                  const renderer = new UniqueValueRenderer({
                    field: "studyType",
                    uniqueValueInfos: [
                      {
                        value: StudyType.Speed,
                        symbol: new PictureMarkerSymbol({
                          url: "http://staticfile.tes.ca/gisMap/latestStudies/speed.png",
                          width: 20,
                          height: 20,
                        }),
                      },
                    ],
                  });

                  const speedStudyLayer = new FeatureLayer({
                    id: "SpeedStudyLayer",
                    title: "Speed Study",
                    source: lstNewRoadSegmentGraphics,
                    objectIdField: "ObjectId",
                    outFields: ["*"],
                    fields: [
                      {
                        alias: initDataGis.midblockGeoIdName,
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: initDataGis.midblockGeoIdName,
                        nullable: false,
                        type: "string",
                      },
                      {
                        alias: "study Type",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "studyType",
                        nullable: true,
                        type: "integer",
                      },
                      {
                        alias: "North Approach Volume",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "northApproachVolume",
                        nullable: true,
                        type: "integer",
                      },
                      {
                        alias: "South Approach Volume",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "southApproachVolume",
                        nullable: true,
                        type: "integer",
                      },
                      {
                        alias: "East Approach Volume",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "eastApproachVolume",
                        nullable: true,
                        type: "integer",
                      },
                      {
                        alias: "West Approach Volume",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "westApproachVolume",
                        nullable: true,
                        type: "integer",
                      },
                      {
                        alias: "Total Volume",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "totalVolume",
                        nullable: true,
                        type: "integer",
                      },
                    ],
                    renderer: renderer,
                    popupTemplate: {
                      title: "Latest Speed Study",
                      content: [
                        {
                          type: "fields",
                          fieldInfos: [
                            {
                              fieldName: initDataGis.midblockGeoIdName,
                              label: "Geo ID",
                            },
                            {
                              fieldName: "northApproachVolume",
                              label: "North Approach Volume",
                            },
                            {
                              fieldName: "southApproachVolume",
                              label: "South Approach Volume",
                            },
                            {
                              fieldName: "eastApproachVolume",
                              label: "East Approach Volume",
                            },
                            {
                              fieldName: "westApproachVolume",
                              label: "West Approach Volume",
                            },
                            {
                              fieldName: "totalVolume",
                              label: "Total Volume",
                            },
                          ],
                        },
                      ],
                    },
                  });

                  speedStudyLayer.load().then(() => {
                    if (isCancelled.current || !isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }
                    removeLayer(map, "SpeedStudyLayer");
                    map.add(speedStudyLayer);
                    if (legendExpand) legendExpand.expanded = false;
                  });

                  layerSuccessfullyLoadedInDomCallback(
                    view,
                    speedStudyLayer,
                    () => {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                    }
                  );
                });
            });
        });
      };
      view.when(() => {
        if (isCancelled.current) {
          setIsLayerLoading(false);
          return;
        }
        if (
          isLayerExist(map, "SpeedStudyLayer") ||
          isUnderConstruction.current
        ) {
          return;
        }
        queryingAndCreateLayer();
      });
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          if (isLayerExist(map, "SpeedStudyLayer")) {
            isTheLastMovement.current = false;
          }
        },
        1
      );
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          isTheLastMovement.current = true;
          if (!isCancelled.current && !isUnderConstruction.current)
            queryingAndCreateLayer();
        },
        700
      );
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    lstStudyLocations,
    roadSegmentLayer,
    reCreateLayerEvenItExists,
  ]);

  return {
    isTrafficSpeedStudyLayerLoading: isLayerLoading,
  };
};
