import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  PropsWithChildren,
  useCallback,
} from "react";

import {
  getInfrastructureData,
  getStoredInfrastructureData,
  storeInfrastructureData,
  storeInfrastructureDataKeyName,
} from "./utils";

import { MapSetting } from "../../types/infrastructure/infrastructureTypes";

type TInfrastructureContext = {
  infrastructureLoading: boolean;
  infrastructureData: MapSetting | undefined;
  clearStoredInfrastructureData: () => void;
};
const initialData: TInfrastructureContext = {
  infrastructureLoading: true,
  infrastructureData: undefined,
  clearStoredInfrastructureData: () => {},
};

export const InfrastructureContext = createContext(initialData);

const useInfrastructure = () => useContext(InfrastructureContext);

function InfrastructureProvider({ children }: PropsWithChildren) {
  const [infrastructureData, setInfrastructureData] = useState<MapSetting>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchAndStoreInfrastructureData = async () => {
    setIsLoading(true);
    //To prevent getting data on refresh, uncomment code below
    // const storedData = getStoredInfrastructureData();
    // if (storedData) {
    //   setInfrastructureData(storedData);
    //   setIsLoading(false);
    //   return;
    // }
    const data = await getInfrastructureData();
    if (data) {
      setInfrastructureData(data);
      storeInfrastructureData(data);
    }
    setIsLoading(false);
  };

  const clearStoredInfrastructureData = useCallback(() => {
    localStorage.removeItem(storeInfrastructureDataKeyName);
    setInfrastructureData(undefined);
  }, []);

  useEffect(() => {
    fetchAndStoreInfrastructureData();
  }, []);

  const value: TInfrastructureContext = {
    infrastructureLoading: isLoading,
    infrastructureData,
    clearStoredInfrastructureData,
  };
  return (
    <InfrastructureContext.Provider value={value}>
      {children}
    </InfrastructureContext.Provider>
  );
}

export { InfrastructureProvider, useInfrastructure };
