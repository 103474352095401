import i18n from "../../../../../react-i18next";

const tabTitles = [
    {
        id: 0,
        text: i18n.t('general'),
        icon: 'fa-solid fa-circle-info',
        content: 'Details tab content',
    },
    {
        id: 1,
        text: i18n.t('xmlTags'),
        icon: 'fa-solid fa-tag',
        content: 'Location tab content',
    },
    {
        id: 2,
        text: i18n.t('pdfRedactions'),
        icon: 'fa-solid fa-file-pdf',
        content: 'Permissions tab content',
    },
    {
        id: 3,
        text: i18n.t('pictureExtractions'),
        icon: 'fa-regular fa-images',
        content: 'Permissions tab content',
    },
];
export default tabTitles;