import i18n from "../../../../react-i18next";

const tabTitles = [
    {
        id: 0,
        text: i18n.t('general'),
        icon: 'fa-solid fa-circle-info',
        content: 'General tab content',
    },
    {
        id: 1,
        text: i18n.t('location'),
        icon: 'fa-solid fa-map',
        content: 'Location tab content',
    },
    {
        id: 2,
        text: i18n.t('roads'),
        icon: 'fa-solid fa-map-location-dot',
        content: 'Roads tab content',
    },
    {
        id: 3,
        text: i18n.t('vehicles'),
        icon: 'fa-solid fa-car',
        content: ' Vehicles tab content',
    },
    {
        id: 4,
        text: i18n.t('drivers'),
        icon: 'fa-solid fa-id-card',
        content: 'Drivers tab content',
    },
    {
        id: 5,
        text: i18n.t('passengers'),
        icon: 'fa-solid fa-user',
        content: 'Passengers tab content',
    },
    {
        id: 6,
        text: i18n.t('pedestrians'),
        icon: 'fa-solid fa-person-walking',
        content: 'Pedestrians tab content',
    },
    {
        id: 7,
        text: i18n.t('people'),
        icon: 'fa-solid fa-people-group',
        content: 'People tab content',
    },
    {
        id: 8,
        text: i18n.t('attachments'),
        icon: 'fa-solid fa-paperclip',
        content: 'Attachments tab content',
    },
    {
        id: 9,
        text: i18n.t('remarks'),
        icon: 'fa-solid fa-circle-exclamation',
        content: 'Remarks tab content',
    },
    {
        id: 10,
        text: i18n.t('changeLogs'),
        icon: 'fa-solid fa-list-check',
        content: 'Change Logs tab content',
    },
    {
        id: 11,
        text: i18n.t('validations'),
        icon: 'fa-solid fa-check-double',
        content: 'Validations tab content',
    },
    {
        id: 12,
        text: i18n.t('mergeLogs'),
        icon: 'fa-solid fa-list-check',
        content: 'Change Logs tab content',
    },
];
export default tabTitles;

