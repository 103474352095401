import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";
import Legend from "@arcgis/core/widgets/Legend.js";
import Expand from "@arcgis/core/widgets/Expand.js";
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import { LocationTrafficSummaryDTO } from "../../../../types/trafficStudy/dtos/studyDto";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import ClassBreaksRenderer from "@arcgis/core/renderers/ClassBreaksRenderer";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";

type TProps = {
  showLayer: boolean;
  legend: Legend | null;
  legendExpand: Expand | null;
  map: Map | null;
  view: MapView | null;
  initDataGis: MapSetting | null | undefined;
  lstStudyLocations: LocationTrafficSummaryDTO[];
  intersectionLayer: FeatureLayer | null;
  reCreateLayerEvenItExists?: boolean;
};
export const useTrafficIntersectionAADTsLayer = ({
  showLayer,
  legend,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  intersectionLayer,
  reCreateLayerEvenItExists = false,
}: TProps) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "IntersectionAADTLayer")) {
          removeLayer(map, "IntersectionAADTLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations?.length !== 0 &&
      intersectionLayer
    ) {
      if (isLayerExist(map, "IntersectionAADTLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "IntersectionAADTLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);

      if (isCancelled.current) {
        setIsLayerLoading(false);
        isUnderConstruction.current = false;
        return;
      }

      const intersectionLocations = lstStudyLocations.filter(
        (loc) =>
          loc.locationType.toString() === LocationType.Intersection.toString()
      );

      if (intersectionLocations.length === 0) return;

      let queryIds = generateQueryIds(
        initDataGis,
        "intersectionGeoIdType",
        intersectionLocations
      );

      const strIntersectionQuery = `${initDataGis.intersectionGeoIdName} IN (${queryIds})`;

      const intersectionQuery = {
        outFields: ["*"],
        where: strIntersectionQuery,
        returnGeometry: true,
      };

      const queryingAndCreateLayer = () => {
        setIsLayerLoading(true);
        isUnderConstruction.current = true;
        view.whenLayerView(intersectionLayer).then((layerView) => {
          reactiveUtils &&
            reactiveUtils
              .whenOnce(() => !layerView.updating)
              .then(() => {
                if (isCancelled.current || !isTheLastMovement.current) {
                  setIsLayerLoading(false);
                  isUnderConstruction.current = false;
                  return;
                }
                layerView.queryFeatures(intersectionQuery).then((results) => {
                  if (isCancelled.current || !isTheLastMovement.current) {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                    return;
                  }
                  const graphics = results.features;
                  console.log("!!length", graphics.length);

                  // Attach intersectionAADTs to the graphics attributes
                  graphics.forEach((graphic) => {
                    const matchedLocation = lstStudyLocations.find(
                      (loc) =>
                        loc.geoId.toString() ===
                        graphic.attributes[
                          initDataGis.intersectionGeoIdName
                        ].toString()
                    );
                    graphic.attributes["intersectionAADT"] =
                      matchedLocation?.intersectionAADT;
                    graphic.attributes["location"] = matchedLocation?.Location;
                  });

                  const intersectionAADTLayer = new FeatureLayer({
                    id: "IntersectionAADTLayer",
                    title: "IntersectionAADTLayer",
                    source: graphics,
                    // editable: true,
                    objectIdField: "ObjectId",
                    outFields: ["*"],
                    fields: [
                      {
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        alias: "Intersection AADT",
                        name: "intersectionAADT",
                        nullable: false,
                        type: "integer",
                      },
                      {
                        alias: initDataGis?.intersectionGeoIdName,
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: initDataGis?.intersectionGeoIdName,
                        nullable: false,
                        type: "string",
                      },
                    ],
                    // popupTemplates can still be viewed on
                    // individual features
                    popupTemplate: {
                      title: "Intersection AADT",
                      content: [
                        {
                          type: "fields",
                          fieldInfos: [
                            {
                              fieldName: initDataGis?.intersectionGeoIdName,
                              label: "Geo ID",
                            },
                            {
                              fieldName: "intersectionAADT",
                              label: "Total",
                            },
                          ],
                        },
                      ],
                    },
                    renderer: new ClassBreaksRenderer({
                      field: "intersectionAADT",
                      classBreakInfos: [
                        {
                          minValue: 1,
                          maxValue: 1000,
                          symbol: new SimpleMarkerSymbol({
                            color: "#00ff00",
                            size: "11px",
                          }),
                          label: "> 0",
                        },
                        {
                          minValue: 1001,
                          maxValue: 5000,
                          symbol: new SimpleMarkerSymbol({
                            color: "#FFFF00",
                            size: "12px",
                          }),
                          label: "> 1000",
                        },
                        {
                          minValue: 5001,
                          maxValue: 10000,
                          symbol: new SimpleMarkerSymbol({
                            color: "#ee8200",
                            size: "13px",
                          }),
                          label: "> 5000",
                        },
                        {
                          minValue: 10001,
                          maxValue: 20000,
                          symbol: new SimpleMarkerSymbol({
                            color: "#ff0000",
                            size: "14px",
                          }),
                          label: "> 10000",
                        },
                        {
                          minValue: 20001,
                          maxValue: 500000,
                          symbol: new SimpleMarkerSymbol({
                            color: "#8B4000",
                            size: "15px",
                          }),
                          label: "> 20000",
                        },
                      ],
                    }),
                  });

                  intersectionAADTLayer.load().then(() => {
                    if (isCancelled.current || !isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }
                    removeLayer(map, "IntersectionAADTLayer");
                    map.add(intersectionAADTLayer);
                    if (legend)
                      legend.layerInfos.push({
                        layer: intersectionAADTLayer,
                      });
                    if (legendExpand) legendExpand.expanded = true;
                  });

                  layerSuccessfullyLoadedInDomCallback(
                    view,
                    intersectionAADTLayer,
                    () => {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                    }
                  );
                });
              });
        });
      };
      view.when(() => {
        if (isCancelled.current) {
          setIsLayerLoading(false);
          return;
        }
        if (
          isLayerExist(map, "IntersectionAADTLayer") ||
          isUnderConstruction.current
        ) {
          return;
        }
        queryingAndCreateLayer();
      });
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          if (isLayerExist(map, "IntersectionAADTLayer")) {
            isTheLastMovement.current = false;
          }
        },
        1
      );
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          isTheLastMovement.current = true;
          if (!isCancelled.current && !isUnderConstruction.current)
            queryingAndCreateLayer();
        },
        700
      );
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    lstStudyLocations,
    intersectionLayer,
    reCreateLayerEvenItExists,
  ]);

  return {
    isTrafficIntersectionAADTsLayerLoading: isLayerLoading,
  };
};
