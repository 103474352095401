import {
  Form,
  Switch,
  TagBox,
  TextBox,
  ValidationGroup,
} from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { SimpleItem } from "devextreme-react/form";
import { t } from "i18next";
import React from "react";
import { XMLRedaction } from "../../../../../types/collision/collisionTypes";
import {
  SortObjectByPropName,
  SumIntArray,
} from "../../../../../utils/arrayTools";
import { XmlRedactionDefault } from "../../../../../types/collision/enums/collisionEnums";
import { EnumFlag2Array } from "../../../../../utils/enumTools";
import { NameValue } from "../../../../../types/general/generalTypes";

interface IPros {
  isLocked: boolean;
  initData: XMLRedaction;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
  lstXmlRedactionDefault: NameValue[];
}

const General = (props: IPros) => {
  function onChangeValue(name: string, value: any) {
    props.setInitData({ ...props.initData, [name]: value });
  }

  return (
    <React.Fragment>
      <ValidationGroup ref={props.validationRef}>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          <Form colCount={12}>
            <SimpleItem colSpan={4}>
              <TextBox
                label={t("name")}
                labelMode="floating"
                value={props.initData?.name}
                onValueChange={(e) => onChangeValue("name", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <div style={{ color: "#424242" }}>{t("hasPdfRedaction")}</div>
              <Switch
                style={{ marginTop: 5 }}
                hint={t("hasPdfRedaction")}
                value={props.initData?.hasPdfRedaction}
                onValueChange={(e) => onChangeValue("hasPdfRedaction", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <div style={{ color: "#424242" }}>
                {t("hasPictureExtraction")}
              </div>
              <Switch
                style={{ marginTop: 5 }}
                hint={t("hasPictureExtraction")}
                value={props.initData?.hasPictureExtraction}
                onValueChange={(e) => onChangeValue("hasPictureExtraction", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={4}>
              <TagBox
                label={t("xmlRedactionDefault")}
                labelMode="floating"
                dataSource={SortObjectByPropName(
                  props?.lstXmlRedactionDefault,
                  "name"
                )}
                valueExpr="value"
                displayExpr="name"
                value={EnumFlag2Array(
                  XmlRedactionDefault,
                  props.initData?.xmlRedactionDefault
                )}
                onValueChange={(e) =>
                  onChangeValue("xmlRedactionDefault", SumIntArray(e))
                }
              />
            </SimpleItem>
          </Form>
        </div>
      </ValidationGroup>
    </React.Fragment>
  );
};

export default General;
