import { NumberBox, SelectBox } from "devextreme-react";
import { Form, SimpleItem } from "devextreme-react/form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useClientSetting } from "../../../../../../contexts/clientSetting";
import { TesCodeValue } from "../../../../../../types/infrastructure/infrastructureTypes";
import { AADTType, ApproachType, CountCodeType, MatchingAlgorithmResultType, PCSPatternType, TrendingResultStatus } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { AlphaCodeType, TrafficSectionAADT } from "../../../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { Enum2Array } from "../../../../../../utils/enumTools";

// props
interface IPros {
  initData: TrafficSectionAADT,
  aadtType: AADTType,
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  // initDataTesCodeValues: TesCodeValue
}

const AADT = (props: IPros) => {
  const { t } = useTranslation();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  // const { customerDivisions } = useClientSetting();
  const lstPatternType = Enum2Array(PCSPatternType)
  const lstCountCode = Enum2Array(CountCodeType)
  const lstAlphaCodeType = Enum2Array(AlphaCodeType)
  const lstApproachType = Enum2Array(ApproachType)
  const lstMatchingAlgorithmResultType = Enum2Array(MatchingAlgorithmResultType)
  const lstTrendingResultStatus = Enum2Array(TrendingResultStatus)

  function onValueChangeIntersection(name: string, value: any) {
    props.setInitData({ ...props.initData, [name]: value } )
    props.setDataChanged(true)
  }

  return (
    <div className={`aadtDetails-aadt ${compactViewModel ? "compactStyle" : ""}`}>
      <React.Fragment>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
            <Form colCount={3}>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('year')}
                  labelMode='floating'
                  value={props.initData?.year}
                  onValueChange={(e) => onValueChangeIntersection("year", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  labelMode="floating"
                  label={t('patternType')}
                  value={props.initData.patternType}
                  onValueChange={(e) => onValueChangeIntersection("patternType", e)}
                  items={lstPatternType}
                  showClearButton={true}
                  searchEnabled={true}
                  valueExpr="value"
                  displayExpr="name"
                  disabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  labelMode="floating"
                  label={t('countCode')}
                  value={props.initData.countCode}
                  onValueChange={(e) => onValueChangeIntersection("countCode", e)}
                  items={lstCountCode}
                  showClearButton={true}
                  searchEnabled={true}
                  valueExpr="value"
                  displayExpr="name"
                  disabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  labelMode="floating"
                  label={t('alphaCode')}
                  value={props.initData.alphaCode}
                  onValueChange={(e) => onValueChangeIntersection("alphaCode", e)}
                  items={lstAlphaCodeType}
                  showClearButton={true}
                  searchEnabled={true}
                  valueExpr="value"
                  displayExpr="name"
                  disabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('aadt')}
                  labelMode='floating'
                  value={props.initData?.aADT}
                  onValueChange={(e) => onValueChangeIntersection("aADT", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('truckAADT')}
                  labelMode='floating'
                  value={props.initData?.truckAADT}
                  onValueChange={(e) => onValueChangeIntersection("truckAADT", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('mpAadt')}
                  labelMode='floating'
                  value={props.initData?.mpAadt}
                  onValueChange={(e) => onValueChangeIntersection("mpAadt", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('sadt')}
                  labelMode='floating'
                  value={props.initData?.sadt}
                  onValueChange={(e) => onValueChangeIntersection("sadt", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('sawdt')}
                  labelMode='floating'
                  value={props.initData?.sawdt}
                  onValueChange={(e) => onValueChangeIntersection("sawdt", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('wadt')}
                  labelMode='floating'
                  value={props.initData?.wadt}
                  onValueChange={(e) => onValueChangeIntersection("wadt", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('factorSadt')}
                  labelMode='floating'
                  value={props.initData?.factorSadt}
                  onValueChange={(e) => onValueChangeIntersection("factorSadt", e)}
                  className="modalInput"
                  disabled={true}
                  format={{ type: 'fixedPoint', precision: 2 }}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('factorWadt')}
                  labelMode='floating'
                  value={props.initData?.factorWadt}
                  onValueChange={(e) => onValueChangeIntersection("factorWadt", e)}
                  className="modalInput"
                  disabled={true}
                  format={{ type: 'fixedPoint', precision: 2 }}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('dirSplitPercent')}
                  labelMode='floating'
                  value={props.initData?.dirSplitPercent}
                  onValueChange={(e) => onValueChangeIntersection("dirSplitPercent", e)}
                  className="modalInput"
                  disabled={true}
                  format={{ type: 'fixedPoint', precision: 2 }}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  labelMode="floating"
                  label={t('dirSplitHigherVolDirection')}
                  value={props.initData.dirSplitHigherVolDirection}
                  onValueChange={(e) => onValueChangeIntersection("dirSplitHigherVolDirection", e)}
                  items={lstApproachType}
                  showClearButton={true}
                  searchEnabled={true}
                  valueExpr="value"
                  displayExpr="name"
                  disabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('dhvPercent')}
                  labelMode='floating'
                  value={props.initData?.dHVPercent}
                  onValueChange={(e) => onValueChangeIntersection("dHVPercent", e)}
                  className="modalInput"
                  disabled={true}
                  format={{ type: 'fixedPoint', precision: 2 }}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('truckPercent')}
                  labelMode='floating'
                  value={props.initData?.truckPercent}
                  onValueChange={(e) => onValueChangeIntersection("truckPercent", e)}
                  className="modalInput"
                  disabled={true}
                  format={{ type: 'fixedPoint', precision: 2 }}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('yearlyPercentChangeInAADT')}
                  labelMode='floating'
                  value={props.initData?.yearlyPercentChangeInAADT}
                  onValueChange={(e) => onValueChangeIntersection("yearlyPercentChangeInAADT", e)}
                  className="modalInput"
                  disabled={true}
                  format={{ type: 'fixedPoint', precision: 2 }}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('tenYearPercentChangeInAADT')}
                  labelMode='floating'
                  value={props.initData?.tenYearPercentChangeInAADT}
                  onValueChange={(e) => onValueChangeIntersection("tenYearPercentChangeInAADT", e)}
                  className="modalInput"
                  disabled={true}
                  format={{ type: 'fixedPoint', precision: 2 }}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('confidenceIndex')}
                  labelMode='floating'
                  value={props.initData?.confidenceIndex}
                  onValueChange={(e) => onValueChangeIntersection("confidenceIndex", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t('confidenceIndexPercent')}
                  labelMode='floating'
                  value={props.initData?.confidenceIndexPercent}
                  onValueChange={(e) => onValueChangeIntersection("confidenceIndexPercent", e)}
                  className="modalInput"
                  disabled={true}
                  format={{ type: 'fixedPoint', precision: 2 }}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  labelMode="floating"
                  label={t('matchingAlgorithmResultType')}
                  value={props.initData.matchingAlgorithmResultType}
                  onValueChange={(e) => onValueChangeIntersection("matchingAlgorithmResultType", e)}
                  items={lstMatchingAlgorithmResultType}
                  showClearButton={true}
                  searchEnabled={true}
                  valueExpr="value"
                  displayExpr="name"
                  disabled={true}
                />
              </SimpleItem>              <SimpleItem colSpan={1}>
                <SelectBox
                  labelMode="floating"
                  label={t('trendingStatus')}
                  value={props.initData.trendingStatus}
                  onValueChange={(e) => onValueChangeIntersection("trendingStatus", e)}
                  items={lstTrendingResultStatus}
                  showClearButton={true}
                  searchEnabled={true}
                  valueExpr="value"
                  displayExpr="name"
                  disabled={true}
                />
              </SimpleItem>
            </Form>
        </div>
      </React.Fragment>
    </div>
  );
};
export default AADT;
