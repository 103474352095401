import i18n from "../../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: i18n.t("general"),
    icon: "fa-solid fa-info",
    content: "General tab content",
  },
  {
    id: 1,
    text: i18n.t("locations"),
    icon: "fa-solid fa-map",
    content: "Locations tab content",
  }
];
export default tabTitles;