import i18n from "../../../../react-i18next";

const addTabTitles = [
  {
    id: 0,
    text: i18n.t("details"),
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  }

];

const updateTabTitles = [
  {
    id: 0,
    text: i18n.t("details"),
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: i18n.t("tags"),
    icon: "fa-solid fa-language",
    content: "tags tab content",
  },
  {
    id: 2,
    text: i18n.t("customers"),
    icon: "fa-solid fa-users",
    content: "tags tab content",
  },

];

export {addTabTitles, updateTabTitles};