//imports
import { ContextMenu, ValidationGroup } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SafetyPermissions from "../../../../../../constants/Permissions/SafetyPermissions";
import { AADTPattern } from "../../../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../../utils/enumTools";
import CollapsibleCard from "../..//../../../../components/collapsibleCard/collapsibleCard";
import GeneralGisMapManager from "../..//../../../../components/gisMap/generalGisMapManager";
import Permission from "../..//../../../../components/permission/permision";
import { useAuth } from "../..//../../../../contexts/auth";
import { InfrastructureApiUrl } from "../..//../../../../environment/routeSettings";
import { ResponseCode, TesMapModule } from "../..//../../../../types/general/enums/generalEnums";
import { INameId, NameValue, RequestResponseResult } from "../..//../../../../types/general/generalTypes";
import { VMLocation } from "../..//../../../../types/infrastructure/dto/locationdDto";
import { LocationType } from "../..//../../../../types/infrastructure/enums/infrastructureEnums";
import { Location } from "../..//../../../../types/infrastructure/infrastructureTypes";
import { RequestErrorHandling, TesGet } from "../..//../../../../utils/rest";
import "./locations.scss";
import { ApproachType, FacilityType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { RightClickMenu } from "../../../../../../utils/rightClickMenu";

// props
interface IProps {
  initData: AADTPattern;
  setInitData: React.Dispatch<React.SetStateAction<AADTPattern>>;
  areaId: string;
  updateIntersection: (lstIntersections: Location[] | undefined) => void;
  updateRoadSegment: (lstRoadSegments: Location[] | undefined) => void;
  intersections: Location[];
  roadSegments: Location[];
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Locations = (props: IProps) => {
  const dataGridCurrentIntersectionsRef = useRef<DataGridRef<any, any>>(null);
  const dataGridCurrentRoadSegmentsRef = useRef<DataGridRef<any, any>>(null);
  const [showMap, setShowMap] = useState(false);
  const { activeLoading } = useAuth();
  const [initDataSiteSubTypes, setInitDataSiteSubTypes] = useState<INameId[]>([]);
  const [lstVolumeInheritType, setLstVolumeInheritType] = useState<NameValue[]>([]);
  const lstFacilityType = Enum2Array(FacilityType)
  const lstApproachType = Enum2Array(ApproachType)
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitDataSiteSubTypes();
        setLstVolumeInheritType(Enum2Array(lstVolumeInheritType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitDataSiteSubTypes() {
    var res = await TesGet(InfrastructureApiUrl() + "/api/SiteTypes/GetAllSiteSubTypesName/" + localStorage.getItem("selectedCustomerId"), true) as RequestResponseResult<INameId[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteSubTypes(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  const { t } = useTranslation();
  const items = [
    // { text: t('openInNewTab'), icon:  "fa-solid fa-up-right-from-square"},
    { text: t('openLocation'), icon: "fa-solid fa-location-dot" }
  ];

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_D_Setups]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.data._id, "/trafficStudy/setups/AADTPatternDetails/", selectedRowData.row.data.locationType, selectedRowData.row.data.id)}          />
          <ValidationGroup ref={props.validationRef}>
          <div className={"dx-card responsive-paddings"} style={{paddingTop: "0.5rem"}}>
            <div className="row">
              <div className="leftColumn">
                <Button
                  onClick={() => setShowMap(true)}
                  icon="fa-solid fa-map-location-dot"
                  style={{ marginRight: 10 }}
                  hint={t("showMap")}
                />
              </div>
            </div>
            <div style={{marginTop: "-0.5rem"}}>
              {/* intesection datagrid */}
              <CollapsibleCard title={t("intersections")}>
                <DataGrid
                  id="gridContainer"
                  ref={dataGridCurrentIntersectionsRef}
                  dataSource={props.initData.locations?.filter((x: VMLocation) => x.locationType === LocationType.Intersection)}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  onExporting={OnExporting}
                  onContextMenuPreparing={e => { setSelectedRowData(e) }}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <SortByGroupSummaryInfo
                    summaryItem="Total Count"
                    sortOrder="desc"
                  />
                  <Summary>
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                  <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                    <Search enabled />
                  </ColumnChooser>
                  <Column alignment="left" dataField="geoId" caption={t("geoId")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column dataField="description" caption={t("description")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="SiteSubTypeId"
                    allowSorting={true}
                    visible={false}
                    caption={t("siteSubType")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(initDataSiteSubTypes, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                  <Column dataField={"facilityType"} caption={t("facilityType")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(lstFacilityType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="latitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("latitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="longitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("longitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="xCoord"
                    caption={t("xCoord")}
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    format={{ type: "fixedPoint", precision: 4 }}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="yCoord"
                    caption={t("yCoord")}
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    format={{ type: "fixedPoint", precision: 4 }}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="lhrs"
                    caption={t("lhrs")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="endLHRS"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("endLHRS")}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="startOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("startOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="endOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("endOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="nextEndOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("nextEndOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="nextStartOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("nextStartOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="priorEndOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("priorEndOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="priorStartOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("priorStartOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="nextLHRS"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("nextLHRS")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="priorLHRS"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("priorLHRS")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column dataField={"streetDirection1"} caption={t("streetDirection1")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(lstApproachType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column dataField={"streetDirection2"} caption={t("streetDirection2")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(lstApproachType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="isDeleted"
                    allowSorting={true}
                    caption={t("isDeleted")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Inactive"
                    allowSorting={true}
                    caption={t("inactive")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                </DataGrid>
              </CollapsibleCard>

              {/* Roadsegment datagrid */}
              <CollapsibleCard title={t("roadSegments")}>
                <DataGrid
                  id="gridContainer"
                  ref={dataGridCurrentRoadSegmentsRef}
                  dataSource={props.initData.locations?.filter((x: VMLocation) => x.locationType === LocationType.Midblock)}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  onExporting={OnExporting}
                  onContextMenuPreparing={e => { setSelectedRowData(e) }}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <SortByGroupSummaryInfo
                    summaryItem="Total Count"
                    sortOrder="desc"
                  />
                  <Summary>
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                  <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                    <Search enabled />
                  </ColumnChooser>
                  <Column alignment="left" dataField="geoId" caption={t("geoId")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column dataField="description" caption={t("description")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="SiteSubTypeId"
                    allowSorting={true}
                    visible={false}
                    caption={t("siteSubType")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(initDataSiteSubTypes, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                  <Column dataField={"facilityType"} caption={t("facilityType")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(lstFacilityType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="latitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("latitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="longitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("longitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="xCoord"
                    caption={t("xCoord")}
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    format={{ type: "fixedPoint", precision: 4 }}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="yCoord"
                    caption={t("yCoord")}
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    format={{ type: "fixedPoint", precision: 4 }}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="lhrs"
                    caption={t("lhrs")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="endLHRS"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("endLHRS")}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="startOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("startOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="endOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("endOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="nextEndOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("nextEndOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="nextStartOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("nextStartOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="priorEndOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("priorEndOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="priorStartOffset"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("priorStartOffset")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="nextLHRS"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("nextLHRS")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="priorLHRS"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("priorLHRS")}
                    format={{ type: "fixedPoint", precision: 2 }}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="isDeleted"
                    allowSorting={true}
                    caption={t("isDeleted")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Inactive"
                    allowSorting={true}
                    caption={t("inactive")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                </DataGrid>
              </CollapsibleCard>
            </div>
          </div>

          {/*on Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            // maxHeight={"90%"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.AreaDetailsView}
              lstIntersectionGeoIdIn={(props.initData?.locations?.filter(x => x.locationType === LocationType.Intersection).map(x => x.geoId!))!}
              setLstRoadSegmentGeoIdIn={(props.initData?.locations?.filter(x => x.locationType === LocationType.Midblock).map(x => x.geoId!))!}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="LocationId"
            />
          </Popup>
        </ValidationGroup>
      </React.Fragment>
    </Permission>
  );
};

export default Locations;