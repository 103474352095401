import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  getAveragePaths,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";
import Expand from "@arcgis/core/widgets/Expand";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import { LocationTrafficSummaryDTO } from "../../../../types/trafficStudy/dtos/studyDto";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import Graphic from "@arcgis/core/Graphic";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import Polyline from "@arcgis/core/geometry/Polyline";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import Point from "@arcgis/core/geometry/Point";

type TProps = {
  showLayer: boolean;
  legendExpand: Expand | null;
  map: Map | null;
  view: MapView | null;
  initDataGis: MapSetting | null | undefined;
  lstStudyLocations: LocationTrafficSummaryDTO[];
  roadSegmentLayer: FeatureLayer | null;
  reCreateLayerEvenItExists?: boolean;
};

export const useTrafficPostedSpeedLayer = ({
  showLayer,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
  reCreateLayerEvenItExists = false,
}: TProps) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "PostedSpeedLayer")) {
          removeLayer(map, "PostedSpeedLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations?.length !== 0 &&
      roadSegmentLayer
    ) {
      if (isLayerExist(map, "PostedSpeedLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "PostedSpeedLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);

      if (isCancelled.current) {
        setIsLayerLoading(false);
        isUnderConstruction.current = false;
        return;
      }

      const midblockLocations = lstStudyLocations.filter(
        (loc) =>
          loc.locationType.toString() === LocationType.Midblock.toString() &&
          loc.postedSpeed > 0
      );

      if (midblockLocations.length === 0) return;

      let queryIds = generateQueryIds(
        initDataGis,
        "midblockGeoIdType",
        midblockLocations
      );

      const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

      const roadSegmentQuery = {
        outFields: ["*"],
        where: strRoadSegmentQuery,
        returnGeometry: true,
      };

      const getSizeByScale = (scale: number, fieldValue: number) => {
        switch (fieldValue) {
          case 30:
            if (scale < 72500) return 25;
            if (scale < 288500) return 10;
            return 4;
          case 40:
            if (scale < 72500) return 20;
            if (scale < 288500) return 10;
            return 6;
          case 50:
            if (scale < 72500) return 20;
            if (scale < 288500) return 10;
            return 6;
          case 60:
            if (scale < 72500) return 20;
            if (scale < 288500) return 12;
            return 8;
          case 70:
            if (scale < 72500) return 20;
            if (scale < 288500) return 16;
            return 10;
          case 80:
            return 20;
          case 90:
            return 20;
          case 100:
            return 20;
          case 110:
            return 20;
          case 120:
            return 20;
          default:
            return 20;
        }
      };

      const queryingAndCreateLayer = () => {
        setIsLayerLoading(true);
        isUnderConstruction.current = true;
        view?.whenLayerView(roadSegmentLayer).then((roadSegmentLayerView) => {
          reactiveUtils
            .whenOnce(() => !roadSegmentLayerView.updating)
            .then(() => {
              if (isCancelled.current || !isTheLastMovement.current) {
                setIsLayerLoading(false);
                isUnderConstruction.current = false;
                return;
              }
              roadSegmentLayerView
                .queryFeatures(roadSegmentQuery)
                .then((results) => {
                  console.log("!!resLength", results.features.length);
                  if (isCancelled.current || !isTheLastMovement.current) {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                    return;
                  }
                  const lstNewRoadSegmentGraphics = results.features
                    .filter(
                      (feature) =>
                        feature.geometry &&
                        feature.geometry.type === "polyline" &&
                        (feature.geometry as Polyline).paths?.length > 0
                    )
                    .map((place) => {
                      const paths = (place.geometry as Polyline).paths[0];
                      const [avgX, avgY] = getAveragePaths(paths);
                      // Convert to Longitude/Latitude and back to Web Mercator coordinates
                      const [lng, lat] = webMercatorUtils.xyToLngLat(
                        avgX,
                        avgY
                      );
                      const [x, y] = webMercatorUtils.lngLatToXY(lng, lat);

                      // Find matching location's average speed
                      const matchingLocation = lstStudyLocations.find(
                        (loc) =>
                          loc.geoId.toString() ===
                          place.attributes[
                            initDataGis.midblockGeoIdName
                          ].toString()
                      );

                      return new Graphic({
                        attributes: {
                          [initDataGis.midblockGeoIdName]:
                            place.attributes[
                              initDataGis.midblockGeoIdName
                            ].toString(),
                          postedSpeed: matchingLocation?.postedSpeed.toString(),
                        },
                        geometry: new Point({
                          x,
                          y,
                          spatialReference: {
                            wkid: place.geometry?.spatialReference?.wkid,
                          },
                        }),
                      });
                    });

                  const renderer = new UniqueValueRenderer({
                    field: "postedSpeed",
                    uniqueValueInfos: [
                      {
                        value: "30",
                        symbol: new PictureMarkerSymbol({
                          url:
                            "http://staticfile.tes.ca/gisMap/speed/30.png" +
                            `?v=${new Date().getTime()}`,
                          width: getSizeByScale(view.scale, 30),
                          height: getSizeByScale(view.scale, 30),
                        }),
                      },
                      {
                        value: "40",
                        symbol: new PictureMarkerSymbol({
                          url:
                            "http://staticfile.tes.ca/gisMap/speed/40.png" +
                            `?v=${new Date().getTime()}`,
                          width: getSizeByScale(view.scale, 40),
                          height: getSizeByScale(view.scale, 40),
                        }),
                      },
                      {
                        value: "50",
                        symbol: new PictureMarkerSymbol({
                          url:
                            "http://staticfile.tes.ca/gisMap/speed/50.png" +
                            `?v=${new Date().getTime()}`,
                          width: getSizeByScale(view.scale, 50),
                          height: getSizeByScale(view.scale, 50),
                        }),
                      },
                      {
                        value: "60",
                        symbol: new PictureMarkerSymbol({
                          url:
                            "http://staticfile.tes.ca/gisMap/speed/60.png" +
                            `?v=${new Date().getTime()}`,
                          width: getSizeByScale(view.scale, 60),
                          height: getSizeByScale(view.scale, 60),
                        }),
                      },
                      {
                        value: "70",
                        symbol: new PictureMarkerSymbol({
                          url:
                            "http://staticfile.tes.ca/gisMap/speed/70.png" +
                            `?v=${new Date().getTime()}`,
                          width: getSizeByScale(view.scale, 70),
                          height: getSizeByScale(view.scale, 70),
                        }),
                      },
                      {
                        value: "80",
                        symbol: new PictureMarkerSymbol({
                          url:
                            "http://staticfile.tes.ca/gisMap/speed/80.png" +
                            `?v=${new Date().getTime()}`,
                          width: getSizeByScale(view.scale, 80),
                          height: getSizeByScale(view.scale, 80),
                        }),
                      },
                      {
                        value: "90",
                        symbol: new PictureMarkerSymbol({
                          url:
                            "http://staticfile.tes.ca/gisMap/speed/90.png" +
                            `?v=${new Date().getTime()}`,
                          width: getSizeByScale(view.scale, 90),
                          height: getSizeByScale(view.scale, 90),
                        }),
                      },
                      {
                        value: "100",
                        symbol: new PictureMarkerSymbol({
                          url:
                            "http://staticfile.tes.ca/gisMap/speed/100.png" +
                            `?v=${new Date().getTime()}`,
                          width: getSizeByScale(view.scale, 100),
                          height: getSizeByScale(view.scale, 100),
                        }),
                      },
                      {
                        value: "110",
                        symbol: new PictureMarkerSymbol({
                          url:
                            "http://staticfile.tes.ca/gisMap/speed/110.png" +
                            `?v=${new Date().getTime()}`,
                          width: getSizeByScale(view.scale, 110),
                          height: getSizeByScale(view.scale, 110),
                        }),
                      },
                      {
                        value: "120",
                        symbol: new PictureMarkerSymbol({
                          url:
                            "http://staticfile.tes.ca/gisMap/speed/120.png" +
                            `?v=${new Date().getTime()}`,
                          width: getSizeByScale(view.scale, 120),
                          height: getSizeByScale(view.scale, 120),
                        }),
                      },
                    ],
                  });

                  const postedSpeedLayer = new FeatureLayer({
                    id: "PostedSpeedLayer",
                    title: "Posted Speed",
                    source: lstNewRoadSegmentGraphics,
                    objectIdField: "ObjectId",
                    outFields: ["*"],
                    fields: [
                      {
                        alias: "Posted Speed",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "postedSpeed",
                        nullable: true,
                        type: "string",
                      },
                      {
                        alias: initDataGis.midblockGeoIdName,
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: initDataGis.midblockGeoIdName,
                        nullable: false,
                        type: "string",
                      },
                    ],
                    // popupTemplates can still be viewed on
                    // individual features
                    popupTemplate: {
                      title: "Posted Speed",
                      content: [
                        {
                          type: "fields",
                          fieldInfos: [
                            {
                              fieldName: initDataGis.midblockGeoIdName,
                              label: "Geo ID",
                            },
                            {
                              fieldName: "postedSpeed",
                              label: "Posted Speed",
                            },
                          ],
                        },
                      ],
                    },
                    renderer: renderer,
                  });

                  postedSpeedLayer.load().then(() => {
                    if (isCancelled.current || !isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }
                    removeLayer(map, "PostedSpeedLayer");
                    map.add(postedSpeedLayer);
                    if (legendExpand) legendExpand.expanded = false;
                  });

                  view.watch("scale", (newScale) => {
                    renderer.uniqueValueInfos.forEach((info) => {
                      (info.symbol as PictureMarkerSymbol).width =
                        getSizeByScale(newScale, Number(info.value));
                      (info.symbol as PictureMarkerSymbol).height =
                        getSizeByScale(newScale, Number(info.value));
                    });

                    postedSpeedLayer.renderer = renderer;
                  });

                  layerSuccessfullyLoadedInDomCallback(
                    view,
                    postedSpeedLayer,
                    () => {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                    }
                  );
                });
            });
        });
      };
      view.when(() => {
        if (isCancelled.current) {
          setIsLayerLoading(false);
          return;
        }
        if (
          isLayerExist(map, "PostedSpeedLayer") ||
          isUnderConstruction.current
        ) {
          return;
        }
        queryingAndCreateLayer();
      });
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          if (isLayerExist(map, "PostedSpeedLayer")) {
            isTheLastMovement.current = false;
          }
        },
        1
      );
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          isTheLastMovement.current = true;
          if (!isCancelled.current && !isUnderConstruction.current)
            queryingAndCreateLayer();
        },
        700
      );
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    lstStudyLocations,
    roadSegmentLayer,
    reCreateLayerEvenItExists,
  ]);

  return {
    isTrafficPostedSpeedLayerLoading: isLayerLoading,
  };
};
