import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import { isLayerExist } from "./index";
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import FeatureEffect from "@arcgis/core/layers/support/FeatureEffect";
import Polygon from "@arcgis/core/geometry/Polygon";

type TProps = {
  view: MapView;
  map: Map;
  layers: FeatureLayer[];
  initDataGis: MapSetting;
  setSelectedArea: (value: any) => void;
  setSelectedData: (value: any) => void;
};
export const addSketchViewModel = async ({
  view,
  map,
  layers,
  initDataGis,
  setSelectedArea,
  setSelectedData,
}: TProps) => {
  if (isLayerExist(map, "selected-area-graphic-layer")) return;
  const clearUpSelection = async (reactiveUtils: any) => {
    view.popup.close?.();
    setSelectedArea && setSelectedArea([]);
    setSelectedData && setSelectedData([])
    const clearLayerEffect = async (layer: FeatureLayer) => {
      const layerView = await view.whenLayerView(layer);
      await reactiveUtils.whenOnce(() => !layerView.updating);
      layerView.featureEffect = new FeatureEffect();
    };

    // Clear feature effects for both layers
    await Promise.all(layers.map((layer) => clearLayerEffect(layer)));
  };

  const findMinMaxSelectedAreas = (
    lstPoints: number[][],
    webMercatorUtils: __esri.webMercatorUtils
  ) => {
    if (!setSelectedArea) return;
    lstPoints = lstPoints.map((x) => {
      return webMercatorUtils.xyToLngLat(x[0], x[1]);
    });
    const lstX = lstPoints.flatMap((x) => x[0]);
    const lstY = lstPoints.flatMap((x) => x[1]);
    var xMax = Math.max.apply(null, lstX);
    var xMin = Math.min.apply(null, lstX);
    var yMax = Math.max.apply(null, lstY);
    var yMin = Math.min.apply(null, lstY);
    setSelectedArea({
      longitudeMax: xMax,
      longitudeMin: xMin,
      latitudeMax: yMax,
      latitudeMin: yMin,
    });
  };

  await view.when();

  const graphicLayer = new GraphicsLayer({
    title: "Selected Area",
    id: "selected-area-graphic-layer",
  });
  map.add(graphicLayer);

  await view.when();

  const sketchViewModel = new SketchViewModel({
    view: view,
    layer: graphicLayer,
    pointSymbol: {
      type: "simple-marker",
      color: [255, 255, 255, 0],
      size: "1px",
      outline: {
        color: "gray",
        width: 0,
      },
    },
  });

  view.ui.add("select-by-polygon", "top-left");
  const selectButton = document.getElementById("select-by-polygon");
  view.ui.add("clear-polygon", "top-left");
  const removeButton = document.getElementById("clear-polygon");

  if (selectButton) {
    selectButton.addEventListener("click", () => {
      view.popup.close?.();
      sketchViewModel.create("polygon");
    });
  }

  if (removeButton) {
    removeButton.addEventListener("click", () => {
      clearUpSelection(reactiveUtils);
    });
  }

  await view.when();
  const selectFeatures = async (geometry: __esri.Geometry) => {
    findMinMaxSelectedAreas((geometry as Polygon).rings[0], webMercatorUtils);

    const query: {
      geometry: __esri.Geometry;
      outFields: string[];
      spatialRelationship: "contains";
      returnGeometry: boolean;
    } = {
      geometry,
      outFields: ["*"],
      spatialRelationship: "contains",
      returnGeometry: true,
    };

    const finalData: {
      geoId: any;
      locationType: LocationType;
    }[] = [];

    view?.whenLayerView(layers[0]).then(function (layerView) {
      reactiveUtils
        .whenOnce(() => !layerView.updating)
        .then(() => {
          layerView
            .queryFeatures(query)
            .then(function (results) {
              const graphics = results.features;
              if (graphics.length > 0 && finalData.length === 0) {
                view.goTo(geometry.extent.expand(2)).catch(function (error) {
                  if (error.name !== "AbortError") {
                    console.error(error);
                  }
                });
              }
              if (results.features.length === 0) {
                layerView.featureEffect = new FeatureEffect();
              } else {
                layerView.featureEffect = new FeatureEffect({
                  filter: {
                    geometry: query.geometry,
                    spatialRelationship: "contains",
                    // distance: query.distance,
                    // units: query.units,
                  },
                  excludedEffect: "blur(5px) grayscale(90%) opacity(40%)",
                  includedEffect: " contrast(200%) saturate(200%)",
                });
              }
              // get the attributes to add to selected Data
              const data = graphics.map(function (feature, i) {
                return (
                  Object.keys(feature.attributes)
                    .filter(function (key) {
                      return (
                        [initDataGis.intersectionGeoIdName].indexOf(key) !== -1
                      );
                    })
                    // need to create key value pairs from the feature
                    // attributes so that info can added in selected data
                    .reduce(function (obj: Record<string, any>, key) {
                      obj[key] = feature.attributes[key];
                      return obj;
                    }, {})
                );
              });
              finalData.push(
                ...data.map((x) => ({
                  geoId: x[initDataGis.intersectionGeoIdName],
                  locationType: LocationType.Intersection,
                }))
              );
            })
            .catch();
        });
    });

    view?.whenLayerView(layers[1]).then(function (layerView) {
      reactiveUtils
        .whenOnce(() => !layerView.updating)
        .then(() => {
          layerView
            .queryFeatures(query)
            .then(function (results) {
              const graphics = results.features;
              if (graphics.length > 0 && finalData.length === 0) {
                view.goTo(geometry.extent.expand(2)).catch(function (error) {
                  if (error.name !== "AbortError") {
                    console.error(error);
                  }
                });
              }
              if (results.features.length === 0) {
                layerView.featureEffect = new FeatureEffect({
                  includedEffect: "blur(5px) grayscale(90%) opacity(40%)",
                });
              } else {
                layerView.featureEffect = new FeatureEffect({
                  filter: {
                    geometry: query.geometry,
                    spatialRelationship: "contains",
                    // distance: query.distance,
                    // units: query.units,
                  },
                  excludedEffect: "blur(2px) saturate(200%) opacity(40%)",
                });
              }
              // get the attributes to add to selected Data
              const data = graphics.map(function (feature, i) {
                return (
                  Object.keys(feature.attributes)
                    .filter(function (key) {
                      return (
                        [initDataGis.midblockGeoIdName].indexOf(key) !== -1
                      );
                    })
                    // need to create key value pairs from the feature
                    // attributes so that info can added in selected data
                    .reduce(function (obj: Record<string, any>, key) {
                      obj[key] = feature.attributes[key];
                      return obj;
                    }, {})
                );
              });
              finalData.push(
                ...data.map((x) => ({
                  geoId: x[initDataGis.midblockGeoIdName],
                  locationType: LocationType.Midblock,
                }))
              );
              setSelectedData && setSelectedData(finalData);
            })
            .catch();
        });
    });
  };

  sketchViewModel.on("create", (event) => {
    if (event.state === "complete") {
      graphicLayer.remove(event.graphic);
      selectFeatures(event.graphic.geometry);
    }
  });
};
