//imports
import React, { useEffect, useRef, useState } from "react";
import Tabs from "devextreme-react/tabs";
import { TesGet, TesPost, RequestErrorHandling } from "../../../utils/rest";
import {
  ResponseCode,
  TesMapModule,
} from "../../../types/general/enums/generalEnums";
import {
  RequestResponseResult,
  TabTitle,
} from "../../../types/general/generalTypes";
import { useTranslation } from "react-i18next";
import notify from "devextreme/ui/notify";
import Permission from "../../../components/permission/permision";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import {
  CollisionApiUrl,
  InfrastructureApiUrl,
} from "../../../environment/routeSettings";
import design from "./infrastructureMap.module.scss";
import filterTabData from "./data/filterTab";
import dataTabData from "./data/dataTab";
import { GisMap } from "./components/gisMap/gisMap";
import {
  FromMapRequestDTO,
  VMLocation,
} from "../../../types/infrastructure/dto/locationdDto";
import Data from "./components/data/data";
import {
  GetCollisionDatePeriodRequestDTO,
  ToMapRequestCollisionDTO,
  ToMapResponseCollisionDTO,
} from "../../../types/collision/dto/collisionDtos";
import { useAuth } from "../../../contexts/auth";
import Filter from "./components/filter/filter";
import {
  ToMapRequestTrafficDTO,
  ToMapResponseTrafficDTO,
} from "../../../types/trafficStudy/dtos/studyDto";
import { useNavigate } from "react-router-dom";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { Button, Tooltip } from "devextreme-react";
import { TrafficMapMode } from "../../../types/trafficStudy/enums/trafficStudyEnums";
import { CollisionMapMode } from "../../../types/collision/enums/collisionEnums";
import { MenuStatus, useNavigation } from "../../../contexts/navigation";
import DashboardPermissions from "../../../constants/Permissions/dashboardPermissions";
import getTitle from "../../../components/title/getTitle";
import { GisMap_v1 } from "./components/gisMap/GisMap_v1";
import { GisMap_v2 } from "./components/gisMap/GisMap_v2";
import GisMap_v3 from "./components/gisMap/GisMap_v3";
import { useInfrastructure } from "../../../contexts/infrastructure";

export interface IFilterTimeFrame {
  fromDate: string;
  toDate: string;
}
const GISSummaryMap = () => {
  const [selectedLoc, setSelectedLoc] = useState<VMLocation>(new VMLocation());
  const [titleFilter, setTitleFilter] = useState<TabTitle[]>([]);
  const [titleData, setTitleData] = useState<TabTitle[]>([]);
  const [datePeriod, setDatePeriod] =
    useState<GetCollisionDatePeriodRequestDTO>();

  //Collision
  const [collisionToMapData, setCollisionToMapData] =
    useState<ToMapResponseCollisionDTO | null>(null);
  const [collisionToMapReq, setCollisionToMapReq] =
    useState<ToMapRequestCollisionDTO>(new ToMapRequestCollisionDTO());

  //Traffic
  const [trafficToMapData, setTrafficToMapData] =
    useState<ToMapResponseTrafficDTO | null>(null);
  const [trafficToMapReq, setTrafficToMapReq] =
    useState<ToMapRequestTrafficDTO>(new ToMapRequestTrafficDTO());
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const [locationToZoom, setLocationToZoom] = useState<VMLocation>(
    new VMLocation()
  );

  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const { menuStatus, setMenuStatus } = useNavigation();
  const { infrastructureData } = useInfrastructure();

  const [isLeftSideOpen, setIsLeftSideOpen] = useState(true);
  const [isRightSideOpen, setIsRightSideOpen] = useState(false);
  const [collapseButtonClickCount, setCollapseButtonClickCount] = useState(0);
  const [isAnimationPaused, setIsAnimationPaused] = useState(false);
  const [mapTest, setMapTest] = useState<boolean>(false);
  const [pinned, setPinned] = useState<boolean>(false);
  const [filterTimeFrame, setFilterTimeFrame] = useState<IFilterTimeFrame>({
    fromDate: "",
    toDate: "",
  });
  const [isFilterTimeFrameChanged, setIsFilterTimeFrameChanged] =
    useState(false);
  const filterTimeFrameRef = useRef<IFilterTimeFrame>({
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    if (
      filterTimeFrameRef.current?.fromDate !== filterTimeFrame.fromDate ||
      filterTimeFrameRef.current?.toDate !== filterTimeFrame.toDate
    ) {
      filterTimeFrameRef.current = filterTimeFrame;
      setIsFilterTimeFrameChanged(true);
    } else {
      setIsFilterTimeFrameChanged(false);
    }
  }, [filterTimeFrame, trafficToMapReq, collisionToMapReq]);

  const title = getTitle("/dashboard/trafficOperation", "");
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      if (setMenuStatus) setMenuStatus(MenuStatus.Closed);
      try {
        if (activeLoading) activeLoading(true);
        // await getInitDataGisMap();
        await getMinMaxdate();
        setTitleFilter(filterTabData(""));
        setTitleData(dataTabData(""));
        setTrafficToMapReq({
          ...trafficToMapReq,
          mapMode: TrafficMapMode.None,
        });
        setCollisionToMapReq({
          ...collisionToMapReq,
          mapMode: CollisionMapMode.None,
        });
        if (activeLoading) activeLoading(false);
        if (setMenuStatus) setMenuStatus(MenuStatus.Closed);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //TODO name should changed
  async function getLocationId(inData: FromMapRequestDTO) {
    var res = (await TesPost(
      InfrastructureApiUrl() + "/api/Locations/GetLocationIdByGeoId",
      inData,
      true
    )) as RequestResponseResult<VMLocation>;
    if (res.responseCode === ResponseCode.OK) {
      setCollapsed(true);
      return setSelectedLoc(res.results);
    } else if (res.responseCode === ResponseCode.IdNotExists) {
      if (activeLoading) activeLoading(false);
      await RequestErrorHandling(res);
    }
    await RequestErrorHandling(res);
  }

  async function getMinMaxdate() {
    var postObj: GetCollisionDatePeriodRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId") as string,
    };
    var res = (await TesPost(
      CollisionApiUrl() + "/api/Collisions/GetCollisionDatePeriod",
      postObj,
      true
    )) as RequestResponseResult<GetCollisionDatePeriodRequestDTO>;
    // setFromMapReqCol({ ...fromMapReqCol, toDate: res.results.endDT!, fromDate: new Date(new Date(res.results.startDT!).getFullYear() - 4, 0, 1) })
    setDatePeriod(res.results);
  }

  async function getLocationData(geoId: string, locationType: LocationType) {
    try {
      if (activeLoading) activeLoading(true);
      var postObj: VMLocation = {
        locationType: locationType,
        geoId: geoId,
        customerId: localStorage.getItem("selectedCustomerId") ?? "",
      };
      var res = (await TesPost(
        InfrastructureApiUrl() + "/api/Locations/GetLocationIdByGeoId",
        postObj,
        true
      )) as RequestResponseResult<VMLocation>;
      if (res.responseCode === ResponseCode.OK) {
        setCollapsed(true);
        if (activeLoading) activeLoading(false);
        return res.results;
      } else if (res.responseCode === ResponseCode.InfrastructureNotExists) {
        if (activeLoading) activeLoading(false);
        await RequestErrorHandling(res);
      }
      if (activeLoading) activeLoading(false);
      await RequestErrorHandling(res);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function go2RoadSegment(geoId: string) {
    var res = await getLocationData(geoId, LocationType.Midblock);
    if (res) {
      window.open(`/infrastructure/roadSegmentDetails/${res.id}`, "_blank");
    }
  }
  async function go2Intersection(geoId: string) {
    var res = await getLocationData(geoId, LocationType.Intersection);
    if (res) {
      window.open(`/infrastructure/intersectionDetails/${res.id}`, "_blank");
    }
  }

  function LeftCollapseTrigger(value: boolean) {
    const leftColumn = document.querySelector(".leftColumn");
    if (leftColumn) {
      if (value) {
        leftColumn.classList.add("collapsed");
      } else {
        leftColumn.classList.remove("collapsed");
      }
    }
    setIsLeftSideOpen(value);
    setCollapseButtonClickCount((prevCount) => prevCount + 1);
  }

  function RightCollapseTrigger(value: boolean) {
    const rightColumn = document.querySelector(".rightColumn");
    if (rightColumn) {
      if (value) {
        rightColumn.classList.add("collapsed");
      } else {
        rightColumn.classList.remove("collapsed");
      }
    }
    setIsRightSideOpen(value);
  }

  useEffect(() => {
    if (collapseButtonClickCount >= 2) {
      setIsAnimationPaused(true);
    }
  }, [collapseButtonClickCount]);

  return (
    <Permission
      allowed={[DashboardPermissions.Dashboard_V_TransportationOperation]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div
          className={`${design.row} ${design.gisMapSummary} ${design.page} gisMapSummary`}
        >
          {isLeftSideOpen && (
            <div className={design.leftColumn}>
              <Tabs
                width={"100%"}
                dataSource={titleFilter}
                // selectedIndex={selectedIndex}
                // onOptionChanged={onTabsSelectionChanged}
              />

              <Filter
                setLocationToZoom={setLocationToZoom}
                collisionToMapReq={collisionToMapReq}
                setCollisionToMapData={setCollisionToMapData}
                setCollisionToMapReq={setCollisionToMapReq}
                collapsed={collapsed}
                selectedLoc={selectedLoc}
                setTrafficToMapData={setTrafficToMapData}
                setTrafficToMapReq={setTrafficToMapReq}
                trafficToMapReq={trafficToMapReq}
                setMapTest={setMapTest}
                setFilterTimeFrame={setFilterTimeFrame}
              />
            </div>
          )}

          <div className={design.middleColumn}>
            <div className={design.mapContainer}>
              {/* Toggle Button */}
              {!pinned && (
                <div className={design.rightButtonContainer}>
                  {isRightSideOpen ? (
                    <div>
                      <Button
                        style={{ backgroundColor: "#f0b70d" }}
                        id="OpenedDataButton"
                        icon="fa-solid fa-solid fa-magnifying-glass-chart"
                        onClick={() => RightCollapseTrigger(false)}
                      />
                      <Tooltip
                        target="#OpenedDataButton"
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        hideOnOutsideClick={false}
                      >
                        <div style={{ maxHeight: "200px", overflow: "hidden" }}>
                          {t("closeDataColumn")}
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div>
                      <Button
                        style={{ backgroundColor: "#f0b70d" }}
                        id="ClosedDataButton"
                        icon="fa-solid fa-solid fa-magnifying-glass-chart"
                        onClick={() => RightCollapseTrigger(true)}
                      />
                      <Tooltip
                        target="#ClosedDataButton"
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        hideOnOutsideClick={false}
                      >
                        <div style={{ maxHeight: "200px", overflow: "hidden" }}>
                          {t("openDataColumn")}
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
              <div className={design.leftButtonContainer}>
                {isLeftSideOpen ? (
                  <div>
                    <Button
                      id="openedFilterButton"
                      icon="fa-solid fa-chevron-left"
                      onClick={() => LeftCollapseTrigger(false)}
                      className={
                        isAnimationPaused
                          ? design.leftCollapseButtonPaused
                          : design.leftCollapseButton
                      }
                    />
                    <Tooltip
                      target="#openedFilterButton"
                      showEvent="mouseenter"
                      hideEvent="mouseleave"
                      hideOnOutsideClick={false}
                    >
                      <div style={{ maxHeight: "200px", overflow: "hidden" }}>
                        {t("closeFilterColumn")}
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    <Button
                      id="closedFilterButton"
                      icon="fa-solid fa-chevron-right"
                      onClick={() => LeftCollapseTrigger(true)}
                      className={
                        isAnimationPaused
                          ? design.leftCollapseButtonPaused
                          : design.leftCollapseButton
                      }
                    />
                    <Tooltip
                      target="#closedFilterButton"
                      showEvent="mouseenter"
                      hideEvent="mouseleave"
                      hideOnOutsideClick={false}
                    >
                      <div style={{ maxHeight: "200px", overflow: "hidden" }}>
                        {t("openFilterColumn")}
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
              {/* <div ref={componentRef} onMouseOver={changeBackground} style={{width: '100%', height:"500px"}}>
                {initDataGis !== null && */}
              {/*<GisMap*/}
              {/*  initDataGis={initDataGis}*/}
              {/*  go2Intersection={go2Intersection}*/}
              {/*  go2RoadSegment={go2RoadSegment}*/}
              {/*  tesModule={TesMapModule.Infrastructure}*/}
              {/*  collisionToMapData={collisionToMapData}*/}
              {/*  collisionToMapReq={collisionToMapReq}*/}
              {/*  trafficToMapData={trafficToMapData}*/}
              {/*  trafficToMapReq={trafficToMapReq}*/}
              {/*  getLocationId={getLocationId}*/}
              {/*  hasFullHeight={true}*/}
              {/*  dataButtonTrigger={RightCollapseTrigger}*/}
              {/*  setIsRightSideOpen={setIsRightSideOpen}*/}
              {/*  locationToZoom={locationToZoom}*/}
              {/*  mapTest={mapTest}*/}
              {/*  // mapEl={componentRef}*/}
              {/*/>*/}
              {/*<div style={{ marginTop: "80px" }} />*/}
              {/*<GisMap_v1*/}
              {/*  initDataGis={initDataGis}*/}
              {/*  go2Intersection={go2Intersection}*/}
              {/*  go2RoadSegment={go2RoadSegment}*/}
              {/*  tesModule={TesMapModule.Infrastructure}*/}
              {/*  collisionToMapData={collisionToMapData}*/}
              {/*  collisionToMapReq={collisionToMapReq}*/}
              {/*  trafficToMapData={trafficToMapData}*/}
              {/*  trafficToMapReq={trafficToMapReq}*/}
              {/*  getLocationId={getLocationId}*/}
              {/*  mapTest={mapTest}*/}
              {/*  hasFullHeight={true}*/}
              {/*  clickedOnMap={() => {*/}
              {/*    setIsRightSideOpen(true);*/}
              {/*  }}*/}
              {/*/>*/}
              {/*<GisMap_v2*/}
              {/*  initDataGis={initDataGis}*/}
              {/*  go2Intersection={go2Intersection}*/}
              {/*  go2RoadSegment={go2RoadSegment}*/}
              {/*  tesModule={TesMapModule.Infrastructure}*/}
              {/*  collisionToMapData={collisionToMapData}*/}
              {/*  collisionToMapReq={collisionToMapReq}*/}
              {/*  trafficToMapData={trafficToMapData}*/}
              {/*  trafficToMapReq={trafficToMapReq}*/}
              {/*  getLocationId={getLocationId}*/}
              {/*  mapTest={mapTest}*/}
              {/*  clickedOnMap={() => {*/}
              {/*    setIsRightSideOpen(true);*/}
              {/*  }}*/}
              {/*  shouldReCreateLayers={isFilterTimeFrameChanged}*/}
              {/*/>*/}
              <GisMap_v3
                initDataGis={infrastructureData}
                go2Intersection={go2Intersection}
                go2RoadSegment={go2RoadSegment}
                collisionToMapData={collisionToMapData}
                collisionToMapReq={collisionToMapReq}
                trafficToMapData={trafficToMapData}
                trafficToMapReq={trafficToMapReq}
                getLocationId={getLocationId}
                mapTest={mapTest}
                clickedOnMap={() => {
                  setIsRightSideOpen(true);
                }}
                shouldReCreateLayers={isFilterTimeFrameChanged}
              />

              {/* }
              </div> */}
            </div>
          </div>

          {isRightSideOpen && (
            <div className={design.rightColumn}>
              <Tabs width={"100%"} dataSource={titleData} />
              <Data
                selectedLoc={selectedLoc}
                setCollisionToMapData={setCollisionToMapData}
                setCollisionToMapReq={setCollisionToMapReq}
                collisionToMapReq={collisionToMapReq}
                pinned={pinned}
                setPinned={setPinned}
                datePeriod={datePeriod}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default GISSummaryMap;
