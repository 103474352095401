import React, { useRef, useState, useEffect, useMemo } from "react";
import "../../../../../../components/gisMap/v3/gisMap.scss";
import { ResponseCode } from "../../../../../../types/general/enums/generalEnums";
import LoadPanel from "devextreme-react/load-panel";
import { LocationType } from "../../../../../../types/infrastructure/enums/infrastructureEnums";
import { RequestErrorHandling, TesPost } from "../../../../../../utils/rest";
import { InfrastructureApiUrl } from "../../../../../../environment/routeSettings";
import { RequestResponseResult } from "../../../../../../types/general/generalTypes";
import { DetailedMMSRes } from "../../../../../../types/infrastructure/infrastructureTypes";
import { useTranslation } from "react-i18next";
import { VMLocation } from "../../../../../../types/infrastructure/dto/locationdDto";
import notify from "devextreme/ui/notify";
import { useNavigate } from "react-router-dom";
import {
  TmmsRankLayerLstStudyLocations,
  useMmsRankLayer,
} from "../../../../../../components/gisMap/v3/hookLayers/useMmsRankLayer";
import { useInitializeMap } from "../../../../../../components/gisMap/v3/hooks/useInitializeMap";
import { useIntersectionLayer } from "../../../../../../components/gisMap/v3/hooks/useIntersectionsLayer";
import { useRoadSegmentLayer } from "../../../../../../components/gisMap/v3/hooks/useRoadSegmentLayer";
import { useCustomLayers } from "../../../../../../components/gisMap/v3/hooks/useCustomLayers";
import {
  adjustViewZooming,
  openInGoogleMap,
  openStreetView,
} from "../../../../../../components/gisMap/v3/utils";
import { registerPopupActionFunctions } from "../../../../../../components/gisMap/v3/utils/popupsActions";
import { useInfrastructure } from "../../../../../../contexts/infrastructure";

type TProps = {
  tesModule: any;
  smoothGridData: DetailedMMSRes[];
};
export function GisMap_v3({ tesModule, smoothGridData }: TProps) {
  const { t } = useTranslation();
  const history = useNavigate();
  const { infrastructureData: initDataGis } = useInfrastructure();

  const mapEl = useRef(null);
  const zoomTimerRef = useRef<any | null>(null);
  const mapContainerRef = useRef<any | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [showOverlayText, setShowOverlayText] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);

  async function getLocationData(geoId: string, locationType: LocationType) {
    try {
      setIsNavigating(true);
      var postObj: VMLocation = {
        locationType: locationType,
        geoId: geoId,
        customerId: localStorage.getItem("selectedCustomerId") ?? "",
      };
      var res = (await TesPost(
        InfrastructureApiUrl() + "/api/Locations/GetLocationIdByGeoId",
        postObj,
        true
      )) as RequestResponseResult<VMLocation>;
      if (res.responseCode === ResponseCode.OK) {
        setIsNavigating(false);
        return res.results;
      } else if (res.responseCode === ResponseCode.InfrastructureNotExists) {
        await RequestErrorHandling(res);
        setIsNavigating(false);
      }
      await RequestErrorHandling(res);
    } catch (ex) {
      setIsNavigating(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function go2Intersection(geoId: string) {
    var res = await getLocationData(geoId, LocationType.Intersection);
    if (res) {
      history("/infrastructure/intersectionDetails/" + res.id);
    }
  }
  async function go2RoadSegment(geoId: string) {
    var res = await getLocationData(geoId, LocationType.Midblock);
    if (res) {
      history("/infrastructure/roadSegmentDetails/" + res.id);
    }
  }

  const withoutPermissionZoomTrying = (value: boolean) => {
    if (!value) {
      setShowOverlayText(false);
    } else {
      if (!showOverlayText) {
        setShowOverlayText(true);
        if (zoomTimerRef.current) {
          clearTimeout(zoomTimerRef.current);
        }
        zoomTimerRef.current = setTimeout(() => {
          setShowOverlayText(false);
          zoomTimerRef.current = null;
        }, 4000);
      }
    }
  };

  const rankLayerPreparedData = useMemo(() => {
    const preparedData: TmmsRankLayerLstStudyLocations = [];
    smoothGridData.forEach((item) => {
      const innerData: TmmsRankLayerLstStudyLocations = [];
      if (item.smoothMMS != 0 && item.rin) {
        item.mmsResultDetails.forEach((mmsItem) => {
          innerData.push({
            smoothMMS: item.smoothMMS,
            geoId: mmsItem.geoId,
            rin: item.rin,
          });
        });
      }
      preparedData.push(...innerData);
    });
    return preparedData;
  }, [smoothGridData]);

  const {
    view,
    map,
    legend,
    legendExpand,
    isInitialLoading,
    measurementWidget,
  }: {
    view: any;
    map: any;
    legend: any;
    legendExpand: any;
    isInitialLoading: boolean;
    measurementWidget: any;
  } = useInitializeMap({
    mapEl,
    initDataGis,
    isMovingMapEnabled: true,
    hasLegend: true,
    hasLayerListWidget: true,
    hasPrintWidget: true,
    zoomBehavior: {
      isDefault: false,
      zoomOptions: {
        disableMouseWheel: true,
        disableDoubleClickZoom: true,
        disableDoubleClickWithControlPressed: false,
        disableKeyboardZoom: true,
        disablePopupZoom: true,
      },
      withoutPermissionZoomTryingCallback: withoutPermissionZoomTrying,
    },
  });

  const { intersectionLayer, intersectionLayerId, isIntersectionLoading } =
    useIntersectionLayer({
      view,
      map,
      mapEl,
      initDataGis,
      tesModule,
    });
  const { roadSegmentLayer, roadSegmentLayerId, isRoadSegmentLoading } =
    useRoadSegmentLayer({
      view,
      map,
      mapEl,
      initDataGis,
      tesModule,
    });

  const { isCustomLayerLoading } = useCustomLayers({ map, mapEl, initDataGis });

  const { isMmsRankLayerLoading } = useMmsRankLayer({
    showLayer:
      !isInitialLoading && !isIntersectionLoading && !isRoadSegmentLoading,
    legend,
    map,
    view,
    initDataGis,
    lstStudyLocations: rankLayerPreparedData,
    roadSegmentLayer,
  });

  useEffect(() => {
    if (
      !isInitialLoading &&
      !isRoadSegmentLoading &&
      !isIntersectionLoading &&
      !isCustomLayerLoading &&
      view &&
      roadSegmentLayer &&
      intersectionLayer
    ) {
      adjustViewZooming({
        expand: 1,
        view,
        layers: [intersectionLayer, roadSegmentLayer],
        finishedZoomingCallback: () => {
          setIsLoading(false);
        },
      });
    }
  }, [
    isInitialLoading,
    isRoadSegmentLoading,
    isIntersectionLoading,
    isCustomLayerLoading,
    view,
    roadSegmentLayer,
    intersectionLayer,
  ]);

  useEffect(() => {
    if (
      view &&
      intersectionLayer &&
      roadSegmentLayer &&
      initDataGis &&
      !isIntersectionLoading &&
      !isRoadSegmentLoading &&
      !isInitialLoading
    ) {
      registerPopupActionFunctions(
        {
          view,
          initDataGis,
          intersectionLayer,
          roadSegmentLayer,
        },
        {
          "street-view": {
            openStreetView: openStreetView,
          },
          "open-google-maps": {
            openInGoogleMap: openInGoogleMap,
          },
          "go-to-intersection": {
            go2Intersection: go2Intersection,
          },
          "go-to-roadSegment": {
            go2RoadSegment: go2RoadSegment,
          },
        }
      );
    }
  }, [
    view,
    intersectionLayer,
    roadSegmentLayer,
    initDataGis,
    isIntersectionLoading,
    isRoadSegmentLoading,
    isInitialLoading,
  ]);

  useEffect(() => {
    const mapContainer = mapContainerRef.current;
    if (mapContainer) {
      mapContainer.addEventListener("mousewheel", (event: any) => {
        if (event.ctrlKey) {
          event.preventDefault();
        }
      });
      return () => {
        mapContainer.removeEventListener("mousewheel", (event: any) => {
          if (event.ctrlKey) {
            event.preventDefault();
          }
        });
      };
    }
  }, [mapContainerRef]);

  return (
    <div
      style={{
        position: "relative",
      }}
      ref={mapContainerRef}
    >
      {isLoading && <div className={"mapLoadingContainer"}>{t("loading")}</div>}
      {isMmsRankLayerLoading && (
        <div className={"mapLoadingRowContainer"}>{t("loading")}</div>
      )}
      {showOverlayText && (
        <div
          className={"text_box_v1"}
          onMouseOver={() => {
            view && view.focus();
          }}
        >
          {t("useCtrl+ScrollToZoomTheMap")}
        </div>
      )}
      <div
        ref={mapEl}
        onMouseOver={() => {
          view && view.focus();
        }}
        className={"map_box2"}
      >
        <div
          id="distance"
          className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
          style={{ marginTop: "6.1rem", marginRight: "0.9rem" }}
          title="Distance Measurement Tool"
          onClick={() => {
            measurementWidget.activeTool = "distance";
          }}
        >
          <span className="esri-icon-measure-line"></span>
        </div>

        <div
          id="clear-distance"
          className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
          style={{ marginTop: "8.15rem", marginRight: "0.9rem" }}
          title="Clear Measurement Tool"
          onClick={() => {
            measurementWidget.clear();
          }}
        >
          <span className="esri-icon-trash"></span>
        </div>
        <div id="info" className="esri-widget"></div>
      </div>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={isNavigating}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
    </div>
  );
}
