import { NameValue } from "../types/general/generalTypes";
import i18n from "../react-i18next";
i18n.loadNamespaces("sidebar");

// function Enum2Array<T>(): INameValue[] {
//     var inputEnum: T;
//     var result: INameValue[] = [];
//     for (var n in inputEnum) {
//         if (typeof inputEnum[n] === 'number') {
//             result.push({ name: n.toString(), value: (inputEnum[n]) as unknown as string })
//         }
//     }
//     return result;
// }


function Enum2Array<T extends object>(enumeration: T): NameValue[] {
    return (Object.keys(enumeration) as Array<keyof T>)
        .filter(key => isNaN(Number(key)))
        .filter(key => typeof enumeration[key] === "number")
        .map(key => {
            let res = new NameValue();
            res.name = i18n.t("enum-" + key.toString());
            res.value = enumeration[key] as unknown as string;
            return res;
        });
}


function Enum2Key(myEnum: any, myEnumValue: any) {
    return i18n.t("enum-" + Object.keys(myEnum)[Object.values(myEnum).indexOf(myEnumValue)]);
}

function EnumFlag2Array<T extends object>(enumeration: T, flags: number): number[] {
    var res: number[] = [];
    var lstEnum = Enum2Array(enumeration);
    lstEnum.forEach(ef => {
        if ((Number.parseInt(ef.value) & flags) !== 0) {
            res.push((Number.parseInt(ef.value)));
        }
    })
    return res;
}



export { Enum2Array, EnumFlag2Array,Enum2Key }