import { Form, SelectBox, TextBox } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import { t } from "i18next";
import React from "react";
import Permission from "../../../../../components/permission/permision";
import { TMSPermissions } from "../../../../../constants/Permissions";
import { AddLanguageDto, LanguageDetailsDto } from "../../../../../types/translation/dtos/translationDtos";
import { NameValue } from "../../../../../types/general/generalTypes";
import { useParams } from "react-router-dom";
import { ILanguageParams } from "../../../../../types/translation/traslationTypes";

interface IProps {
  isLocked: boolean;
  selectedLang: LanguageDetailsDto;
  setSelectedLang: React.Dispatch<React.SetStateAction<any>>
  setDataChanged: React.Dispatch<React.SetStateAction<any>>
  baseLanguagesList: NameValue[]
  params: Readonly<Partial<ILanguageParams>>
}


const Details = (props: IProps) => {

  function onValueChanged(key: string, value: any) {
    props.setSelectedLang({ ...props.selectedLang, [key]: value })
    props.setDataChanged(true)
  }

  return (
    <Permission
      allowed={[TMSPermissions.TMS_Admin]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className="row">
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("name")}
                labelMode='floating'
                value={props.selectedLang?.languageName}
                onValueChange={(e) => onValueChanged("languageName", e)}
                className="modalInput"
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("languageCode")}
                labelMode='floating'
                value={props.selectedLang?.languageCode}
                onValueChange={(e) => onValueChanged("languageCode", e)}
                className="modalInput"
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
            {props.params.languageId === "AddNew" ?
                <SelectBox
                  placeholder=""
                  label={t("parentLanguage")}
                  labelMode='floating'
                  onValueChange={(e) => onValueChanged("parentLanguageId", e)}
                  items={props.baseLanguagesList!}
                  valueExpr="value"
                  displayExpr="name"
                  showClearButton={true}
                  searchEnabled={true}
                  style={{ margin: "-2rem 0 1rem 0" }}
                />
                :
                <TextBox
                  placeholder={props.selectedLang.parentLanguageName}
                  value={props.selectedLang.parentLanguageName}
                  label={t("parentLanguage")}
                  labelMode='floating'
                  style={{ margin: "-2rem 0 1rem 0" }}
                  disabled={true}
                />
              }
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("imageUrl")}
                labelMode='floating'
                value={props.selectedLang?.imageAddress!}
                onValueChange={(e) => onValueChanged("imageAddress", e)}
                className="modalInput"
                disabled={props.isLocked}
                style={{ margin: "-2rem 0 1rem 0" }}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("description")}
                labelMode='floating'
                value={props.selectedLang?.description}
                onValueChange={(e) => onValueChanged("description", e)}
                className="modalInput"
                disabled={props.isLocked}
                style={{ margin: "-2rem 0 1rem 0" }}
              />
            </SimpleItem>
          </Form>
        </div>
      </React.Fragment>
    </Permission>
  )
}

export default Details;