import { CheckBox, Switch } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Button as DevExpressButton,
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import Form, { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import Tabs from "devextreme-react/tabs";
import TextBox from "devextreme-react/text-box";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { AuthApiUrl, SettingApiUrl } from "../../environment/routeSettings";
import {
  GridType,
  ModalMode,
  ResponseCode,
} from "../../types/general/enums/generalEnums";
import {
  GridSetting,
  RequestResponseResult,
} from "../../types/general/generalTypes";
import { ReplaceGridProfile } from "../../types/setting/dto/gridSettingDTo";
import {
  CustomerDefaultGridSetting,
  GridProfile,
  SharedGridProfile,
} from "../../types/setting/gridSettingTypes";
import { OnExporting } from "../../utils/dataGridTools";
import { useScreenSize } from "../../utils/media-query";
import {
  RequestErrorHandling,
  TesDelete,
  TesGet,
  TesPost,
} from "../../utils/rest";
import tabTitles from "./data/data";
import "./gridProfilePicker.scss";
import { useGridSettings } from "../../contexts/gridSettings";
import { TMSPermissions } from "../../constants/Permissions";
import Permission from "../permission/permision";

interface IProps {
  gridType: GridType;
  customerId: string;
  dataGridRef: React.RefObject<DataGridRef<any, any>>;
  setGridSetting?: React.Dispatch<React.SetStateAction<GridSetting>>;
  disabled?: boolean;
}

const GridProfilePicker = (props: IProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
  const [showModalShare, setShowModalShare] = useState<boolean>(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [selectedData, setSelectedData] = useState<GridProfile>(
    new GridProfile()
  );
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [initDataUser, setInitDataUser] = useState([]);
  const [initDataGroup, setInitDataGroup] = useState([]);
  const dataGridUserRef = useRef<DataGridRef<any, any>>(null);
  const dataGridGroupRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [popupHeight, setPopupHeight] = useState<number | string>(670);
  const [gridHeight, setGridHeight] = useState<number | string>(340);
  const { isXLarge, is2xLarge } = useScreenSize();

  const {
    allDefaultGridSettings,
    allUserGridSettings,
    addUserGridSettings,
    addDefaultGridSettings,
    removeUserGridSettings,
    updateUserGridSettings,
    isExistedGridSetting,
    isGridSettingsLoading,
    activeSettingIds,
    changeActiveSetting,
    getGridSettingById,
  } = useGridSettings();
  const { user } = useAuth();

  const defaultGridSettings = useMemo(() => {
    return allDefaultGridSettings.filter((x) => x.gridType === props.gridType);
  }, [allDefaultGridSettings, props.gridType]);
  const userGridSettings = useMemo(() => {
    return allUserGridSettings.filter((x) => x.gridType === props.gridType);
  }, [allUserGridSettings, props.gridType]);

  useEffect(() => {
    if (!isGridSettingsLoading) {
      props.dataGridRef.current?.instance().refresh();
    }
  }, [isGridSettingsLoading]);

  useEffect(() => {
    if (isXLarge) {
      setPopupHeight(830);
      setGridHeight(500);
    }
    if (is2xLarge) {
      setPopupHeight("auto");
      setGridHeight("auto");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        activeLoading?.(true);
        await getInitialDataUser();
        await getInitialDataGroup();
        setInitialDataShare();
        activeLoading?.(false);
      } catch (ex) {
        activeLoading?.(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    if (showModalShare) {
      fetchMyAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalShare]);

  const getInitialDataUser = async () => {
    setInitDataUser(
      await TesGet(
        AuthApiUrl() +
          "/api/user/GetUsersOfClient/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  };
  const getInitialDataGroup = async () => {
    setInitDataGroup(
      await TesGet(
        AuthApiUrl() +
          "/api/Groups/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  };
  const setInitialDataShare = () => {
    dataGridGroupRef?.current?.instance().selectRows(
      selectedData.sharedGridProfiles.map((item) => item.toUserId),
      true
    );
    dataGridUserRef?.current?.instance().selectRows(
      selectedData.sharedGridProfiles.map((item) => item.toUserId),
      true
    );
  };

  const onClearFiltersClick = (d: any) => {
    let dialog = custom({
      title: t("warning"),
      messageHtml: t("resetWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: (e) => {
            try {
              localStorage.removeItem(props.gridType.toString());
              props.dataGridRef.current?.instance().refresh();
              notify(t("settingsResetToDefaultSuccessfully"), "success", 5000);
              window.location.reload();
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
        },
      ],
    });
    dialog.show();
  };
  const onActivateDefaultSettingClick = () => {
    let dialog = custom({
      title: t("warning"),
      messageHtml: t("activateDefaultGridSettingWarning"),
      buttons: [
        {
          text: t("yes"),
          onClick: (e) => {
            props.dataGridRef.current
              ?.instance()
              .state(JSON.parse(defaultGridSettings?.[0].gridSetting));
            localStorage.setItem(
              props.gridType.toString(),
              defaultGridSettings?.[0].gridSetting
            );
            changeActiveSetting(props.gridType, defaultGridSettings?.[0].id);
            setShowModal(false);
          },
        },
        {
          text: t("no"),
        },
      ],
    });
    dialog.show();
  };

  const onSaveAsDefaultClick = () => {
    let dialog = custom({
      title: t("addDefaultSetting"),
      messageHtml: t("areYouSureWantToAddDefaultGridSettings?"),
      buttons: [
        {
          text: t("yes"),
          onClick: onSubmitSaveAsOrUpdateDefaultSetting,
        },
        {
          text: t("no"),
        },
      ],
    });
    dialog.show();
  };
  const onUpdateDefaultClick = (d: any) => {
    let dialog = custom({
      title: t("updateDefaultSetting"),
      messageHtml: t("areYouSureWantToUpdateDefaultSetting?"),
      buttons: [
        {
          text: t("yes"),
          onClick: onSubmitSaveAsOrUpdateDefaultSetting,
        },
        {
          text: t("no"),
        },
      ],
    });
    dialog.show();
  };
  const onSubmitSaveAsOrUpdateDefaultSetting = async () => {
    try {
      activeLoading?.(true);
      const payload = new GridProfile();
      payload.gridSetting = JSON.stringify(
        props.dataGridRef.current?.instance().state()
      );
      payload.gridType = props.gridType;
      payload.customerId = props.customerId;
      payload.name = `${GridType[props.gridType]} Default Setting`;
      const res: RequestResponseResult<CustomerDefaultGridSetting> =
        await TesPost(
          `${SettingApiUrl()}/api/CustomerDefaultGridSettings/AddUpdate`,
          payload,
          true
        );
      if (res.responseCode === ResponseCode.OK) {
        addDefaultGridSettings(res.results);
        changeActiveSetting(props.gridType, res.results.id);
        activeLoading?.(false);
        onCloseModalNew();
        notify(t("dataSuccessfullyAdded"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
        activeLoading?.(false);
      }
    } catch (ex) {
      activeLoading?.(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  };

  const onSaveClick = () => {
    if (
      isExistedGridSetting(
        JSON.stringify(props.dataGridRef.current?.instance().state())
      )
    ) {
      notify(t("gridSettingAlreadyExisted"), "error", 5000);
      return;
    }
    setModalMode(ModalMode.Add);
    setShowModalAdd(true);
  };
  const onSubmitAddNewUserSetting = async () => {
    try {
      activeLoading?.(true);
      const payload = new GridProfile();
      payload.gridSetting = JSON.stringify(
        props.dataGridRef.current?.instance().state()
      );
      payload.gridType = props.gridType;
      payload.customerId = props.customerId;
      payload.name = selectedData.name;
      payload.isUserDefault = selectedData.isUserDefault;
      const res: RequestResponseResult<GridProfile> = await TesPost(
        SettingApiUrl() + "/api/GridProfiles/AddGridProfiles",
        payload,
        true
      );
      if (res.responseCode === ResponseCode.OK) {
        // await getInitData();
        addUserGridSettings(res.results);
        changeActiveSetting(props.gridType, res.results.id);
        onCloseModalNew();
        activeLoading?.(false);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
        activeLoading?.(false);
      }
    } catch (ex) {
      activeLoading?.(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  };
  const onEditClick = (d: any) => {
    let newData = new GridProfile();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModalAdd(true);
  };
  const onSubmitEditUserSetting = async () => {
    try {
      activeLoading?.(true);
      const payload = {
        ...selectedData,
        gridSetting: selectedData.gridSetting,
      };
      const res: RequestResponseResult<GridProfile> = await TesPost(
        `${SettingApiUrl()}/api/GridProfiles/Update`,
        payload,
        true
      );
      if (res.responseCode === ResponseCode.OK) {
        updateUserGridSettings(res.results);
        onCloseModalNew();
        activeLoading?.(false);
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
        activeLoading?.(false);
      }
    } catch (ex) {
      activeLoading?.(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  };
  const onDeleteClick = async (d: any) => {
    let dialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e: any) => {
            try {
              activeLoading?.(true);
              const res: RequestResponseResult<any> = await TesDelete(
                `${SettingApiUrl()}/api/GridProfiles/Delete/${d.row.data.id}`,
                true
              );
              if (res.responseCode === ResponseCode.OK) {
                removeUserGridSettings(d.row.data.id);
                if (activeSettingIds[props.gridType] === d.row.data.id) {
                  changeActiveSetting(props.gridType, "");
                }
                activeLoading?.(false);
                notify(t("dataSuccessfullyDeleted"), "success", 5000);
              } else {
                activeLoading?.(false);
                await RequestErrorHandling(res);
              }
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
          },
        },
        {
          text: t("no"),
        },
      ],
    });
    dialog.show();
  };
  const onReplaceClick = async (d: any) => {
    let dialog = custom({
      title: t("replace"),
      messageHtml: t("areYouSureWantToReplace?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e: any) => {
            if (
              isExistedGridSetting(
                JSON.stringify(props.dataGridRef.current?.instance().state())
              )
            ) {
              notify(t("gridSettingAlreadyExisted"), "error", 5000);
              return;
            } else {
              try {
                activeLoading?.(true);
                const stringSetting = JSON.stringify(
                  props.dataGridRef.current?.instance().state()
                );
                const settingId = d.row.data.id;
                const postObj = new ReplaceGridProfile();
                postObj.id = settingId;
                postObj.NewGridSetting = stringSetting;
                const res: RequestResponseResult<GridProfile> = await TesPost(
                  `${SettingApiUrl()}/api/GridProfiles/ReplaceGridSetting`,
                  postObj,
                  true
                );
                if (res.responseCode === ResponseCode.OK) {
                  updateUserGridSettings(res.results);
                  activeLoading?.(false);
                  notify(t("dataSuccessfullyReplaced"), "success", 5000);
                } else {
                  activeLoading?.(false);
                  await RequestErrorHandling(res);
                }
              } catch {
                activeLoading?.(false);
                notify(t("someErrorOccurred"), "error", 5000);
              }
            }
          },
        },
        {
          text: t("no"),
        },
      ],
    });
    dialog.show();
  };
  const onShareClick = (d: any) => {
    let newData = new GridProfile();
    Object.assign(newData, d.row.data);
    setSelectedData(newData);
    setModalMode(ModalMode.Edit);
    setShowModalShare(true);
  };
  const onSubmitShare = async () => {
    try {
      activeLoading?.(true);
      const sharedData: SharedGridProfile[] = [];
      dataGridUserRef?.current
        ?.instance()
        .getSelectedRowKeys()
        .forEach((item: string) => {
          sharedData.push({
            id: selectedData.id,
            toUserId: item,
          });
        });
      dataGridGroupRef?.current
        ?.instance()
        .getSelectedRowKeys()
        .forEach((item: string) => {
          sharedData.push({
            id: selectedData.id,
            toUserId: item,
          });
        });
      const payload = {
        ...selectedData,
        sharedGridProfiles: sharedData,
      };
      const res: RequestResponseResult<any> = await TesPost(
        `${SettingApiUrl()}/api/GridProfiles/Update`,
        payload,
        true
      );
      if (res.responseCode === ResponseCode.OK) {
        updateUserGridSettings(res.results);
        setShowModalShare(false);
        activeLoading?.(false);
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
        activeLoading?.(false);
      }
    } catch (ex) {
      activeLoading?.(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  };

  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };
  const onCloseModalNew = () => {
    if (modalMode === ModalMode.Add) {
      setShowModalAdd(false);
    } else {
      setSelectedData(new GridProfile());
      setShowModalAdd(false);
    }
  };

  const onTabsSelectionChanged = (args: any) => {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  };

  const getShareStatus = (
    data: any
  ): "SHARED_WITH_ME" | "SHARED" | "PRIVATE" => {
    if (data.sharedGridProfiles && data.sharedGridProfiles.length > 0) {
      if (
        data.sharedGridProfiles.find(
          (item: SharedGridProfile) => item.toUserId === user?.id
        )
      ) {
        return "SHARED_WITH_ME";
      } else {
        return "SHARED";
      }
    } else {
      return "PRIVATE";
    }
  };
  const renderShareStatusChip = (d: any) => {
    const shareStatus = getShareStatus(d.data);
    if (shareStatus === "PRIVATE") {
      return <div className={"privateChip"}>{t("private")}</div>;
    } else if (shareStatus === "SHARED") {
      return <div className={"shareChip"}>{t("shared")}</div>;
    } else if (shareStatus === "SHARED_WITH_ME") {
      return <div className={"shareChip"}>{t("sharedWithMe")}</div>;
    }
  };
  const onRowClick = (d: any) => {
    if (d.data.gridSetting) {
      props.dataGridRef.current
        ?.instance()
        .state(JSON.parse(d.data.gridSetting));
      changeActiveSetting(props.gridType, d.data.id);
      setShowModal(false);
      localStorage.setItem(props.gridType.toString(), d.data.gridSetting);
    }
  };
  const renderLastActiveSetting = () => {
    const activeSetting = getGridSettingById(activeSettingIds[props.gridType]);
    if (activeSetting) {
      return (
        <div className={"activeChip"}>
          <span>{`${t("lastActiveSetting")}:`}</span>
          <span style={{ fontWeight: "bold", marginLeft: "4px" }}>
            {activeSetting.name}
          </span>
        </div>
      );
    }
  };
  return (
    <>
      <Button
        style={{ marginLeft: 10 }}
        onClick={() => setShowModal(true)}
        icon="fa-solid fa-gears"
        hint={t("gridViewManager")}
        disabled={props.disabled}
      />

      {/* Main Grid */}
      {showModal && (
        <Popup
          width={"65%"}
          height={popupHeight}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={t("gridViewManager")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => setShowModal(false)}
        >
          <div className={"resetBTNsContainer"}>
            <Button
              className="tes-modal-btn-add resetBTNs"
              onClick={onClearFiltersClick}
              icon="fa-solid fa-rotate-right "
              text={t("resetAll")}
              hint={t("clearAllFiltersAndActivateDefaultSetting")}
            />
            <Button
              className="tes-modal-btn-add resetBTNs"
              onClick={onActivateDefaultSettingClick}
              icon="fa-solid fa-rotate-right "
              text={t("activateDefaultSetting")}
            />
          </div>
          <div className={"gridSettingContainer"}>
            <div className={"horizontalSwitchContainer"}>
              <p>{t("horizontalScrolling")}</p>
              <Switch
                className={"horizontalSwitch"}
                onValueChange={(e) => {
                  const newSettingObj = {
                    ...JSON.parse(localStorage.getItem("GridSetting")!),
                    horizontalScrolling: e,
                  };
                  localStorage.setItem(
                    "GridSetting",
                    JSON.stringify(newSettingObj)
                  );
                  props.setGridSetting && props.setGridSetting(newSettingObj);
                  props.dataGridRef.current?.instance().refresh();
                }}
                value={
                  JSON.parse(localStorage.getItem("GridSetting") ?? "{}")
                    ?.horizontalScrolling ?? false
                }
              />
            </div>
            {
              <Permission
                allowed={[TMSPermissions.TMS_Admin]}
                hasFeedBackElement={false}
              >
                <Button
                  className="tes-modal-btn-add"
                  onClick={
                    defaultGridSettings?.length > 0
                      ? onUpdateDefaultClick
                      : onSaveAsDefaultClick
                  }
                  icon="fa-solid fa-floppy-disk"
                  text={
                    defaultGridSettings?.length > 0
                      ? t("updateDefault")
                      : t("saveAsDefault")
                  }
                />
              </Permission>
            }
          </div>
          <div className={"gridTitleContainer"}>
            <div className={"leftContainer"}>
              <h6 style={{ margin: "0" }}>{t("userSettings")}</h6>
              {renderLastActiveSetting()}
            </div>
            <Button
              onClick={onSaveClick}
              icon="fa-solid fa-floppy-disk"
              hint={t("save")}
            />
          </div>
          <DataGrid
            ref={dataGridRef}
            dataSource={userGridSettings}
            height={gridHeight}
            rowAlternationEnabled={true}
            showBorders={true}
            selection={{ mode: "single" }}
            hoverStateEnabled={true}
            remoteOperations={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onRowClick={onRowClick}
            onExporting={OnExporting}
          >
            <Column
              dataField="name"
              caption={t("name")}
              cellRender={(e) => {
                if (e.data.isUserDefault) {
                  return (
                    <div style={{ display: "flex" }}>
                      {e.data.name}
                      <div className="isDefaultChip">{t("myDefault")}</div>
                    </div>
                  );
                } else {
                  return e.data.name;
                }
              }}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="shareStatus"
              cellRender={renderShareStatusChip.bind(this)}
              caption={t("status")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              type="buttons"
              caption={t("actions")}
              fixedPosition="right"
              width={130}
            >
              <DevExpressButton
                hint={t("edit")}
                visible={({ row }) => {
                  return getShareStatus(row.data) !== "SHARED_WITH_ME";
                }}
                onClick={onEditClick}
                icon="fa-solid fa-pencil"
                cssClass="GridViewManagerActionIconsFirst"
              />
              <DevExpressButton
                hint={t("share")}
                visible={({ row }) => {
                  return getShareStatus(row.data) !== "SHARED_WITH_ME";
                }}
                onClick={onShareClick}
                icon="fa-solid fa-share-nodes"
                cssClass="GridViewManagerActionIcons"
              />
              <DevExpressButton
                hint={t("replace")}
                visible={({ row }) => {
                  return getShareStatus(row.data) !== "SHARED_WITH_ME";
                }}
                onClick={onReplaceClick}
                icon="fa-solid fa-repeat"
                cssClass="GridViewManagerActionIcons"
              />
              <DevExpressButton
                hint={t("delete")}
                onClick={onDeleteClick}
                icon="fa-solid fa-trash-can"
                cssClass="GridViewManagerActionIcons"
              />
            </Column>
          </DataGrid>
        </Popup>
      )}

      {/*Add/Edit */}
      {showModalAdd && (
        <Popup
          width={"40%"}
          height={"Auto"}
          visible={showModalAdd}
          resizeEnabled={true}
          showTitle={true}
          title={modalMode === ModalMode.Add ? t("add") : t("update")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => setShowModalAdd(false)}
        >
          <ScrollView width="100%" height="100%">
            <Form colCount={2}>
              <SimpleItem colSpan={2}>
                <TextBox
                  label={t("name")}
                  labelMode="floating"
                  value={selectedData.name}
                  onValueChange={(e) => onValueChanged("name", e)}
                />
              </SimpleItem>
              <SimpleItem colSpan={2}>
                <CheckBox
                  text={t("setAsMyDefault")}
                  value={!!selectedData.isUserDefault}
                  onValueChanged={(e) =>
                    onValueChanged("isUserDefault", e.value)
                  }
                  className={"setAsDefaultCheckbox"}
                  // style={{ width: "9rem" }}
                />
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModalNew()}
                  text={t("cancel")}
                />
                {modalMode === ModalMode.Add && (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onSubmitAddNewUserSetting}
                    text={t("add")}
                  />
                )}
                {modalMode === ModalMode.Edit && (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onSubmitEditUserSetting}
                    text={t("update")}
                  />
                )}
              </div>
            </div>
          </ScrollView>
        </Popup>
      )}

      {/* Share */}
      {showModalShare && (
        <Popup
          width={"60%"}
          height={"37rem"}
          visible={showModalShare}
          resizeEnabled={true}
          showTitle={true}
          title={t("shareGridLayout")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => setShowModalShare(false)}
        >
          <Form colCount={2}>
            <SimpleItem colSpan={2}>
              <div className={"dx-card"}>
                <Tabs
                  width={"100%"}
                  dataSource={tabTitles}
                  selectedIndex={selectedIndex}
                  onOptionChanged={onTabsSelectionChanged}
                />
                {selectedIndex === 0 && (
                  <div className="div-content">
                    <DataGrid
                      id="gridContainer"
                      ref={dataGridUserRef}
                      dataSource={initDataUser}
                      rowAlternationEnabled={true}
                      showBorders={true}
                      hoverStateEnabled={true}
                      remoteOperations={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      keyExpr="id"
                      height={"20rem"}
                      onExporting={OnExporting}
                    >
                      <Export enabled={true} allowExportSelectedData={true} />
                      <Paging enabled={true} defaultPageSize={100} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                      />

                      <Selection
                        mode="multiple"
                        selectAllMode={"allPages"}
                        showCheckBoxesMode={"always"}
                      />

                      <FilterRow visible={true} applyFilter="auto" />

                      <HeaderFilter visible={true} />

                      <SearchPanel
                        visible={true}
                        width={285}
                        placeholder={t("search...")}
                      />
                      <ColumnChooser
                        width={350}
                        height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                      >
                        <Search enabled />
                      </ColumnChooser>
                      <Column dataField="userName" caption={t("username")}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                      <Column dataField="firstName" caption={t("firstName")}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                      <Column dataField="lastName" caption={t("lastName")}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    </DataGrid>
                  </div>
                )}

                {selectedIndex === 1 && (
                  <div className="div-content">
                    <DataGrid
                      id="gridContainer"
                      ref={dataGridGroupRef}
                      dataSource={initDataGroup}
                      rowAlternationEnabled={true}
                      showBorders={true}
                      hoverStateEnabled={true}
                      remoteOperations={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      keyExpr="id"
                      height={"20rem"}
                      onExporting={OnExporting}
                    >
                      <Export enabled={true} allowExportSelectedData={true} />
                      <Paging enabled={true} defaultPageSize={100} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                      />

                      <Selection
                        mode="multiple"
                        selectAllMode={"allPages"}
                        showCheckBoxesMode={"always"}
                      />

                      <FilterRow visible={true} applyFilter="auto" />

                      <HeaderFilter visible={true} />

                      <SearchPanel
                        visible={true}
                        width={285}
                        placeholder={t("search...")}
                      />
                      <ColumnChooser
                        width={350}
                        height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                      >
                        <Search enabled />
                      </ColumnChooser>

                      <Column dataField="name" caption={t("groupName")}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    </DataGrid>
                  </div>
                )}
                <div
                  className="rightColumn"
                  style={{ marginTop: "-2.5rem", marginBottom: "0.75rem" }}
                >
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => setShowModalShare(false)}
                    text={t("cancel")}
                  />
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onSubmitShare}
                    text={t("share")}
                  />
                </div>
              </div>
            </SimpleItem>
          </Form>
        </Popup>
      )}
    </>
  );
};
export default GridProfilePicker;
