import i18n from "../../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: i18n.t("details"),
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: i18n.t("vehicleClassifications"),
    icon: "fa-solid fa-car",
    content: "Vehicle Classification tab content",
  },
  {
    id: 2,
    text: i18n.t("binMaps"),
    icon: "fa-solid fa-sitemap",
    content: "Bin Maps tab content",
  },
  // {
  //   id: 3,
  //   text: i18n.t("laneConfig"),
  //   icon: "fa-solid fa-sitemap",
  //   content: "Lane",
  // },

];
export default tabTitles;