import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./assets/translations/en.json";

// Initialize i18n with fallback language synchronously
i18n.use(initReactI18next).init({
  resources: {
    defaultEn: {
      common: en.common,
    },
  },
  lng: "defaultEn", // Default language
  fallbackLng: "defaultEn", // Fallback language
  ns: ["common"], // Default namespace
  defaultNS: "common",
});

export default i18n;
