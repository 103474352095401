import { RequestResponseResult } from "../../types/general/generalTypes";
import { RequestErrorHandling, TesGet } from "../../utils/rest";
import { InfrastructureApiUrl } from "../../environment/routeSettings";
import { ResponseCode } from "../../types/general/enums/generalEnums";
import { MapSetting } from "../../types/infrastructure/infrastructureTypes";

export const storeInfrastructureDataKeyName = "TESInfrastructureData";

export const getInfrastructureData = async () => {
  const customerId = localStorage.getItem("selectedCustomerId");
  if (!customerId) return undefined;
  try {
    const res: RequestResponseResult<MapSetting> = await TesGet(
      `${InfrastructureApiUrl()}/api/Settings/GetInfrastructureSettings/${customerId}`,
      true
    );
    if (res.responseCode === ResponseCode.OK) {
      return res.results;
    } else {
      await RequestErrorHandling(res);
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching languages:", error);
    return undefined;
  }
};

export const getStoredInfrastructureData = (): MapSetting | undefined => {
  const storedData = localStorage.getItem(storeInfrastructureDataKeyName);
  if (!storedData) return undefined;
  return JSON.parse(storedData);
};
export const storeInfrastructureData = (data: MapSetting) => {
  localStorage.setItem(storeInfrastructureDataKeyName, JSON.stringify(data));
};
