//imports
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Item,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  Summary,
  Toolbar
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import CustomStore from "devextreme/data/custom_store";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SafetyPermissions from "../../../../../../constants/Permissions/SafetyPermissions";
import { useClientSetting } from "../../../../../../contexts/clientSetting";
import { SiteSubTypeDTO } from "../../../../../../types/safety/dto/safetyDtos";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { useScreenSize } from "../../../../../../utils/media-query";
import CollapsibleCard from "../..//../../../../components/collapsibleCard/collapsibleCard";
import GeneralGisMapManager from "../..//../../../../components/gisMap/generalGisMapManager";
import Permission from "../..//../../../../components/permission/permision";
import { useAuth } from "../..//../../../../contexts/auth";
import { FieldApiUrl, InfrastructureApiUrl } from "../..//../../../../environment/routeSettings";
import { TableFieldRequestDTO } from "../..//../../../../types/field/dto/fieldDTO";
import { FieldCategoryType, FieldType } from "../..//../../../../types/field/enums/fieldEnums";
import { TesField } from "../..//../../../../types/field/fieldType";
import { ResponseCode, TesMapModule } from "../..//../../../../types/general/enums/generalEnums";
import { INameId, LazyLoadingRequest, NameValue, RequestResponseResult } from "../..//../../../../types/general/generalTypes";
import { VMLocation } from "../..//../../../../types/infrastructure/dto/locationdDto";
import { LocationType } from "../..//../../../../types/infrastructure/enums/infrastructureEnums";
import {InfrastructureLinearSystemType, Location} from "../..//../../../../types/infrastructure/infrastructureTypes";
import { GridActualDateCalculator } from "../..//../../../../utils/dateTimeTools";
import { RequestErrorHandling, TesGet, TesPost, TesPut } from "../..//../../../../utils/rest";
import "./locations.scss";
import { useParams } from "react-router-dom";
import { ISiteSubTypeDetailsParam } from "../../../../../../types/safety/safetyTypes";
import {useInfrastructure} from "../../../../../../contexts/infrastructure";

const dataSourceIntersection = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${InfrastructureApiUrl()
      }/api/Locations/GetLocations`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
        filter: { type: LocationType.Intersection }
      } as LazyLoadingRequest,
      true
    );
  },
});

const dataSourceRoadSegment = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${InfrastructureApiUrl()
      }/api/Locations/GetLocations`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
        filter: { type: LocationType.Midblock }
      } as LazyLoadingRequest,
      true
    );
  },
});

// props
interface IProps {
  initData: SiteSubTypeDTO;
  setInitData: React.Dispatch<React.SetStateAction<SiteSubTypeDTO>>;
  areaId: string;
  updateIntersection: (lstIntersections: Location[] | undefined) => void;
  updateRoadSegment: (lstRoadSegments: Location[] | undefined) => void;
  intersections: Location[];
  roadSegments: Location[];
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
}

const Locations = (props: IProps) => {
  const dataGridIntersectionsRef = useRef<DataGridRef<any, any>>(null);
  const dataGridCurrentIntersectionsRef = useRef<DataGridRef<any, any>>(null);
  const dataGridCurrentRoadSegmentsRef = useRef<DataGridRef<any, any>>(null);
  const dataGridRoadSegmentRef = useRef<DataGridRef<any, any>>(null);
  const [showModalIntersection, setShowModalIntersection] = useState(false);
  const [showModalRoadSegment, setShowModalRoadSegment] = useState(false);
  const [initDataFieldsValue, setInitDataFieldsValue] = useState<INameId[]>([]);
  const [initDataFieldsValueR, setInitDataFieldsValueR] = useState<INameId[]>([]);
  const [initDataIntersectionFields, setInitDataIntersectionFields] = useState<TesField[]>([]);
  const [initDataRoadsegmentFields, setInitDataRoadsegmentFields] = useState<TesField[]>([]);
  const [showMap, setShowMap] = useState(false);
  const [showAddMap, setShowAddMap] = useState(false);
  const { generalSetting } = useClientSetting();
  const { activeLoading } = useAuth();
  const [dataGridIntersectionsSource, setDataGridIntersectionsSource] = useState<any[]>([]);
  const [initDataSiteSubTypes, setInitDataSiteSubTypes] = useState<INameId[]>([]);
  const [lstLocationType, setLstLocationType] = useState<NameValue[]>([]);
  const [lstVolumeInheritType, setLstVolumeInheritType] = useState<NameValue[]>([]);
  const params = useParams<ISiteSubTypeDetailsParam>();
  const { is2xLarge } = useScreenSize();
  const [popupHeight, setPopupHeight] = useState<string>("85%")

  const {infrastructureData} = useInfrastructure();

  useEffect(() => {
    if (is2xLarge) { setPopupHeight("60%") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataFieldValue();
        await getInitialDataIntersectionFields();
        await getInitialDataRoadsegmentFields();
        await getInitDataSiteSubTypes();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitDataSiteSubTypes() {
    var res = await TesGet(InfrastructureApiUrl() + "/api/SiteTypes/GetAllSiteSubTypesName/" + localStorage.getItem("selectedCustomerId"), true) as RequestResponseResult<INameId[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteSubTypes(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  function list2Value(dv: any, d: TesField) {
    const i = d.fieldValues.findIndex((x) => x.id === dv[d.name]);
    if (i !== -1) {
      return d.fieldValues[i].name;
    }
    return "";
  }

  //functions for intersections in modal
  async function getInitialDataFieldValue() {
    setInitDataFieldsValue(
      await TesGet(
        FieldApiUrl() +
        "/api/CodeValues/GetAllCustomerCodeValue/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function getInitialDataIntersectionFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem('selectedCustomerId')!,
      categoryTypes: [FieldCategoryType.InfrastructureIntersectionGeneral, FieldCategoryType.InfrastructureIntersectionDetails, FieldCategoryType.InfrastructureIntersectionApproachDetails, FieldCategoryType.InfrastructureIntersectionOperation]
    }
    var res = await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", postOb, true) as TesField[];
    res = res.sort(function (a, b) {
      return (a.gridViewIndex - b.gridViewIndex)
    });
    setInitDataIntersectionFields(res);
  }

  function onCloseModalIntersection() {
    setShowModalIntersection(false);
  }

  function onAddLocations(locationType: number) {
    var selectedValues;
    if (locationType === LocationType.Intersection) {
      selectedValues = dataGridIntersectionsRef.current?.instance().getSelectedRowsData();
    } else {
      selectedValues = dataGridRoadSegmentRef.current?.instance().getSelectedRowsData();
    }
    if (selectedValues !== undefined) {
      var selectedLocations = selectedValues
        .filter(
          (x: any) => !props.intersections?.map((a) => a.id).includes(x._id)
        )
        .map((x: any) => {
          var l = new VMLocation();
          l.id = x._id;
          l.description = x.Description;
          l.geoId = x.GeoId;
          l.locationType = x.LocationType;
          l.customerId = x.CustomerId;
          return l;
        });
      props.setInitData({
        ...props.initData, locations: [...props.initData.locations,
        ...selectedLocations
        ]
      })
      props.setDataChanged(true)
    }
    onCloseModalIntersection();
    onCloseModalRoadSegment();
    dataGridIntersectionsRef.current?.instance().deselectAll();
    dataGridRoadSegmentRef.current?.instance().deselectAll();
  }

  //functions for roadsegment in the modal
  async function getInitialDataRoadsegmentFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem('selectedCustomerId')!,
      categoryTypes: [FieldCategoryType.InfrastructureMidBlockGeneral, FieldCategoryType.InfrastructureMidBlockDetails, FieldCategoryType.InfrastructureMidBlockCrossSection, FieldCategoryType.InfrastructureMidBlockOperation, FieldCategoryType.InfrastructureMidBlockRoot]
    }
    var res = await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", postOb, true) as TesField[];
    res = res.sort(function (a, b) {
      return (a.gridViewIndex - b.gridViewIndex)
    });
    setInitDataRoadsegmentFields(res);

  }

  function onCloseModalRoadSegment() {
    setShowModalRoadSegment(false);
  }

  // delete functions
  async function onDeleteIntersection(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              props.setInitData({ ...props.initData, locations: props.initData.locations?.filter((x) => x.id !== d.row.data.id) })
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }
 
  async function onDeleteRoadSegment(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              props.setInitData({ ...props.initData, locations: props.initData.locations?.filter((x) => x.id !== d.row.data.id) })
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  async function onMultipleIntersectionDeleting(gridRef: React.RefObject<DataGridRef<any, any>>) {
    const selectedRows = gridRef.current?.instance().getSelectedRowsData();
    const selectedIds = selectedRows?.map(row => row.id);

    if (!selectedIds || selectedIds.includes(undefined)) {
        notify(t("someErrorOccurred"), "error", 5000);
        return;
    }

    let myDialog = custom({
        title: t("deleteData"),
        messageHtml: t("areYouSureWantToDeleteSelection?"),
        buttons: [
            {
                text: t("yes"),
                onClick: async (e) => {
                    try {
                        props.setInitData({
                            ...props.initData,
                            locations: props.initData.locations?.filter(
                                (x) => !selectedIds.includes(x.id)
                            ),
                        });
                        gridRef.current?.instance().refresh();
                        notify(t("dataSuccessfullyDeleted"), "success", 5000);
                    } catch (error) {
                        notify(t("someErrorOccurred"), "error", 5000);
                    }

                    return { buttonText: e.component.option("text") };
                },
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") };
                },
            },
        ],
    });
    myDialog.show();
  }

  async function addLocationFromMap(lstData: VMLocation[]) {
    var res = await TesPut(
      `${InfrastructureApiUrl()
      }/api/Locations/GetLocationsByGeoIds/${localStorage.getItem("selectedCustomerId")}`,
      lstData,
      true
    ) as RequestResponseResult<VMLocation[]>;
    if (res.responseCode === ResponseCode.OK) {
      props.setInitData({
        ...props.initData, locations: [...props.initData.locations,
        ...res.results
        ]
      })
    } else {
      await RequestErrorHandling(res);
    }
  }

  const { t } = useTranslation();

  function popupDataGridDataSourceArrayBuilder(dataSource: any) {
    return dataSource.load().then((data: any) => {
      return data.data;
    }).catch((error: any) => {
      console.error(error);
      throw error;
    });
  }

  useEffect(() => {
    popupDataGridDataSourceArrayBuilder(dataSourceIntersection)
      .then((dataArray: any) => {
        setDataGridIntersectionsSource(dataArray);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, [dataSourceIntersection]);

  const LHRSLinearSystemColumns = [
    { dataField: "EndLHRS", caption: t("endLHRS") },
    { dataField: "EndOffset", caption: t("endOffset") },
    { dataField: "LHRS", caption: t("lhrs") },
    { dataField: "NextEndOffset", caption: t("nextEndOffset") },
    { dataField: "NextLHRS", caption: t("nextLHRS") },
    { dataField: "NextStartOffset", caption: t("nextStartOffset") },
    { dataField: "PriorEndOffset", caption: t("priorEndOffset") },
    { dataField: "PriorLHRS", caption: t("priorLHRS") },
    { dataField: "PriorStartOffset", caption: t("priorStartOffset") },
    {
      dataField: "StartOffset",
      caption: t("StartOffset"),
      format: { type: "fixedPoint", precision: 2 },
    },
  ];
  const shouldRenderLHRSColumns =
      infrastructureData?.linearSystemType ===
      InfrastructureLinearSystemType.LHRS;

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_D_Setups]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className={"dx-card responsive-paddings"}>
          <div className="row">
            <div className="rightColumn" style={{ visibility: props.isLocked ? "hidden" : "visible" }}>
              <Button
                style={{ margin: 5 }}
                onClick={() => setShowModalIntersection(true)}
                icon="fa-solid fa-circle-plus"
                text={t("intersection")}
              />
              <Button
                style={{ marginRight: 5 }}
                onClick={() => setShowModalRoadSegment(true)}
                icon="fa-solid fa-circle-plus"
                text={t("roadsegment")}
              />
              <Button
                onClick={() => setShowAddMap(true)}
                icon="fa-solid fa-circle-plus"
                text={t("fromMap")}
              />
            </div>
            <div className="leftColumn">
              <Button
                onClick={() => setShowMap(true)}
                icon="fa-solid fa-map-location-dot"
                style={{ marginRight: 10 }}
                hint={t("showMap")}
              />
            </div>
          </div>
          {/* intesection datagrid */}
          <CollapsibleCard title={t("intersections")}>
            <DataGrid
              id="gridContainer"
              ref={dataGridCurrentIntersectionsRef}
              dataSource={props.initData.locations?.filter((x: VMLocation) => x.locationType === LocationType.Intersection)}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Selection
                mode="multiple"
                selectAllMode={'allPages'}
                showCheckBoxesMode={'always'}
              />
              <Column type="selection" width={50} />
              <Toolbar>
                <Item location="after">
                  <Button
                    icon="fa-solid fa-trash"
                    hint={t("deleteMultipleSelections")}
                    onClick={e => onMultipleIntersectionDeleting(dataGridCurrentIntersectionsRef)}
                  />
                </Item>
                <Item name="exportButton" />
                <Item name="columnChooserButton" />
              </Toolbar>
              <Column alignment="left" dataField="geoId" caption={t("geoId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="description" caption={t("description")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="SiteSubTypeId"
                allowSorting={true}
                visible={false}
                caption={t("siteSubType")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(initDataSiteSubTypes, "name")}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>
              <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDeleteIntersection}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          </CollapsibleCard>
          {/* Roadsegment datagrid */}
          <CollapsibleCard title={t("roadSegments")}>
            <DataGrid
              id="gridContainer"
              ref={dataGridCurrentRoadSegmentsRef}
              dataSource={props.initData.locations?.filter((x: VMLocation) => x.locationType === LocationType.Midblock)}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Selection
                mode="multiple"
                selectAllMode={'allPages'}
                showCheckBoxesMode={'always'}
              />
              <Column type="selection" width={50} />
              <Toolbar>
                <Item location="after">
                  <Button
                    icon="fa-solid fa-trash"
                    hint={t("deleteMultipleSelections")}
                    onClick={e => onMultipleIntersectionDeleting(dataGridCurrentRoadSegmentsRef)}
                  />
                </Item>
                <Item name="exportButton" />
                <Item name="columnChooserButton" />
              </Toolbar>
              <Column alignment="left" dataField="geoId" caption={t("geoId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="description" caption={t("description")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="SiteSubTypeId"
                allowSorting={true}
                visible={false}
                caption={t("siteSubType")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(initDataSiteSubTypes, "name")}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>
              <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDeleteRoadSegment}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          </CollapsibleCard>
        </div>
        {/* intersection modal */}
        <Popup
          width={"65%"}
          height={popupHeight}
          visible={showModalIntersection}
          resizeEnabled={true}
          showTitle={true}
          title={t("add")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModalIntersection()}
        >
          <div style={{ height: "90%" }}>
            <DataGrid
              id="gridContainer"
              ref={dataGridIntersectionsRef}
              dataSource={dataSourceIntersection}
              rowAlternationEnabled={true}
              showBorders={true}
              style={{ height: "100%" }}
              keyExpr="_id"
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            //onContentReady={e => {e.component.deselectAll()}}
            //onFilterValueChange={e => {dataGridIntersectionsRef?.current?.instance().deselectAll()}}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
                  <Column
                    alignment="left"
                    dataField="GeoId"
                    allowSorting={true}
                    caption={t("geoId")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Description"
                    allowSorting={true}
                    caption={t("description")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Inactive"
                    allowSorting={true}
                    caption={t("inactive")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Latitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("latitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Longitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("longitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="SiteSubTypeId"
                    allowSorting={true}
                    visible={false}
                    caption={t("siteSubType")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(initDataSiteSubTypes, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>


                  <Column
                    dataField="IsReviewNeeded"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("isReviewNeeded")}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="OverwriteAutoGenerateDescription"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("overwriteAutoGenerateDescription")}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="ReviewNote"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("reviewNote")}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="TesId"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("TesId")}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="LocationType"
                    allowHeaderFiltering={true}
                    caption={t("locationType")}
                  >
                    <Search enabled />
                    <Lookup
                        dataSource={SortObjectByPropName(lstLocationType, "name")}
                        valueExpr="value"
                        displayExpr="name"
                    />
                    <HeaderFilter>
                        <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="VolumeInheritType"
                    allowHeaderFiltering={true}
                    caption={t("volumeInheritType")}
                    visible={false}
                  >
                    <Search enabled />
                    <Lookup
                        dataSource={SortObjectByPropName(lstVolumeInheritType, "name")}
                        valueExpr="value"
                        displayExpr="name"
                    />
                    <HeaderFilter>
                        <Search enabled />
                    </HeaderFilter>
                  </Column>
              {shouldRenderLHRSColumns &&
                  LHRSLinearSystemColumns.map((col) => (
                      <Column
                          key={col.dataField}
                          dataField={col.dataField}
                          allowSorting={true}
                          allowHeaderFiltering={false}
                          caption={col.caption}
                          format={col.format}
                          visible={false}
                      >
                        <Search enabled />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                  ))}
              {initDataIntersectionFields?.map((d) => {
                if (d.fieldType === FieldType.List) {
                  return (
                    <Column
                      dataField={d.name}
                      allowSorting={false}
                      caption={d.labelText}
                      allowFiltering={true}
                    >
                      <Search enabled />
                      <Lookup
                        dataSource={SortObjectByPropName(d.fieldValues, "name")}
                        valueExpr="id"
                        displayExpr="name"
                      />
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Number) {
                  return (
                    <Column
                      dataField={d.name}
                      allowSorting={false}
                      caption={d.labelText}
                      dataType="number"
                      alignment="left"
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Date) {
                  return (
                    <Column
                      dataField={d.name}
                      calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                      caption={d.labelText}
                      format={generalSetting?.dateFormat}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Time) {
                  return (
                    <Column
                      calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                      dataField={d.name}
                      caption={d.labelText}
                      format={generalSetting?.timeFormat}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.DateTime) {
                  return (
                    <Column
                      dataField={d.name}
                      calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                      caption={d.labelText}
                      format={generalSetting?.dateTimeFormat}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else {
                  return (
                    <Column
                      dataField={d.name}
                      allowSorting={false}
                      caption={d.labelText}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                }
              })}

            </DataGrid>
          </div>

          <div style={{ marginTop: 20 }}>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModalIntersection()}
                text={t("cancel")}
              />
              <Button
                className="tes-modal-btn-add"
                onClick={() => onAddLocations(LocationType.Intersection)}
                text={t("add")}
              />
            </div>
          </div>
        </Popup>
        {/* RoadSegment Modal */}
        <Popup
          width={"65%"}
          height={popupHeight}
          visible={showModalRoadSegment}
          resizeEnabled={true}
          showTitle={true}
          title={t("add")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModalRoadSegment()}
        >
          <div style={{ height: "90%" }}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRoadSegmentRef}
              dataSource={dataSourceRoadSegment}
              rowAlternationEnabled={true}
              showBorders={true}
              style={{ height: "100%" }}
              keyExpr="id"
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            //onContentReady={e => {e.component.deselectAll()}}
            //onFilterValueChange={e => {dataGridRoadSegmentRef?.current?.instance().deselectAll()}}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
                  <Column
                    alignment="left"
                    dataField="GeoId"
                    allowSorting={true}
                    caption={t("geoId")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Description"
                    allowSorting={true}
                    caption={t("description")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Inactive"
                    allowSorting={true}
                    caption={t("inactive")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Latitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("latitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Longitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("longitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="SiteSubTypeId"
                    allowSorting={true}
                    visible={false}
                    caption={t("siteSubType")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(initDataSiteSubTypes, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>

                  <Column
                    dataField="IsReviewNeeded"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("isReviewNeeded")}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="OverwriteAutoGenerateDescription"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("overwriteAutoGenerateDescription")}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="ReviewNote"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("reviewNote")}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    dataField="TesId"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("TesId")}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="LocationType"
                    allowHeaderFiltering={true}
                    caption={t("locationType")}
                  >
                    <Search enabled />
                    <Lookup
                        dataSource={SortObjectByPropName(lstLocationType, "name")}
                        valueExpr="value"
                        displayExpr="name"
                    />
                    <HeaderFilter>
                        <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="VolumeInheritType"
                    allowHeaderFiltering={true}
                    caption={t("volumeInheritType")}
                    visible={false}
                  >
                    <Search enabled />
                    <Lookup
                        dataSource={SortObjectByPropName(lstVolumeInheritType, "name")}
                        valueExpr="value"
                        displayExpr="name"
                    />
                    <HeaderFilter>
                        <Search enabled />
                    </HeaderFilter>
                  </Column>
              {shouldRenderLHRSColumns &&
                  LHRSLinearSystemColumns.map((col) => (
                      <Column
                          key={col.dataField}
                          dataField={col.dataField}
                          allowSorting={true}
                          allowHeaderFiltering={false}
                          caption={col.caption}
                          format={col.format}
                          visible={false}
                      >
                        <Search enabled />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                  ))}
              {initDataRoadsegmentFields?.map((d) => {
                if (d.fieldType === FieldType.List) {
                  return (
                    <Column
                      dataField={d.name}
                      allowSorting={false}
                      caption={d.labelText}
                      allowFiltering={true}
                    >
                      <Search enabled />
                      <Lookup
                        dataSource={SortObjectByPropName(d.fieldValues, "name")}
                        valueExpr="id"
                        displayExpr="name"
                      />
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Number) {
                  return (
                    <Column
                      dataField={d.name}
                      allowSorting={false}
                      caption={d.labelText}
                      dataType="number"
                      alignment="left"
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Date) {
                  return (
                    <Column
                      dataField={d.name}
                      calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                      caption={d.labelText}
                      format={generalSetting?.dateFormat}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Time) {
                  return (
                    <Column
                      calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                      dataField={d.name}
                      caption={d.labelText}
                      format={generalSetting?.timeFormat}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.DateTime) {
                  return (
                    <Column
                      dataField={d.name}
                      calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                      caption={d.labelText}
                      format={generalSetting?.dateTimeFormat}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else {
                  return (
                    <Column
                      dataField={d.name}
                      allowSorting={false}
                      caption={d.labelText}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                }
              })}
            </DataGrid>
          </div>

          <div style={{ marginTop: 20 }}>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModalRoadSegment()}
                text={t("cancel")}
              />
              <Button
                className="tes-modal-btn-add"
                onClick={() => onAddLocations(LocationType.Midblock)}
                text={t("add")}
              />
            </div>
          </div>
        </Popup>
      </React.Fragment>


      {/*Add From Map Popup */}
      <Popup
        width={"50%"}
        height={"auto"}
        // maxHeight={"90%"}
        visible={showAddMap}
        resizeEnabled={true}
        showTitle={true}
        title={t("map")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => setShowAddMap(false)}
      >
        <GeneralGisMapManager
          tesModule={TesMapModule.AreaDetails}
          lstIntersectionGeoIdIn={(props.initData?.locations?.filter(x => x.locationType === LocationType.Intersection).map(x => x.geoId!))!}
          setLstRoadSegmentGeoIdIn={(props.initData?.locations?.filter(x => x.locationType === LocationType.Midblock).map(x => x.geoId!))!}
          showGis={showAddMap}
          setShowMap={setShowAddMap}
          locId="LocationId"
          addLocationFromMap={addLocationFromMap}
        />
      </Popup>


      {/*on Map Popup */}
      <Popup
        width={"50%"}
        height={"auto"}
        // maxHeight={"90%"}
        visible={showMap}
        resizeEnabled={true}
        showTitle={true}
        title={t("map")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => setShowMap(false)}
      >
        <GeneralGisMapManager
          tesModule={TesMapModule.AreaDetailsView}
          lstIntersectionGeoIdIn={(props.initData?.locations?.filter(x => x.locationType === LocationType.Intersection).map(x => x.geoId!))!}
          setLstRoadSegmentGeoIdIn={(props.initData?.locations?.filter(x => x.locationType === LocationType.Midblock).map(x => x.geoId!))!}
          showGis={showMap}
          setShowMap={setShowMap}
          locId="LocationId"
          addLocationFromMap={addLocationFromMap}
        />
      </Popup>
    </Permission>
  );
};

export default Locations;