import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";
import { StudyType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import Expand from "@arcgis/core/widgets/Expand";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import { SimplifiedStudy } from "../../../../types/trafficStudy/trafficStudyTypes";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";

type TProps = {
  showLayer: boolean;
  legendExpand: Expand | null;
  map: Map | null;
  view: MapView | null;
  initDataGis: MapSetting | null | undefined;
  lstStudyLocations: SimplifiedStudy[];
  intersectionLayer: FeatureLayer | null;
  reCreateLayerEvenItExists?: boolean;
};

export const useLatestTMCStudyLayer = ({
  showLayer,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  intersectionLayer,
  reCreateLayerEvenItExists = false,
}: TProps) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "TMCStudyLayer")) {
          removeLayer(map, "TMCStudyLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations?.length !== 0 &&
      intersectionLayer
    ) {
      if (isLayerExist(map, "TMCStudyLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "TMCStudyLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);

      if (isCancelled.current) {
        setIsLayerLoading(false);
        isUnderConstruction.current = false;
        return;
      }

      const intersectionLocations = lstStudyLocations.filter(
        (loc) =>
          loc.locationType.toString() === LocationType.Intersection.toString()
      );

      if (intersectionLocations.length === 0) return;

      let queryIds = generateQueryIds(
        initDataGis,
        "intersectionGeoIdType",
        intersectionLocations
      );

      const strIntersectionQuery = `${initDataGis.intersectionGeoIdName} IN (${queryIds})`;

      const intersectionQuery = {
        outFields: ["*"],
        where: strIntersectionQuery,
        returnGeometry: true,
      };

      const queryingAndCreateLayer = () => {
        setIsLayerLoading(true);
        isUnderConstruction.current = true;
        view?.whenLayerView(intersectionLayer).then(function (layerView) {
          reactiveUtils
            .whenOnce(() => !layerView.updating)
            .then(() => {
              if (isCancelled.current || !isTheLastMovement.current) {
                setIsLayerLoading(false);
                isUnderConstruction.current = false;
                return;
              }
              layerView
                .queryFeatures(intersectionQuery)
                .then(function (results) {
                  console.log("!!length", results.features.length);
                  if (isCancelled.current || !isTheLastMovement.current) {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                    return;
                  }
                  const lstNewIntersectionGraphics = results.features;
                  lstNewIntersectionGraphics.forEach((place) => {
                    const matchingLocation = lstStudyLocations.find(
                      (loc) =>
                        loc.geoId.toString() ===
                        place.attributes[
                          initDataGis.intersectionGeoIdName
                        ].toString()
                    );

                    place.attributes[initDataGis.intersectionGeoIdName] =
                      place.attributes[
                        initDataGis.intersectionGeoIdName
                      ].toString();
                    place.attributes["northApproachVolume"] =
                      matchingLocation?.volumeSummary["northApproachVolume"];
                    place.attributes["southApproachVolume"] =
                      matchingLocation?.volumeSummary["southApproachVolume"];
                    place.attributes["westApproachVolume"] =
                      matchingLocation?.volumeSummary["westApproachVolume"];
                    place.attributes["eastApproachVolume"] =
                      matchingLocation?.volumeSummary["eastApproachVolume"];
                    place.attributes["totalVolume"] =
                      matchingLocation?.volumeSummary["totalVolume"];
                    place.attributes["studyType"] = matchingLocation?.studyType;
                  });

                  const renderer = new UniqueValueRenderer({
                    field: "studyType",
                    uniqueValueInfos: [
                      {
                        value: StudyType.TMC,
                        symbol: new PictureMarkerSymbol({
                          url: "http://staticfile.tes.ca/gisMap/latestStudies/tmc2.png",
                          width: 15,
                          height: 15,
                        }),
                      },
                    ],
                  });

                  const tmcStudyLayer = new FeatureLayer({
                    id: "TMCStudyLayer",
                    title: "TMC Study",
                    source: lstNewIntersectionGraphics,
                    objectIdField: "ObjectId",
                    outFields: ["*"],
                    fields: [
                      {
                        alias: initDataGis.intersectionGeoIdName,
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: initDataGis.intersectionGeoIdName,
                        nullable: false,
                        type: "string",
                      },
                      {
                        alias: " ",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "studyType",
                        nullable: true,
                        type: "integer",
                      },
                      {
                        alias: "North Approach Volume",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "northApproachVolume",
                        nullable: true,
                        type: "integer",
                      },
                      {
                        alias: "South Approach Volume",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "southApproachVolume",
                        nullable: true,
                        type: "integer",
                      },
                      {
                        alias: "East Approach Volume",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "eastApproachVolume",
                        nullable: true,
                        type: "integer",
                      },
                      {
                        alias: "West Approach Volume",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "westApproachVolume",
                        nullable: true,
                        type: "integer",
                      },
                      {
                        alias: "Total Volume",
                        defaultValue: undefined,
                        editable: true,
                        length: -1,
                        name: "totalVolume",
                        nullable: true,
                        type: "integer",
                      },
                    ],
                    popupTemplate: {
                      title: "Latest TMC Study",
                      content: [
                        {
                          type: "fields",
                          fieldInfos: [
                            {
                              fieldName: initDataGis.intersectionGeoIdName,
                              label: "Geo ID",
                            },
                            {
                              fieldName: "northApproachVolume",
                              label: "North Approach Volume",
                            },
                            {
                              fieldName: "southApproachVolume",
                              label: "South Approach Volume",
                            },
                            {
                              fieldName: "eastApproachVolume",
                              label: "East Approach Volume",
                            },
                            {
                              fieldName: "westApproachVolume",
                              label: "West Approach Volume",
                            },
                            {
                              fieldName: "totalVolume",
                              label: "Total Volume",
                            },
                          ],
                        },
                      ],
                    },
                    renderer: renderer,
                  });

                  tmcStudyLayer.load().then(() => {
                    if (isCancelled.current || !isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }
                    removeLayer(map, "TMCStudyLayer");
                    map.add(tmcStudyLayer);
                    if (legendExpand) legendExpand.expanded = false;
                  });

                  layerSuccessfullyLoadedInDomCallback(
                    view,
                    tmcStudyLayer,
                    () => {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                    }
                  );
                });
            });
        });
      };
      view.when(() => {
        if (isCancelled.current) {
          setIsLayerLoading(false);
          return;
        }
        if (isLayerExist(map, "TMCStudyLayer") || isUnderConstruction.current) {
          return;
        }
        queryingAndCreateLayer();
      });
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          if (isLayerExist(map, "TMCStudyLayer")) {
            isTheLastMovement.current = false;
          }
        },
        1
      );
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          isTheLastMovement.current = true;
          if (!isCancelled.current && !isUnderConstruction.current)
            queryingAndCreateLayer();
        },
        700
      );
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    lstStudyLocations,
    intersectionLayer,
    reCreateLayerEvenItExists,
  ]);

  return {
    isLatestTMCStudyLayerLoading: isLayerLoading,
  };
};
