import { loadModules } from "esri-loader";
import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";
import Legend from "@arcgis/core/widgets/Legend";
import Expand from "@arcgis/core/widgets/Expand";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import { LocationTrafficSummaryDTO } from "../../../../types/trafficStudy/dtos/studyDto";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import ClassBreaksRenderer from "@arcgis/core/renderers/ClassBreaksRenderer";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";

type TProps = {
  showLayer: boolean;
  legend: Legend | null;
  legendExpand: Expand | null;
  map: Map | null;
  view: MapView | null;
  initDataGis: MapSetting | null | undefined;
  lstStudyLocations: LocationTrafficSummaryDTO[];
  roadSegmentLayer: FeatureLayer | null;
  reCreateLayerEvenItExists?: boolean;
};

export const useTrafficComplianceLayer = ({
  showLayer,
  legend,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
  reCreateLayerEvenItExists = false,
}: TProps) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "ComplianceLayer")) {
          removeLayer(map, "ComplianceLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations?.length !== 0 &&
      roadSegmentLayer
    ) {
      if (isLayerExist(map, "ComplianceLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "ComplianceLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);

      if (isCancelled.current) {
        setIsLayerLoading(false);
        isUnderConstruction.current = false;
        return;
      }

      const midblockLocations = lstStudyLocations.filter(
        (loc) =>
          loc.locationType.toString() === LocationType.Midblock.toString()
      );

      if (midblockLocations.length === 0) return;

      let queryIds = generateQueryIds(
        initDataGis,
        "midblockGeoIdType",
        midblockLocations
      );

      const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

      const roadSegmentQuery = {
        outFields: ["*"],
        where: strRoadSegmentQuery,
        returnGeometry: true,
      };

      const queryingAndCreateLayer = () => {
        setIsLayerLoading(true);
        isUnderConstruction.current = true;
        view.whenLayerView(roadSegmentLayer).then((layerView) => {
          reactiveUtils
            .whenOnce(() => !layerView.updating)
            .then(() => {
              if (isCancelled.current || !isTheLastMovement.current) {
                setIsLayerLoading(false);
                isUnderConstruction.current = false;
                return;
              }
              layerView.queryFeatures(roadSegmentQuery).then((results) => {
                if (isCancelled.current || !isTheLastMovement.current) {
                  setIsLayerLoading(false);
                  isUnderConstruction.current = false;
                  return;
                }
                const graphics = results.features;
                console.log("!!length", graphics.length);

                // Attach roadSegmentAADT to the graphics attributes
                graphics.forEach((graphic) => {
                  const matchedLocation = lstStudyLocations.find(
                    (loc) =>
                      loc.geoId.toString() ===
                      graphic.attributes[
                        initDataGis.midblockGeoIdName
                      ].toString()
                  );
                  graphic.attributes["compliance"] =
                    matchedLocation?.compliance;
                });
                const complianceLayer = new FeatureLayer({
                  id: "ComplianceLayer",
                  title: "ComplianceLayer",
                  source: graphics,
                  objectIdField: "ObjectId",
                  outFields: ["*"],
                  fields: [
                    {
                      alias: "Compliance",
                      defaultValue: undefined,
                      editable: true,
                      length: -1,
                      name: "compliance",
                      nullable: true,
                      type: "double",
                    },
                    {
                      alias: initDataGis.midblockGeoIdName,
                      defaultValue: undefined,
                      editable: true,
                      length: -1,
                      name: initDataGis.midblockGeoIdName,
                      nullable: false,
                      type: "string",
                    },
                  ],
                  renderer: new ClassBreaksRenderer({
                    field: "compliance",
                    defaultSymbol: new SimpleLineSymbol({
                      color: "black",
                      width: "5px",
                    }),
                    defaultLabel: "no data",
                    classBreakInfos: [
                      {
                        minValue: 0,
                        maxValue: 50,
                        symbol: new SimpleLineSymbol({
                          color: "#8B4000",
                          width: "6px",
                        }),
                        label: "< 50%",
                      },
                      {
                        minValue: 50,
                        maxValue: 60,
                        symbol: new SimpleLineSymbol({
                          color: "#ff0000",
                          width: "6px",
                        }),
                        label: "50% - 60%",
                      },
                      {
                        minValue: 60,
                        maxValue: 70,
                        symbol: new SimpleLineSymbol({
                          color: "#FFA500",
                          width: "6px",
                        }),

                        label: "60% - 70%",
                      },
                      {
                        minValue: 70,
                        maxValue: 80,
                        symbol: new SimpleLineSymbol({
                          color: "#F6BE00",
                          width: "6px",
                        }),

                        label: "70% - 80%",
                      },
                      {
                        minValue: 80,
                        maxValue: 90,
                        symbol: new SimpleLineSymbol({
                          color: "#FFFF00",
                          width: "6px",
                        }),

                        label: "80% - 90%",
                      },
                      {
                        minValue: 90,
                        maxValue: 100,
                        symbol: new SimpleLineSymbol({
                          color: "#00FF00",
                          width: "6px",
                        }),
                        label: "90% - 100%",
                      },
                    ],
                  }),
                  // popupTemplates can still be viewed on
                  // individual features
                  popupTemplate: {
                    title: "Compliance",
                    content: [
                      {
                        type: "fields",
                        fieldInfos: [
                          {
                            fieldName: initDataGis.midblockGeoIdName,
                            label: "Geo ID",
                          },
                          {
                            fieldName: "compliance",
                            label: "Compliance",
                          },
                        ],
                      },
                    ],
                  },
                });

                complianceLayer.load().then(() => {
                  if (isCancelled.current || !isTheLastMovement.current) {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                    return;
                  }
                  removeLayer(map, "ComplianceLayer");
                  map.add(complianceLayer);
                  if (legend)
                    legend.layerInfos.push({
                      layer: complianceLayer,
                    });
                  if (legendExpand) legendExpand.expanded = true;
                });

                layerSuccessfullyLoadedInDomCallback(
                  view,
                  complianceLayer,
                  () => {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                  }
                );
              });
            });
        });
      };
      view.when(() => {
        if (isCancelled.current) {
          setIsLayerLoading(false);
          return;
        }
        if (
          isLayerExist(map, "ComplianceLayer") ||
          isUnderConstruction.current
        ) {
          return;
        }
        queryingAndCreateLayer();
      });
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          if (isLayerExist(map, "ComplianceLayer")) {
            isTheLastMovement.current = false;
          }
        },
        1
      );
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          isTheLastMovement.current = true;
          if (!isCancelled.current && !isUnderConstruction.current)
            queryingAndCreateLayer();
        },
        700
      );
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    lstStudyLocations,
    roadSegmentLayer,
    reCreateLayerEvenItExists,
  ]);

  return {
    isTrafficComplianceLayerLoading: isLayerLoading,
  };
};
