//imports
import { ContextMenu } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GeneralGisMapManager from "../../../components/gisMap/generalGisMapManager";
import GridFilter from "../../../components/gridFilter/gridFilter";
import GridProfilePicker from "../../../components/gridProfilePicker/gridProfilePicker";
import Permission from "../../../components/permission/permision";
import getTitle from "../../../components/title/getTitle";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { FieldApiUrl, TrafficApiUrl } from "../../../environment/routeSettings";
import { TableFieldRequestDTO } from "../../../types/field/dto/fieldDTO";
import { FieldCategoryType, FieldLocation2Show, FieldType } from "../../../types/field/enums/fieldEnums";
import { TesField } from "../../../types/field/fieldType";
import { GridType, ResponseCode, TesMapModule } from "../../../types/general/enums/generalEnums";
import { GridSetting, INameId, LazyLoadingRequest, RequestResponseResult } from "../../../types/general/generalTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../../utils/dateTimeTools";
import { EnumFlag2Array } from "../../../utils/enumTools";
import { TesGet, TesPost } from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";

const dataSource = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${TrafficApiUrl()
      }/api/TrafficSections/GetTrafficSections`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
      } as LazyLoadingRequest,
      true
    );
  },
});

const TrafficSections = () => {
  const [showMap, setShowMap] = useState(false);
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const navigateToTrafficDetails = useCallback(() => {
    history("/infrastructure/trafficSectionDetails/AddNew");
  }, [history]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('other')}
  ];
  const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { customerDivisions } = useClientSetting();

  const title = getTitle('/infrastructure/TrafficSections', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataFields();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem('selectedCustomerId')!,
      categoryTypes: [FieldCategoryType.InfrastructureTrafficSectionGeneral]
    }
    var res = await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", postOb, true) as TesField[];
    res = res.sort(function (a, b) {
      return (a.gridViewIndex - b.gridViewIndex)
    });
    setInitDataFields(res);
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data._id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/infrastructure/trafficSectionDetails/${e.data._id}`, "_blank");
      } else {
        history(`/infrastructure/trafficSectionDetails/${e.data._id}`);
      }
    }
  }

  return (
    <Permission
      allowed={[
        InfrastructurePermissions.Infrastructure_E_TrafficSection,
        InfrastructurePermissions.Infrastructure_V_TrafficSection,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.data._id, "/infrastructure/trafficSectionDetails/")}
          />
          <h2 className={"content-block"}>{t("trafficSections")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="leftColumn" style={{ display: "flex" }}>

                  <Button onClick={() => setShowMap(true)}
                    icon="fa-solid fa-map-location-dot"
                  />

                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId") ?? ""}
                    gridType={GridType.TrafficSection}
                    dataGridRef={dataGridRef}
                    setGridSetting={setGridSetting}
                  />

                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => setShowFilter(true)}
                    icon="fa-solid fa-filter"
                    hint={t("filter")}
                  />

                </div>
                {/* <div className="rightColumn">
                  <Permission
                    allowed={[
                      InfrastructurePermissions.Infrastructure_E_TrafficSection,
                    ]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={navigateToTrafficDetails}
                      icon="fa-solid fa-circle-plus"
                      text={t("trafficSection")}
                    />
                  </Permission>
                </div> */}
              </div>
              <DataGrid
                id="gridContainer"
                key={"_id"}
                ref={dataGridRef}
                dataSource={dataSource}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClicked}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={e => { setSelectedRowData(e) }}
                columnAutoWidth={gridSetting.horizontalScrolling}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.TrafficSection.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                  deferred={true}
                  // @ts-ignore
                  maxFilterLengthInRequest={10000}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="_id"
                  allowSorting={true}
                  caption={t("id")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(customerDivisions!, "name")}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="Name"
                  allowSorting={true}
                  caption={t("name")}
                  width={400}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="GeoId"
                  allowSorting={true}
                  caption={t("geoId")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="LHRS"
                  allowSorting={true}
                  caption={t("lhrs")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="Offset"
                  allowSorting={true}
                  caption={t("offset")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="NextTrafficSectionId"
                  allowSorting={true}
                  caption={t("nextTrafficSectionId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="PreviousTrafficSectionId"
                  allowSorting={true}
                  caption={t("previousTrafficSectionId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="OverlapTrafficSectionId"
                  allowSorting={true}
                  caption={t("overlapTrafficSectionId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="ExcludeMatchingTrending"
                  allowSorting={true}
                  caption={t("excludeMatchingTrending")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="IsNonAssumedRoad"
                  allowSorting={true}
                  caption={t("isNonAssumedRoad")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="IsBorderCrossing"
                  allowSorting={true}
                  caption={t("isBorderCrossing")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="IsOverLap"
                  allowSorting={true}
                  caption={t("isOverLap")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="IsPDCS"
                  allowSorting={true}
                  caption={t("isPDCS")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="IsDeleted"
                  allowSorting={true}
                  caption={t("isDeleted")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                {initDataFields && initDataFields.length > 0 && initDataFields.map((d) => {
                  if (d.fieldType === FieldType.List) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        allowFiltering={true}
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <Search enabled />
                        <Lookup
                          dataSource={SortObjectByPropName(d.fieldValues, "name")}
                          valueExpr="id"
                          displayExpr="name"
                        />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Number) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="number"
                        alignment="left"
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Date) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="date"
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        format={generalSetting?.dateFormat}
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Time) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        format={generalSetting?.timeFormat}
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.DateTime) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        format={generalSetting?.dateTimeFormat}
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Boolean) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="boolean"
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        allowSorting={true}
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  }
                })}
              </DataGrid>
            </div>
          </div>

          <GridFilter
            dataGridRef={dataGridRef}
            setShowModal={setShowFilter}
            showModal={showFilter}
            tesModule={TesMapModule.Collision}
          />

          {/* Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.Infrastructure}
              dataGridRef={dataGridRef}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="_id"
            />
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default TrafficSections;
