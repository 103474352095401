import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  PropsWithChildren,
  useCallback,
} from "react";

import {
  fetchDefaultGridSettings,
  fetchUserGridSettings,
  getStoredDefaultGridSettings,
  getStoredUserGridSettings,
  storeDefaultGridSettings,
  storeDefaultGridSettingsKeyName,
  storeUserGridSettings,
  storeUserGridSettingsKeyName,
} from "./utils";
import {
  CustomerDefaultGridSetting,
  GridProfile,
} from "../../types/setting/gridSettingTypes";
import { GridType } from "../../types/general/enums/generalEnums";
import {
  storeSelectedLangKeyName,
  storeTranslationsKeyName,
} from "../translations/utils";

type TActiveSettingIds = {
  [key in GridType]: string;
};
type TGridsContext = {
  allDefaultGridSettings: CustomerDefaultGridSetting[];
  allUserGridSettings: GridProfile[];
  addDefaultGridSettings: (newSetting: CustomerDefaultGridSetting) => void;
  addUserGridSettings: (newSetting: GridProfile) => void;
  updateUserGridSettings: (newGridSetting: GridProfile) => void;
  removeUserGridSettings: (id: string) => void;
  isExistedGridSetting: (gridSetting: string) => boolean;
  isGridSettingsLoading: boolean;
  activeSettingIds: TActiveSettingIds;
  changeActiveSetting: (gridType: GridType, activeId: string) => void;
  getGridSettingById: (
    gridSettingId: string
  ) => CustomerDefaultGridSetting | GridProfile | undefined;
  clearStoredGridSettings: () => void;
};
const initialData: TGridsContext = {
  allDefaultGridSettings: [],
  allUserGridSettings: [],
  addDefaultGridSettings: (newSetting) => {},
  addUserGridSettings: (newSetting) => {},
  updateUserGridSettings: (newGridSetting) => {},
  removeUserGridSettings: (id) => {},
  isExistedGridSetting: (gridSetting) => false,
  isGridSettingsLoading: true,
  activeSettingIds: {} as TActiveSettingIds,
  changeActiveSetting: (gridType, activeId) => {},
  getGridSettingById: (gridSettingId) => undefined,
  clearStoredGridSettings: () => {},
};

export const GridSettingsContext = createContext(initialData);

const useGridSettings = () => useContext(GridSettingsContext);

function GridSettingsProvider({ children }: PropsWithChildren) {
  const [defaultSettings, setDefaultSettings] = useState<
    CustomerDefaultGridSetting[]
  >([]);
  const [userSettings, setUserSettings] = useState<GridProfile[]>([]);
  const [activeSettingIds, setActiveSettingIds] = useState<TActiveSettingIds>(
    {} as TActiveSettingIds
  );
  const [isLoading, setIsLoading] = useState(true);

  const configAllGridsByGridSettings = () => {
    let activeSettings = {} as TActiveSettingIds;
    const storedDefaultSettings = getStoredDefaultGridSettings();
    const storedUserSettings = getStoredUserGridSettings();
    storedDefaultSettings.map((item) => {
      localStorage.setItem(item.gridType.toString(), item.gridSetting);
      activeSettings[item.gridType] = item.id;
    });
    storedUserSettings.map((item) => {
      if (item.isUserDefault) {
        localStorage.setItem(item.gridType.toString(), item.gridSetting);
        activeSettings[item.gridType] = item.id;
      }
    });
    setActiveSettingIds(activeSettings);
    setIsLoading(false);
  };
  const fetchAndConfigGridSettings = async () => {
    setIsLoading(true);
    const defaultGridSettings = await fetchDefaultGridSettings();
    const userGridSettings = await fetchUserGridSettings();
    setDefaultSettings(defaultGridSettings);
    setUserSettings(userGridSettings);
    storeDefaultGridSettings(defaultGridSettings);
    storeUserGridSettings(userGridSettings);
    configAllGridsByGridSettings();
  };

  const addUserGridSettings = (newSetting: GridProfile) => {
    setUserSettings((prev) => {
      return [...prev, newSetting];
    });
    if (newSetting.isUserDefault) {
      setUserSettings((prev) => {
        return prev.map((item, index) => {
          if (item.id !== newSetting.id) {
            return {
              ...item,
              isUserDefault: false,
            };
          }
          return item;
        });
      });
    }
  };
  const addDefaultGridSettings = (newSetting: CustomerDefaultGridSetting) => {
    setDefaultSettings((prev) => {
      return [...prev, newSetting];
    });
  };
  const updateUserGridSettings = (newGridSetting: GridProfile) => {
    const updatedUserSettings = userSettings.map((setting: any) => {
      if (setting.id === newGridSetting.id) {
        return {
          ...setting,
          ...newGridSetting,
        };
      } else {
        return setting;
      }
    });
    if (newGridSetting.isUserDefault) {
      updatedUserSettings.forEach((item, index, array) => {
        if (item.id !== newGridSetting.id) {
          array[index] = {
            ...item,
            isUserDefault: false,
          };
        }
      });
    }
    setUserSettings(updatedUserSettings);
  };
  const removeUserGridSettings = (id: string) => {
    const updatedUserSettings = userSettings.filter(
      (setting: any) => setting.id !== id
    );
    setUserSettings(updatedUserSettings);
  };

  const getGridSettingById = (gridSettingId: string) => {
    const setting: any = [...userSettings, ...defaultSettings].find(
      (setting: any) => setting.id === gridSettingId
    );
    if (setting) {
      return setting;
    } else {
      return undefined;
    }
  };

  const isExistedGridSetting = (gridSetting: string) => {
    return (
      userSettings.some((setting) => setting.gridSetting === gridSetting) ||
      defaultSettings.some((setting) => setting.gridSetting === gridSetting)
    );
  };
  const changeActiveSetting = (gridType: GridType, activeId: string) => {
    setActiveSettingIds((prev) => {
      return {
        ...prev,
        [gridType]: activeId,
      };
    });
  };
  const clearStoredGridSettings = useCallback(() => {
    localStorage.removeItem(storeUserGridSettingsKeyName);
    localStorage.removeItem(storeDefaultGridSettingsKeyName);
    setActiveSettingIds({} as TActiveSettingIds);
    setUserSettings([]);
    setDefaultSettings([]);
  }, []);

  useEffect(() => {
    fetchAndConfigGridSettings();
  }, []);

  const value = {
    allDefaultGridSettings: defaultSettings,
    allUserGridSettings: userSettings,
    addDefaultGridSettings,
    addUserGridSettings,
    updateUserGridSettings,
    removeUserGridSettings,
    isExistedGridSetting,
    isGridSettingsLoading: isLoading,
    activeSettingIds,
    changeActiveSetting,
    getGridSettingById,
    clearStoredGridSettings,
  };
  return (
    <GridSettingsContext.Provider value={value}>
      {children}
    </GridSettingsContext.Provider>
  );
}

export { GridSettingsProvider, useGridSettings };
