import i18n from "../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: i18n.t("details"),
    icon: "fa-solid fa-circle-info",
    content: "Details tab content",
  },
  {
    id: 1,
    text: i18n.t("map"),
    icon: "fa-solid fa-location-dot",
    content: "Map tab content",
  },
  {
    id: 2,
    text: i18n.t("predictedVolumeRawData"),
    icon: "fa-solid fa-square-poll-vertical",
    content: "Details tab content",
  },
  {
    id: 3,
    text: i18n.t("predictedResultSummary"),
    icon: "fa-solid fa-hashtag",
    content: "Work Order tab content",
  },
  {
    id: 4,
    text: i18n.t("predictedSignalWarrants"),
    icon: "fa-solid fa-traffic-light",
    content: "Work Order tab content",
  },
  {
    id: 5,
    text: i18n.t("studies"),
    icon: "fa-solid fa-traffic-light",
    content: "Study tab content",
  },
];
export default tabTitles;