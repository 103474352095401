import i18n from "../../../../react-i18next";

const tabTitles = [
    {
        id: 0,
        text: i18n.t('overview'),
        icon: 'fa-solid fa-magnifying-glass-chart',
        content: 'Overiew tab content',
    },
    {
        id: 1,
        text: i18n.t('emphasisArea'),
        icon: 'fa-solid fa-magnifying-glass-location',
        content: 'Emphasis Area tab content',
    },
    {
        id: 3,
        text: i18n.t('comparative'),
        icon: 'fa-solid fa-scale-unbalanced',
        content: 'Comparative tab content',
    },
    {
        id: 4,
        text: i18n.t('heatMap'),
        icon: 'fa-solid fa-map-location-dot',
        content: 'Comparative tab content',
    },
];
export default tabTitles;

