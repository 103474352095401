import i18n from "../../../../../../react-i18next";

const tabTitles = [
    {
        id: 0,
        text: i18n.t('users'),
        icon: 'fa-solid fa-users',
        content: 'Users tab content',
    },
    {
        id: 1,
        text: i18n.t('roles'),
        icon: 'fa-solid fa-user-gear',
        content: 'Roles tab content',
    }
];
export default tabTitles;