import { useRef, useEffect, useState } from "react";
import "@arcgis/core/assets/esri/themes/light/main.css";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Legend from "@arcgis/core/widgets/Legend.js";
import Expand from "@arcgis/core/widgets/Expand.js";
import Measurement from "@arcgis/core/widgets/Measurement.js";

import {
  addBasemapGalleryWidget,
  addLayerListWidget,
  addMeasurementWidget,
  addPrintWidget,
  customizeZoomingBehavior,
} from "../utils";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";

type TProps = {
  mapEl: any;
  initDataGis: MapSetting | null | undefined;
  isMovingMapEnabled: boolean;
  hasLegend?: boolean;
  hasLayerListWidget?: boolean;
  hasPrintWidget?: boolean;
  hasMeasurementWidget?: boolean;
  zoomBehavior?: {
    isDefault: boolean;
    zoomOptions: any;
    withoutPermissionZoomTryingCallback?: (isTrying: boolean) => void;
  };
};

export function useInitializeMap({
  mapEl,
  initDataGis,
  isMovingMapEnabled,
  hasLegend = false,
  hasLayerListWidget = true,
  hasPrintWidget = true,
  hasMeasurementWidget = true,
  zoomBehavior = {
    isDefault: true,
    zoomOptions: {},
    withoutPermissionZoomTryingCallback: (value) => {},
  },
}: TProps) {
  const _view = useRef<MapView | null>(null);
  const _map = useRef<Map | null>(null);
  const _legend = useRef<Legend | null>(null);
  const _legendExpand = useRef<Expand | null>(null);
  const _measurementWidget = useRef<Measurement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const initMap = async () => {
    setIsLoading(true);
    const map = new Map({
      basemap: "osm",
    });
    const view = new MapView({
      map,
      container: mapEl.current,
      zoom: 12,
      center: [initDataGis?.latitude || 0, initDataGis?.longitude || 0],
    });

    if (hasLegend) {
      view.when(() => {
        const legend = new Legend({
          view: view,
          style: "card",
          layerInfos: [],
        });
        const legendExpand = new Expand({
          content: legend,
          view: view,
          expanded: false,
        });

        view.ui.add(legendExpand, "bottom-left");
        _legend.current = legend;
        _legendExpand.current = legendExpand;
      });
    }
    if (hasMeasurementWidget)
      _measurementWidget.current = (await addMeasurementWidget(view)) || null;

    if (hasLayerListWidget) await addLayerListWidget(view);

    if (hasPrintWidget) await addPrintWidget(view);

    if (initDataGis?.baseLayersEnabled)
      await addBasemapGalleryWidget(view, initDataGis);

    if (!zoomBehavior.isDefault)
      await customizeZoomingBehavior(
        view,
        zoomBehavior.zoomOptions,
        zoomBehavior?.withoutPermissionZoomTryingCallback
      );

    if (isMovingMapEnabled) {
      view.when(() => {
        view.ui.add("go-to-location", "top-left");
        view.ui.add("go-to-coordinate", "top-left");
      });
    }

    _view.current = view;
    _map.current = map;

    setIsLoading(false);
  };
  useEffect(() => {
    if (!initDataGis || !initDataGis?.id || !mapEl.current || _view.current)
      return;
    initMap();
  }, [mapEl, initDataGis]);

  return {
    view: _view.current,
    map: _map.current,
    legend: _legend.current,
    legendExpand: _legendExpand.current,
    measurementWidget: _measurementWidget.current,
    isInitialLoading: isLoading,
  };
}
