import { useEffect, useState } from "react";
import { isLayerExist } from "../utils";
import Map from "@arcgis/core/Map";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";

type TProps = {
  map: Map | null;
  mapEl: any;
  initDataGis?: MapSetting | null;
};
export function useCustomLayers({ map, mapEl, initDataGis }: TProps) {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  useEffect(() => {
    if (map && initDataGis && mapEl && initDataGis?.gisLayers?.length > 0) {
      setIsLayerLoading(true);

      const featureLayers: FeatureLayer[] = [];
      initDataGis.gisLayers.forEach((layer) => {
        featureLayers.push(
          new FeatureLayer({
            url: layer.url,
            title: layer.name,
            id: `${layer.name}-layer`,
            popupTemplate: {
              lastEditInfoEnabled: false,
            },
          })
        );
      });
      featureLayers.forEach((layer) => {
        if (!isLayerExist(map, `${layer.id}`)) {
          map.add(layer);
        }
      });

      setIsLayerLoading(false);
    }
  }, [mapEl, initDataGis, map]);

  return {
    isCustomLayerLoading: isLayerLoading,
  };
}
