import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import Legend from "@arcgis/core/widgets/Legend";
import Expand from "@arcgis/core/widgets/Expand";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import { LocationTrafficSummaryDTO } from "../../../../types/trafficStudy/dtos/studyDto";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import ClassBreaksRenderer from "@arcgis/core/renderers/ClassBreaksRenderer";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";

type TProps = {
  showLayer: boolean;
  legend: Legend | null;
  legendExpand: Expand | null;
  map: Map | null;
  view: MapView | null;
  initDataGis: MapSetting | null | undefined;
  lstStudyLocations: LocationTrafficSummaryDTO[];
  roadSegmentLayer: FeatureLayer | null;
  reCreateLayerEvenItExists?: boolean;
};

export const useTrafficRoadSegmentAADTLayer = ({
  showLayer,
  legend,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
  reCreateLayerEvenItExists = false,
}: TProps) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "RoadSegmentAADTLayer")) {
          removeLayer(map, "RoadSegmentAADTLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations?.length !== 0 &&
      roadSegmentLayer
    ) {
      if (isLayerExist(map, "RoadSegmentAADTLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "RoadSegmentAADTLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);

      if (isCancelled.current) {
        setIsLayerLoading(false);
        isUnderConstruction.current = false;
        return;
      }

      const midblockLocations = lstStudyLocations.filter(
        (loc) =>
          loc.locationType.toString() === LocationType.Midblock.toString()
      );

      if (midblockLocations.length === 0) return;

      let queryIds = generateQueryIds(
        initDataGis,
        "midblockGeoIdType",
        midblockLocations
      );

      const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

      const roadSegmentQuery = {
        outFields: ["*"],
        where: strRoadSegmentQuery,
        returnGeometry: true,
      };

      const queryingAndCreateLayer = () => {
        setIsLayerLoading(true);
        isUnderConstruction.current = true;
        view?.whenLayerView(roadSegmentLayer).then((layerView) => {
          reactiveUtils
            .whenOnce(() => !layerView.updating)
            .then(() => {
              if (isCancelled.current || !isTheLastMovement.current) {
                setIsLayerLoading(false);
                isUnderConstruction.current = false;
                return;
              }

              layerView.queryFeatures(roadSegmentQuery).then((results) => {
                if (isCancelled.current || !isTheLastMovement.current) {
                  setIsLayerLoading(false);
                  isUnderConstruction.current = false;
                  return;
                }

                const graphics = results.features;

                // Attach roadSegmentAADT to the graphics attributes
                graphics.forEach((graphic) => {
                  const matchedLocation = lstStudyLocations.find(
                    (loc) =>
                      loc.geoId.toString() ===
                      graphic.attributes[
                        initDataGis.midblockGeoIdName
                      ].toString()
                  );
                  graphic.attributes["roadSegmentAADT"] =
                    matchedLocation?.roadSegmentAADT.toString();
                });
                const roadSegmentAADTLayer = new FeatureLayer({
                  id: "RoadSegmentAADTLayer",
                  title: "RoadSegmentAADTLayer",
                  source: graphics,
                  objectIdField: "ObjectId",
                  outFields: ["*"],
                  fields: [
                    {
                      alias: "RoadSegment AADT",
                      name: "roadSegmentAADT",
                      type: "string",
                      editable: true,
                      nullable: true,
                    },
                    {
                      alias: initDataGis.midblockGeoIdName,
                      name: initDataGis.midblockGeoIdName,
                      type: "string",
                      editable: true,
                      nullable: false,
                    },
                  ],
                  popupTemplate: {
                    title: "Road Segment AADT",
                    content: [
                      {
                        type: "fields",
                        fieldInfos: [
                          {
                            fieldName: initDataGis.midblockGeoIdName,
                            label: "Geo ID",
                          },
                          { fieldName: "roadSegmentAADT", label: "Total" },
                        ],
                      },
                    ],
                  },
                  renderer: new ClassBreaksRenderer({
                    field: "roadSegmentAADT",
                    classBreakInfos: [
                      {
                        minValue: 1,
                        maxValue: 1000,
                        symbol: new SimpleLineSymbol({
                          color: "#00ff00",
                          width: "6px",
                        }),
                        label: "> 0",
                      },
                      {
                        minValue: 1001,
                        maxValue: 5000,
                        symbol: new SimpleLineSymbol({
                          color: "#FFFF00",
                          width: "7px",
                        }),

                        label: "> 1000",
                      },
                      {
                        minValue: 5001,
                        maxValue: 10000,
                        symbol: new SimpleLineSymbol({
                          color: "#ee8200",
                          width: "8px",
                        }),

                        label: "> 5000",
                      },
                      {
                        minValue: 10001,
                        maxValue: 20000,
                        symbol: new SimpleLineSymbol({
                          color: "#ff0000",
                          width: "9px",
                        }),

                        label: "> 10000",
                      },
                      {
                        minValue: 20001,
                        maxValue: 500000,
                        symbol: new SimpleLineSymbol({
                          color: "#8B4000",
                          width: "10px",
                        }),

                        label: "> 20000",
                      },
                    ],
                  }),
                });

                roadSegmentAADTLayer.load().then(() => {
                  if (isCancelled.current || !isTheLastMovement.current) {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                    return;
                  }
                  removeLayer(map, "RoadSegmentAADTLayer");
                  map.add(roadSegmentAADTLayer);
                  if (legend)
                    legend.layerInfos.push({
                      layer: roadSegmentAADTLayer,
                    });
                  if (legendExpand) legendExpand.expanded = true;
                });

                layerSuccessfullyLoadedInDomCallback(
                  view,
                  roadSegmentAADTLayer,
                  () => {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                  }
                );
              });
            });
        });
      };
      view.when(() => {
        if (isCancelled.current) {
          setIsLayerLoading(false);
          return;
        }
        if (
          isLayerExist(map, "RoadSegmentAADTLayer") ||
          isUnderConstruction.current
        ) {
          return;
        }
        queryingAndCreateLayer();
      });
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          if (isLayerExist(map, "RoadSegmentAADTLayer")) {
            isTheLastMovement.current = false;
          }
        },
        1
      );
      afterMapStationaryWithDebounceCallback(
        view,
        () => {
          isTheLastMovement.current = true;
          if (!isCancelled.current && !isUnderConstruction.current)
            queryingAndCreateLayer();
        },
        700
      );
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    lstStudyLocations,
    roadSegmentLayer,
    reCreateLayerEvenItExists,
  ]);

  return {
    isTrafficRoadSegmentAADTLayerLoading: isLayerLoading,
  };
};
