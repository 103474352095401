import { INameId, NameValueNumber } from "../general/generalTypes";
import { Permission } from "../general/enums/generalEnums";
import {
  FieldType,
  FieldCategoryType,
  FieldServiceType,
  SimplifiedTesField,
  SimplifiedFieldCategory,
  FieldPermission,
  FieldLocation2Show,
} from "./enums/fieldEnums";

export class TesField {
  [key: string]: any;
  id!: string;
  customerId!: string;
  fieldCategoryType!: FieldCategoryType;
  fieldType!: FieldType;
  fieldServiceType!: FieldServiceType;
  name!: string;
  description!: string;
  permission!: FieldPermission;
  labelText!: string;
  isRequired!: boolean;
  fieldValues!: INameId[];
  validationRule!: string;
  isUpperCase!: boolean;
  integrationAddress!: string;
  codeGroupName!: String;
  codeGroupId!: string;
  index!: number;
  gridViewIndex!: number;
  detailViewIndex!: number;
  mobileAppIndex!: number;
  isRequiredInApp!: boolean;
  isRequiredInWeb!: boolean;
  isShowInApp!: boolean;
  isShowInWeb!: boolean;
  isShowInDriver!: boolean;
  isShowInPassenger!: boolean;
  isShowInPedestrian!: boolean;
  isCalculatedField!: boolean;
  rule!: string;
  exactDT!: boolean;
  readOnlyField!: boolean;
  isCalculatedEqualField!: boolean;
  fieldLocation2Show!: FieldLocation2Show;
  geocodingIndex!: number;
  showInGeocodingPage!: boolean;
  fieldDefaultValue!: string;
}

export class CodeValue {
  id!: string;
  tesId!: string;
  name!: string;
  code!: string;
  ncdbName!: string;
  ncdbCodeNum!: number;
  ncdbDescription!: string;
  index!: number;
  codeGroup!: CodeGroup;
  codeGroupId!: string;
  value1!: string;
  value2!: string;
  rule!: string;
  isForNull!: boolean;
}
export class CodeGroup {
  [key: string]: any;
  id!: string;
  moduleOfModuleTabId!: string;
  name!: string;
  tesId!: string;
  fieldServiceType!: FieldServiceType;
  customerId!: string;
  codeNum!: number;
  ncdbCodeNum!: number;
  fields!: TesField[];
  codeValues!: CodeValue[];
}

export class SimplifiedField {
  id!: string;
  customerId!: string;
  simplifiedTesField!: SimplifiedTesField;
  fieldType!: FieldType;
  simplifiedFieldCategory!: SimplifiedFieldCategory;
  fieldValue!: string;
  rule!: string;
  displayFieldValue!: string;
}

export class TesFieldRowType {
  fieldType!: FieldType;
  name!: string;
  simplifiedTesField!: number;
  simplifiedFieldCategory!: number;
  values!: NameValueNumber[];
}
