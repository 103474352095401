//#region imports
import { ContextMenu, TagBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import DropDownButton from "devextreme-react/drop-down-button";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GeneralGisMapManager from "../../../components/gisMap/generalGisMapManager";
import GridProfilePicker from "../../../components/gridProfilePicker/gridProfilePicker";
import Permission from "../../../components/permission/permision";
import RecalculateCollision from "../../../components/recalculateCollision/recalculateCollision";
import TesReportViewer from "../../../components/reportViewer/ReportViewer";
import getTitle from "../../../components/title/getTitle";
import ValidationReport from "../../../components/validationReport/validationReport";
import {
  CollisionPermissions,
  TMSPermissions,
} from "../../../constants/Permissions";
import reportLocations from "../../../constants/reportLocations";
import { useAuth } from "../../../contexts/auth";
import {
  CollisionApiUrl,
  InfrastructureApiUrl,
  ReportApiUrl,
} from "../../../environment/routeSettings";
import { TesField } from "../../../types/field/fieldType";
import {
  GridType,
  ResponseCode,
  TesMapModule,
} from "../../../types/general/enums/generalEnums";
import {
  GridSetting,
  LazyLoadingRequest,
  RequestResponseResult,
} from "../../../types/general/generalTypes";
import { VMLocation } from "../../../types/infrastructure/dto/locationdDto";
import { Area } from "../../../types/infrastructure/infrastructureTypes";
import {
  ClientReport,
  ReportLocation,
  UiReportRequest,
} from "../../../types/report/reportTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { useScreenSize } from "../../../utils/media-query";
import { RequestErrorHandling, TesPost } from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import "./simplifiedCollision.scss";
//#endregion
// const dataSource = createStore({
//   loadUrl: `${CollisionApiUrl()
//     }/api/Collisions/GetCollisions/${localStorage.getItem("selectedCustomerId")}`,
//   onBeforeSend: (method, ajaxOptions) => {
//     ajaxOptions.xhrFields = { withCredentials: false };
//     ajaxOptions.headers = {
//       // Authorization: `Bearer ${localStorage.getItem("tesToken")}`,
//       'X-CSRF': '1'
//     };
//   },
// });

const dataSource = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${CollisionApiUrl()}/api/Collisions/GetSimplifiedCollisions`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
      } as LazyLoadingRequest,
      true
    );
  },
});

const SimplifiedCollisions = () => {
  //#region consts
  const { activeLoading } = useAuth();
  const [showMap, setShowMap] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [siteListFilter, setSiteListFilter] = useState<TesField[]>([]);
  const [initDataSiteList, setInitDataSiteList] = useState<Area[]>([]);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [showValidationReport, setShowValidationReport] =
    useState<boolean>(false);
  const [initDataReports, setInitDataReports] = useState<ClientReport[]>([]);
  const [selectedReport, setSelectedReport] = useState<ClientReport>(
    new ClientReport()
  );
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const dropDownOptions = { width: 215 };
  const { is2xLarge } = useScreenSize();
  const [reportPopupHeight, setReportPopupHeight] = useState<string>("80%");
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('other')}
  ];
  const [gridSetting, setGridSetting] = useState<GridSetting>(
    JSON.parse(localStorage.getItem("GridSetting")!) || new GridSetting()
  );
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  //#endregion consts

  //#region functions

  const title = getTitle("/collision/simplifiedCollisions", "");
  useEffect(() => {
    document.title = title;
  }, [title]);
  useEffect(() => {
    if (is2xLarge) {
      setReportPopupHeight("55%");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitDataSiteList();
        await getInitialDataReports();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigateToCollisionDetails = useCallback(() => {
    history("/collision/collisionDetails/AddNew");
  }, [history]);

  async function getInitDataSiteList() {
    const postObj = {
      customerId: localStorage.getItem("selectedCustomerId"),
      divisionId: localStorage.getItem("selectedDivisionId"),
    };
    const res = (await TesPost(
      InfrastructureApiUrl() + "/api/areas",
      postObj,
      true
    )) as RequestResponseResult<Area[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteList(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  async function getInitialDataReports() {
    try {
      var postObj: UiReportRequest = {
        customerId: localStorage.getItem("selectedCustomerId")!,
        lstLocations: [reportLocations.Collision_Collisions],
      };

      var res: ClientReport[] = await TesPost(
        ReportApiUrl() + "/api/ClientReports/GetPageReports",
        postObj,
        true
      );
      var validationReport = new ClientReport();
      validationReport.id = "validationReport";
      validationReport.name = t("validationReport");
      var reportLoc = new ReportLocation();
      reportLoc.name = reportLocations.Collision_Collisions;
      validationReport.reportLocations = [reportLoc];
      res.push(validationReport);
      setInitDataReports(res);
    } catch (ex) {
      notify(t("errorInFetchReports") + ex, "error", 5000);
    }
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data._id !== undefined) {
      if (isCtrlKeyPressed) {
        window.open(`/collision/collisionDetails/${e.data._id}`, "_blank");
      } else {
        history(`/collision/collisionDetails/${e.data._id}`);
      }
    }
  }

  function onCloseFilterModal() {
    setShowFilter(false);
  }

  async function onOpenReport(d: any) {
    var gridData = await dataGridRef.current?.instance().getSelectedRowsData();
    if (gridData?.length === 0) {
      notify(t("pleaseSelectSomeData"), "warning", 5000);
      return;
    }
    if (d !== null) {
      setSelectedReport(d.itemData);
    }
    if (d.itemData.id === "validationReport") {
      setShowValidationReport(true);
    } else {
      setShowReportModal(true);
      setSelectedIds(gridData!.map((x) => x._id) as string[]);
    }
  }

  function onCloseReportModal() {
    setShowReportModal(false);
    dataGridRef.current?.instance().deselectAll();
  }

  async function onValueChanged(e: any) {
    setSiteListFilter(e);
  }

  async function onApplySiteListFilter() {
    try {
      const postObj = siteListFilter;
      if (activeLoading) activeLoading(true);
      const res: VMLocation[] = await TesPost(
        InfrastructureApiUrl() + "/api/areas/GetGeoIds",
        postObj,
        true
      );
      dataGridRef.current?.instance().deselectAll();
      dataGridRef.current?.instance().beginUpdate();
      dataGridRef.current
        ?.instance()
        .columnOption("GeoId", "filterOperations", ["="]);
      dataGridRef.current
        ?.instance()
        .columnOption("GeoId", "filterType", "include");
      dataGridRef.current?.instance().columnOption(
        "GeoId",
        "filterValues",
        res.map((x) => x.geoId)
      );
      dataGridRef.current?.instance().endUpdate();
      setShowFilter(false);
      if (activeLoading) activeLoading(false);
      //notify(t("dataSuccessfullyFiltered"), "success", 5000);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
      setShowFilter(false);
    }
  }

  //#endregion functions
  return (
    <Permission allowed={[TMSPermissions.TMS_Admin]} hasFeedBackElement={true}>
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.data._id,
                "/collision/collisionDetails/"
              )
            }
          />
          <h2 className={"content-block"}>{t("simplifiedCollisions")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="leftColumn" style={{ display: "flex" }}>
                  <Button
                    onClick={() => setShowMap(true)}
                    icon="fa-solid fa-map-location-dot"
                    style={{ marginRight: 10 }}
                    hint={t("showMap")}
                  />
                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId")!}
                    gridType={GridType.CollisionSimplified}
                    dataGridRef={dataGridRef}
                    setGridSetting={setGridSetting}
                  />

                  {/* <Button
                  icon="fa-solid fa-rotate-right"
                  style={{ marginRight: 10 }}
                  //onClick={() => onResetGridView()}
                  hint={t("resetGridView")}
                /> */}

                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => setShowFilter(true)}
                    icon="fa-solid fa-filter"
                    hint={t("filter")}
                  />

                  <DropDownButton
                    style={{ marginLeft: 10 }}
                    icon="fa-solid fa-chart-line"
                    hint={t("report")}
                    items={SortObjectByPropName(
                      initDataReports?.filter((x: ClientReport) =>
                        [reportLocations.Collision_Collisions].some(
                          (a) =>
                            x.reportLocations?.map((x) => x.name).indexOf(a) >=
                            0
                        )
                      ),
                      "name"
                    )}
                    dropDownOptions={{
                      width: MaxDropdownItemWidthCalculator(
                        SortObjectByPropName(
                          initDataReports?.filter((x: ClientReport) =>
                            [reportLocations.Collision_Collisions].some(
                              (a) =>
                                x.reportLocations
                                  ?.map((x) => x.name)
                                  .indexOf(a) >= 0
                            )
                          ),
                          "name"
                        )
                      ),
                    }}
                    displayExpr="name"
                    onItemClick={(d: any) => onOpenReport(d)}
                  />
                  {/* <Permission
                  allowed={[CollisionPermissions.Collision_E, CollisionPermissions.Collision_D]}
                  hasFeedBackElement={false}> */}
                  <RecalculateCollision datagridRef={dataGridRef} />

                  {/* </Permission> */}
                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[CollisionPermissions.Collision_D]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={navigateToCollisionDetails}
                      icon="fa-solid fa-circle-plus"
                      text={t("collision")}
                    />
                  </Permission>
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                key="id"
                dataSource={dataSource}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClicked}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                onExporting={OnExporting}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
                columnAutoWidth={gridSetting.horizontalScrolling}
                //onContentReady={e => {e.component.deselectAll()}}
                //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.CollisionSimplified.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                  deferred={true}
                  // @ts-ignore
                  maxFilterLengthInRequest={10000}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                {/* <SearchPanel visible={true} width={285} placeholder={t("search...")} /> */}
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
              </DataGrid>
            </div>
          </div>
          {/* validation report */}
          <ValidationReport
            collisionIds={selectedIds}
            showValidationReport={showValidationReport}
            setShowValidationReport={setShowValidationReport}
          />

          {/*SiteList Filter*/}
          <Popup
            width={"50%"}
            height="auto"
            visible={showFilter}
            resizeEnabled={true}
            showTitle={true}
            title={t("filter")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={onCloseFilterModal}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2}>
                <SimpleItem colSpan={2}>
                  <TagBox
                    label={t("siteList")}
                    labelMode="floating"
                    className="modalInput"
                    dataSource={SortObjectByPropName(initDataSiteList, "name")}
                    valueExpr="id"
                    displayExpr="name"
                    onValueChange={(e) => onValueChanged(e)}
                  />
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onApplySiteListFilter}
                    text={t("apply")}
                  />
                </div>
              </div>
            </ScrollView>
          </Popup>

          {/* Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            // maxHeight={"90%"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.Collision}
              dataGridRef={dataGridRef}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="LocationId"
            />
          </Popup>

          {/* Report Popup */}
          <Popup
            visible={showReportModal}
            width={"auto"}
            height={reportPopupHeight}
            resizeEnabled={true}
            showTitle={true}
            title={t("reportViewer")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseReportModal()}
          >
            <TesReportViewer
              selectedReport={selectedReport}
              lstSelectedIds={selectedIds}
              isOpen={showReportModal}
            />
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default SimplifiedCollisions;
