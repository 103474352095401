import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./gisMap.scss";
import { MapSetting } from "../../../../../../types/infrastructure/infrastructureTypes";
import { TesMapModule } from "../../../../../../types/general/enums/generalEnums";
import {
  ToMapRequestCollisionDTO,
  ToMapResponseCollisionDTO,
} from "../../../../../../types/collision/dto/collisionDtos";
import {
  ToMapRequestTrafficDTO,
  ToMapResponseTrafficDTO,
} from "../../../../../../types/trafficStudy/dtos/studyDto";
import { FromMapRequestDTO } from "../../../../../../types/infrastructure/dto/locationdDto";
import { useInitializeMap } from "../../../../../../components/gisMap/v3/hooks/useInitializeMap";
import { useIntersectionLayer } from "../../../../../../components/gisMap/v3/hooks/useIntersectionsLayer";
import { useRoadSegmentLayer } from "../../../../../../components/gisMap/v3/hooks/useRoadSegmentLayer";
import {
  addClickOnMapTrigger,
  adjustViewZooming,
  onSelectedFeature_v1,
  openInGoogleMap,
  openStreetView,
} from "../../../../../../components/gisMap/v3/utils";
import { StudyType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { useTrafficIntersectionAADTsLayer } from "../../../../../../components/gisMap/v3/hookLayers/useTrafficIntersectionAADTsLayer";
import { useTrafficRoadSegmentAADTLayer } from "../../../../../../components/gisMap/v3/hookLayers/useTrafficRoadSegmentAADTLayer";
import { useTrafficComplianceLayer } from "../../../../../../components/gisMap/v3/hookLayers/useTrafficComplianceLayer";
import { useTrafficPostedSpeedLayer } from "../../../../../../components/gisMap/v3/hookLayers/useTrafficPostedSpeedLayer";
import { useTrafficAverageSpeedLayer } from "../../../../../../components/gisMap/v3/hookLayers/useTrafficAverageSpeedLayer";
import { useLatestSpeedStudyLayer } from "../../../../../../components/gisMap/v3/hookLayers/useLatestSpeedStudyLayer";
import { useLatestVolumeStudyLayer } from "../../../../../../components/gisMap/v3/hookLayers/useLatestVolumeStudyLayer";
import { useLatestTMCStudyLayer } from "../../../../../../components/gisMap/v3/hookLayers/useLatestTMCStudyLayer";
import { useCollisionBaseOnCoordinateLayer } from "../../../../../../components/gisMap/v3/hookLayers/useCollisionBaseOnCoordinateLayer";
import { useCollisionBaseOnLocationLayer } from "../../../../../../components/gisMap/v3/hookLayers/useCollisionBaseOnLocationLayer";
import { useCustomLayers } from "../../../../../../components/gisMap/v3/hooks/useCustomLayers";
import { LocationType } from "../../../../../../types/infrastructure/enums/infrastructureEnums";
import Graphic from "@arcgis/core/Graphic";
import { registerPopupActionFunctions } from "../../../../../../components/gisMap/v3/utils/popupsActions";

type TProps = {
  initDataGis: MapSetting | undefined;
  collisionToMapData: ToMapResponseCollisionDTO | null;
  collisionToMapReq: ToMapRequestCollisionDTO;
  trafficToMapData: ToMapResponseTrafficDTO | null;
  trafficToMapReq: ToMapRequestTrafficDTO;
  go2Intersection: (geoId: string) => void;
  go2RoadSegment: (geoId: string) => void;
  getLocationId: (inData: FromMapRequestDTO) => void;
  mapTest: boolean;
  clickedOnMap: () => void;
  shouldReCreateLayers: boolean;
};
export default function GisMap_v3({
  initDataGis,
  collisionToMapData,
  collisionToMapReq,
  trafficToMapData,
  trafficToMapReq,
  go2Intersection,
  go2RoadSegment,
  getLocationId,
  mapTest,
  clickedOnMap,
  shouldReCreateLayers,
}: TProps) {
  const tesModule = TesMapModule.Infrastructure;

  const { t } = useTranslation();

  const mapEl = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLayersLoading, setIsLayersLoading] = useState(false);

  const {
    view,
    map,
    legend,
    legendExpand,
    isInitialLoading,
    measurementWidget,
  } = useInitializeMap({
    mapEl,
    initDataGis,
    isMovingMapEnabled: false,
    hasLegend: true,
    hasLayerListWidget: true,
    hasPrintWidget: true,
    zoomBehavior: {
      isDefault: false,
      zoomOptions: {
        disableMouseWheel: true,
        disableDoubleClickZoom: true,
        disableDoubleClickWithControlPressed: false,
        disableKeyboardZoom: true,
        disablePopupZoom: true,
      },
    },
  });
  const { intersectionLayer, intersectionLayerId, isIntersectionLoading } =
    useIntersectionLayer({
      view,
      map,
      mapEl,
      initDataGis,
      tesModule,
    });
  const { roadSegmentLayer, roadSegmentLayerId, isRoadSegmentLoading } =
    useRoadSegmentLayer({
      view,
      map,
      mapEl,
      initDataGis,
      tesModule,
    });

  const { isCustomLayerLoading } = useCustomLayers({ map, mapEl, initDataGis });

  useEffect(() => {
    if (
      view &&
      !isInitialLoading &&
      !isIntersectionLoading &&
      !isRoadSegmentLoading &&
      !isCustomLayerLoading &&
      roadSegmentLayer &&
      intersectionLayer
    ) {
      adjustViewZooming({
        expand: 1.1,
        view,
        layers: [intersectionLayer, roadSegmentLayer],
        finishedZoomingCallback: () => {
          setIsLoading(false);
        },
      });
    }
  }, [
    view,
    isInitialLoading,
    isIntersectionLoading,
    isRoadSegmentLoading,
    isCustomLayerLoading,
    roadSegmentLayer,
    intersectionLayer,
  ]);

  const preparedDataForLayer = useMemo(() => {
    return {
      roadSegmentAADT: trafficToMapData
        ? trafficToMapData.trafficLocations.filter(
            (x) => x.roadSegmentAADT != 0
          )
        : [],
      intersectionAADT: trafficToMapData
        ? trafficToMapData.trafficLocations.filter(
            (x) => x.intersectionAADT != 0
          )
        : [],
      compliance: trafficToMapData
        ? trafficToMapData.trafficLocations.filter((x) => x.compliance != 0)
        : [],
      averageSpeed: trafficToMapData ? trafficToMapData.trafficLocations : [],
      postedSpeed: trafficToMapData ? trafficToMapData.trafficLocations : [],
      latestSpeedStudy: trafficToMapData?.studies
        ? trafficToMapData.studies.filter(
            (x) => x.studyType.toString() === StudyType.Speed.toString()
          )
        : [],
      latestVolumeStudy: trafficToMapData?.studies
        ? trafficToMapData.studies.filter(
            (x) => x.studyType.toString() === StudyType.Volume.toString()
          )
        : [],
      latestTMCStudy: trafficToMapData?.studies
        ? trafficToMapData.studies.filter(
            (x) => x.studyType.toString() === StudyType.TMC.toString()
          )
        : [],
      clusterData: collisionToMapData ? collisionToMapData.geoJsonData : null,
      collisionLocations: collisionToMapData
        ? collisionToMapData.collisionLocations
        : [],
    };
  }, [collisionToMapData, trafficToMapData]);
  const showLayerData = useMemo(() => {
    return {
      roadSegmentAADT: trafficToMapReq.showRoadSegmentAADT,
      intersectionAADT: trafficToMapReq.showIntersectionAADT,
      compliance: trafficToMapReq.showCompliance,
      averageSpeed: trafficToMapReq.showAverageSpeed,
      postedSpeed: trafficToMapReq.showPostedSpeed,
      latestSpeedStudy: trafficToMapReq.showLatestSpeedStudy,
      latestVolumeStudy: trafficToMapReq.showLatestVolumeStudy,
      latestTMCStudy: trafficToMapReq.showLatestTMCStudy,
      clusterData: collisionToMapReq.showClusterData,
      collisionLocations: collisionToMapReq.showCollisionLocations,
    };
  }, [collisionToMapReq, trafficToMapReq]);

  const { isTrafficIntersectionAADTsLayerLoading } =
    useTrafficIntersectionAADTsLayer({
      showLayer: showLayerData.intersectionAADT,
      legend,
      legendExpand,
      map,
      view,
      initDataGis,
      reCreateLayerEvenItExists: shouldReCreateLayers,
      lstStudyLocations: preparedDataForLayer.intersectionAADT,
      intersectionLayer,
    });
  const { isTrafficRoadSegmentAADTLayerLoading } =
    useTrafficRoadSegmentAADTLayer({
      showLayer: showLayerData.roadSegmentAADT,
      legend,
      legendExpand,
      map,
      view,
      initDataGis,
      reCreateLayerEvenItExists: shouldReCreateLayers,
      lstStudyLocations: preparedDataForLayer.roadSegmentAADT,
      roadSegmentLayer,
    });
  const { isTrafficComplianceLayerLoading } = useTrafficComplianceLayer({
    showLayer: showLayerData.compliance,
    legend,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.compliance,
    roadSegmentLayer,
  });
  const { isTrafficPostedSpeedLayerLoading } = useTrafficPostedSpeedLayer({
    showLayer: showLayerData.postedSpeed,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.postedSpeed,
    roadSegmentLayer,
  });
  const { isTrafficAverageSpeedLayerLoading } = useTrafficAverageSpeedLayer({
    showLayer: showLayerData.averageSpeed,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.averageSpeed,
    roadSegmentLayer,
  });
  const { isTrafficSpeedStudyLayerLoading } = useLatestSpeedStudyLayer({
    showLayer: showLayerData.latestSpeedStudy,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.latestSpeedStudy,
    roadSegmentLayer,
  });
  const { isLatestVolumeStudyLayerLoading } = useLatestVolumeStudyLayer({
    showLayer: showLayerData.latestVolumeStudy,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.latestVolumeStudy,
    roadSegmentLayer,
  });
  const { isLatestTMCStudyLayerLoading } = useLatestTMCStudyLayer({
    showLayer: showLayerData.latestTMCStudy,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.latestTMCStudy,
    intersectionLayer,
  });
  const { isCollisionBaseOnCoordinateLayerLoading } =
    useCollisionBaseOnCoordinateLayer({
      showLayer: showLayerData.clusterData,
      legendExpand,
      map,
      view,
      initDataGis,
      reCreateLayerEvenItExists: shouldReCreateLayers,
      collisionGeoJsonData: preparedDataForLayer.clusterData,
    });
  const { isCollisionBaseOnLocationLayerLoading } =
    useCollisionBaseOnLocationLayer({
      showLayer: showLayerData.collisionLocations,
      legendExpand,
      map,
      view,
      initDataGis,
      reCreateLayerEvenItExists: shouldReCreateLayers,
      lstCollisionLocations: preparedDataForLayer.collisionLocations,
      roadSegmentLayer,
      intersectionLayer,
    });

  useEffect(() => {
    setIsLayersLoading(true);
    if (
      !isTrafficRoadSegmentAADTLayerLoading &&
      !isTrafficIntersectionAADTsLayerLoading &&
      !isTrafficComplianceLayerLoading &&
      !isTrafficPostedSpeedLayerLoading &&
      !isTrafficAverageSpeedLayerLoading &&
      !isTrafficSpeedStudyLayerLoading &&
      !isLatestVolumeStudyLayerLoading &&
      !isLatestTMCStudyLayerLoading &&
      !isCollisionBaseOnCoordinateLayerLoading &&
      !isCollisionBaseOnLocationLayerLoading
    ) {
      setIsLayersLoading(false);
    }
  }, [
    isTrafficRoadSegmentAADTLayerLoading,
    isTrafficIntersectionAADTsLayerLoading,
    isTrafficComplianceLayerLoading,
    isTrafficPostedSpeedLayerLoading,
    isTrafficAverageSpeedLayerLoading,
    isTrafficSpeedStudyLayerLoading,
    isLatestVolumeStudyLayerLoading,
    isLatestTMCStudyLayerLoading,
    isCollisionBaseOnCoordinateLayerLoading,
    isCollisionBaseOnLocationLayerLoading,
  ]);

  const onSelectedFeatureCallback = (
    graphic: Graphic,
    roadSegmentLayerId: string,
    intersectionLayerId: string,
    initDataGis: MapSetting
  ) => {
    if (
      graphic.layer?.id.toString().toLowerCase().trim() ===
      roadSegmentLayerId.toString().toLowerCase().trim()
    ) {
      getLocationId({
        customerId: localStorage.getItem("selectedCustomerId") || "",
        locationType: LocationType.Midblock,
        geoId: graphic.attributes[initDataGis.midblockGeoIdName],
      });
    }
    if (
      graphic.layer?.id.toString().toLowerCase().trim() ===
      intersectionLayerId.toString().toLowerCase().trim()
    ) {
      getLocationId({
        customerId: localStorage.getItem("selectedCustomerId") || "",
        locationType: LocationType.Intersection,
        geoId: graphic.attributes[initDataGis.intersectionGeoIdName],
      });
    }
  };
  useEffect(() => {
    if (view && initDataGis && (roadSegmentLayerId || intersectionLayerId)) {
      onSelectedFeature_v1(view, (graphic) =>
        onSelectedFeatureCallback(
          graphic,
          roadSegmentLayerId,
          intersectionLayerId,
          initDataGis
        )
      );
    }
  }, [view, initDataGis, roadSegmentLayerId, intersectionLayerId]);
  useEffect(() => {
    if (
      view &&
      intersectionLayer &&
      roadSegmentLayer &&
      initDataGis &&
      !isIntersectionLoading &&
      !isRoadSegmentLoading &&
      !isInitialLoading
    ) {
      addClickOnMapTrigger(view, clickedOnMap);

      registerPopupActionFunctions(
        {
          view,
          initDataGis,
          intersectionLayer,
          roadSegmentLayer,
        },
        {
          "street-view": {
            openStreetView: openStreetView,
          },
          "open-google-maps": {
            openInGoogleMap: openInGoogleMap,
          },
          "go-to-intersection": {
            go2Intersection: go2Intersection,
          },
          "go-to-roadSegment": {
            go2RoadSegment: go2RoadSegment,
          },
        }
      );
    }
  }, [
    view,
    intersectionLayer,
    roadSegmentLayer,
    initDataGis,
    isIntersectionLoading,
    isRoadSegmentLoading,
    isInitialLoading,
  ]);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {isLoading && <div className={"mapLoadingContainer"}>{t("loading")}</div>}
      {isLayersLoading && (
        <div className={"mapLoadingRowContainer_fullHeight"}>
          {t("loading")}
        </div>
      )}
      <div
        ref={mapEl}
        onMouseOver={() => {
          view && view.focus();
        }}
        className={"full-Height"}
      >
        <div
          id="distance"
          className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
          style={{ marginTop: "6.1rem", marginRight: "0.9rem" }}
          title="Distance Measurement Tool"
          onClick={() => {
            if (measurementWidget) measurementWidget.activeTool = "distance";
          }}
        >
          <span className="esri-icon-measure-line"></span>
        </div>

        <div
          id="clear-distance"
          className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
          style={{ marginTop: "8.15rem", marginRight: "0.9rem" }}
          title="Clear Measurement Tool"
          onClick={() => {
            measurementWidget?.clear();
          }}
        >
          <span className="esri-icon-trash"></span>
        </div>
        <div id="info" className="esri-widget"></div>
      </div>
    </div>
  );
}
