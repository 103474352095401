import {
  FieldType,
  SimplifiedFieldCategory,
  SimplifiedTesField,
} from "../../../../types/field/enums/fieldEnums";

const data = [
  {
    name: "AccNumber",
    fieldType: FieldType.String,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.AccNumber,
  },
  {
    name: "AccidentDate",
    fieldType: FieldType.DateTime,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.AccidentDate,
  },
  {
    name: "HasFatal",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.HasFatal,
  },
  {
    name: "IsHeavyVehicleInvolved",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsHeavyVehicleInvolved,
  },
  {
    name: "IsIntersection",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsIntersection,
  },
  {
    name: "HasInjury",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.HasInjury,
  },
  {
    name: "IsPedestrianInvolved",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsPedestrianInvolved,
  },
  {
    name: "IsCyclistInvolved",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsCyclistInvolved,
  },
  {
    name: "IsYoungDriver",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsYoungDriver,
  },
  {
    name: "IsOldDriver",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsOldDriver,
  },
  {
    name: "IsAggressiveDriver",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsAggressiveDriver,
  },
  {
    name: "IsDistractedDriver",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsDistractedDriver,
  },
  {
    name: "IsImpairedDriverInvolved",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsImpairedDriverInvolved,
  },
  {
    name: "InjuryLevel",
    fieldType: FieldType.TesEnum,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.InjuryLevel,
    values: [
      { name: "Level1(Highest)", value: 1 },
      { name: "Level2", value: 2 },
      { name: "Level3(Lowest)", value: 3 },
    ],
  },

  {
    name: "GeoCodeNotRequired",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.GeoCodeNotRequired,
  },

  {
    name: "IsMotorcycleInvolved",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsMotorcycleInvolved,
  },

  {
    name: "Index",
    fieldType: FieldType.Number,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedVehicle,
    simplifiedTesField: SimplifiedTesField.Index,
  },
  {
    name: "InitialDirectionOfTravel",
    fieldType: FieldType.List,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedVehicle,
    simplifiedTesField: SimplifiedTesField.InitialDirectionOfTravel,
  },
  {
    name: "Maneuver",
    fieldType: FieldType.List,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedVehicle,
    simplifiedTesField: SimplifiedTesField.Maneuver,
  },

  {
    name: "InitialImpactType",
    fieldType: FieldType.List,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.InitialImpactType,
  },
  {
    name: "LightingCondition",
    fieldType: FieldType.List,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.LightingCondition,
  },
  {
    name: "LocationDescription",
    fieldType: FieldType.String,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.LocationDescription,
  },
  {
    name: "PoliceFormAddress",
    fieldType: FieldType.String,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.PoliceFormAddress,
  },

  {
    name: "RoadName",
    fieldType: FieldType.String,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedRoad,
    simplifiedTesField: SimplifiedTesField.RoadName,
  },
  {
    name: "RoadSurfaceCondition",
    fieldType: FieldType.List,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.RoadSurfaceCondition,
  },

  {
    name: "PersonAge",
    fieldType: FieldType.Number,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedPerson,
    simplifiedTesField: SimplifiedTesField.PersonAge,
  },
  {
    name: "PersonBirthday",
    fieldType: FieldType.DateTime,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedPerson,
    simplifiedTesField: SimplifiedTesField.PersonBirthday,
  },
  {
    name: "IsPrivateProperty",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsPrivateProperty,
  },
  {
    name: "IsNonReportable",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.IsNonReportable,
  },

  {
    name: "InitialImpactTypeClient",
    fieldType: FieldType.List,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.InitialImpactTypeClient,
  },
  {
    name: "TES",
    fieldType: FieldType.TesEnum,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedCollision,
    simplifiedTesField: SimplifiedTesField.LocationDescription,
    values: [
      { name: "tes1", value: 1 },
      { name: "tes2", value: 2 },
    ],
  },
];

export default data;
