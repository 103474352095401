import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { ErrorMessage, RequestResponseResult } from '../types/general/generalTypes';
import { MessageType, ResponseCode } from '../types/general/enums/generalEnums';
import { RemoveData4LogOut } from './authTools';
import i18n from "../react-i18next";
i18n.loadNamespaces("sidebar");



async function TesGet(url: string, isAuth: boolean, shouldHandleError: boolean = true) {
    try {
        let hed: any = {
            "Content-Type": "application/json",
            'X-CSRF': '1'

        }
        // if (isAuth) {
        //     hed = {
        //         ...hed,
        //        // 'Authorization': `Bearer ${localStorage.getItem("tesToken")}`,
        //     }
        // }
        const response = await axios.get(url, {
            headers: hed
        });
        const res = await response;
        if (res.status === 200) {
            return res.data
        }
    }
    catch (ex: any) {
        if (ex.response.status === 401) {
            RemoveData4LogOut()
        }
        if (shouldHandleError) {
            notify(url + " : " + ex, "error", 5000);
        }
        //throw ex;
    }
}

async function TesPost(url: string, body: any, isAuth: boolean) {
    try {
        let hed: any = {
            "Content-Type": "application/json",
            'X-CSRF': '1'

        }
        if (isAuth) {
            hed = {
                ...hed,
                //  'Authorization': `Bearer ${localStorage.getItem("tesToken")}`
            }
        }


        const response = await axios.post(url, body, {
            headers: hed
        });
        const res = await response;
        if (res.status === 200) {
            return res.data
        }
    }
    catch (ex: any) {
        if (ex.response.status === 401) {
            RemoveData4LogOut()
        }
        notify(ex, "error", 5000);
        throw ex;
    }
}

async function TesPostReport(url: string, body: any, isAuth: boolean) {
    try {
        let hed: any = {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            'X-CSRF': '1'

        }
        if (isAuth) {
            hed = {
                ...hed,
                //  'Authorization': `Bearer ${localStorage.getItem("tesToken")}`
            }
        }


        const response = await axios.post(url, body, {
            headers: hed,
            responseType: 'blob'
        });
        const res = await response;
        if (res.status === 200) {
            return res.data
        }
    }
    catch (ex: any) {
        if (ex.response.status === 401) {
            RemoveData4LogOut()
        }
        notify(ex, "error", 5000);
        throw ex;
    }
}

async function TesPut(url: string, body: any, isAuth: boolean) {

    try {
        const response = await axios.put(url, body, {
            headers: {
                "Content-Type": "application/json",
                'X-CSRF': '1'
            }
        });
        const res = await response;
        if (res.status === 200) {
            return res.data
        }
    }
    catch (ex: any) {
        if (ex.response.status === 401) {
            RemoveData4LogOut()
        }
        notify(ex, "error", 5000);
        throw ex;
    }
}

async function TesPutUploadFile(url: string, body: any, isAuth: boolean) {

    try {
        const response = await axios.put(url, body, {
            headers: {
                "Content-Type": "application/json",
                'X-CSRF': '1'
            }
        });
        const res = await response;
        if (res.status === 200) {
            return res.data
        }
    }
    catch (ex: any) {
        if (ex.response.status === 401) {
            RemoveData4LogOut()
        }
        notify(ex, "error", 5000);
        throw ex;
    }
}
async function TesPostUploadFile(url: string, body: any) {

    try {
        const response = await axios.post(url, body, {
            headers: {
                "Content-Type": "application/json",
                'X-CSRF': '1'
            }
        });
        const res = await response;
        if (res.status === 200) {
            return res.data
        }
    }
    catch (ex: any) {
        if (ex.response.status === 401) {
            RemoveData4LogOut()
        }
        notify(ex, "error", 5000);
        throw ex;
    }
}


async function TesDelete(url: string, isAuth: boolean) {
    try {
        const response = await axios.delete(url, {
            headers: {
                "Content-Type": "application/json",
                'X-CSRF': '1'
            }
        });
        const res = await response;
        if (res.status === 200) {
            return res.data
        }
    }
    catch (ex: any) {
        if (ex.response.status === 401) {
            RemoveData4LogOut()
        }
        notify(ex, "error", 5000);
        throw ex;
    }
}

async function RequestErrorHandling<T>(req: RequestResponseResult<T>) {
    req.messages.forEach(msg => {
        switch (msg.type) {
            case MessageType.Error:
                notify(msg.messageCode + " : " + msg.message, "error", 5000);
                break;
            case MessageType.Warning:
                notify(msg.messageCode + " : " + msg.message, "warning", 5000);
                break;
            case MessageType.Information:
                notify(msg.messageCode + " : " + msg.message, "info", 5000);
                break;
            default:
                break;
        }
    });

    switch (req.responseCode) {
        case ResponseCode.GenericError:
            notify(i18n.t("rest-genericError"), "error", 5000);
            break;
        case ResponseCode.NotFound:
            notify(i18n.t("rest-notFound"), "error", 5000);
            break;
        case ResponseCode.CreatingError:
            notify(i18n.t("rest-creatingError"), "error", 5000);
            break;
        case ResponseCode.DeletingError:
            notify(i18n.t("rest-deletingError"), "error", 5000);
            break;
        case ResponseCode.UpdatingError:
            notify(i18n.t("rest-updatingError"), "error", 5000);
            break;
        case ResponseCode.GetError:
            notify(i18n.t("rest-getError"), "error", 5000);
            break;
        case ResponseCode.CustomerIdIsNotEqual:
            notify(i18n.t("rest-customerIdIsNotEqual"), "error", 5000);
            break;
        case ResponseCode.IdNotExists:
            notify(i18n.t("rest-idNotExists"), "error", 5000);
            break;
        case ResponseCode.InputIsInvalid:
            notify(i18n.t("rest-inputIsInvalid"), "error", 5000);
            break;
        case ResponseCode.CalculationError:
            notify(i18n.t("rest-calculationError"), "error", 5000);
            break;
        case ResponseCode.DateHasOverlap:
            notify(i18n.t("rest-dateHasOverlap"), "error", 5000);
            break;
        case ResponseCode.TokenExpired:
            notify(i18n.t("rest-tokenExpired"), "error", 5000);
            break;
        case ResponseCode.UserNotExists:
            notify(i18n.t("rest-userNotExists"), "error", 5000);
            break;
        case ResponseCode.TwoFactorIsNotValid:
            notify(i18n.t("rest-twoFactorIsNotValid"), "error", 5000);
            break;
        case ResponseCode.UsernamePasswordNotValid:
            notify(i18n.t("rest-usernamePasswordNotValid"), "error", 5000);
            break;
        case ResponseCode.LoginBlocked:
            notify(i18n.t("rest-loginBlocked"), "error", 5000);
            break;
        case ResponseCode.PasswordIsNotComplex:
            notify(i18n.t("rest-passwordIsNotComplex"), "error", 5000);
            break;
        case ResponseCode.CaptchaIsNotValid:
            notify(i18n.t("rest-captchaIsNotValid"), "error", 5000);
            break;
        case ResponseCode.CustomerNotPermitted:
            notify(i18n.t("rest-customerNotPermitted"), "error", 5000);
            break;
        case ResponseCode.DivisionNotPermitted:
            notify(i18n.t("rest-divisionNotPermitted"), "error", 5000);
            break;
        case ResponseCode.UserNotPermitted:
            notify(i18n.t("rest-userNotPermitted"), "error", 5000);
            break;
        case ResponseCode.NotPermitted:
            notify(i18n.t("rest-notPermitted"), "error", 5000);
            break;
        case ResponseCode.UserNameExist:
            notify(i18n.t("rest-userNameExist"), "error", 5000);
            break;
        case ResponseCode.EmailExist:
            notify(i18n.t("rest-emailExist"), "error", 5000);
            break;
        case ResponseCode.RecalculationDivisionNotPermitted:
            notify(i18n.t("rest-recalculationDivisionNotPermitted"), "error", 5000);
            break;
        case ResponseCode.InfrastructureNotExists:
            notify(i18n.t("rest-infrastructureNotExists"), "error", 5000);
            break;
        case ResponseCode.CollisionNotExists:
            notify(i18n.t("rest-collisionNotExists"), "error", 5000);
            break;
        case ResponseCode.CollisionValidationError:
            notify(i18n.t("rest-collisionValidationError"), "error", 5000);
            break;
        case ResponseCode.Collision4GeoCodeNotExists:
            notify(i18n.t("rest-collision4GeoCodeNotExists"), "error", 5000);
            break;
        case ResponseCode.WorkOrderExist:
            notify(i18n.t("rest-workOrderExist"), "error", 5000);
            break;
        case ResponseCode.RawDataNotExist:
            notify(i18n.t("rest-rawDataNotExist"), "error", 5000);
            break;
        case ResponseCode.AADTFactorsNotExist:
            notify(i18n.t("rest-aadtFactorsNotExist"), "error", 5000);
            break;
        case ResponseCode.AADTSourceIsWrong:
            notify(i18n.t("rest-aadtSourceIsWrong"), "error", 5000);
            break;
        case ResponseCode.AADTNotExistForLocation:
            notify(i18n.t("rest-aadtNotExistForLocation"), "error", 5000);
            break;
        case ResponseCode.SignalWarrantSettingsNotExist:
            notify(i18n.t("rest-signalWarrantSettingsNotExist"), "error", 5000);
            break;
        case ResponseCode.StopWarrantSettingsNotExist:
            notify(i18n.t("rest-stopWarrantSettingsNotExist"), "error", 5000);
            break;
        case ResponseCode.SpfError:
            notify(i18n.t("rest-spfError"), "error", 5000);
            break;
        case ResponseCode.SiteSubTypeNotAssign2Location:
            notify(i18n.t("rest-siteSubTypeNotAssign2Location"), "error", 5000);
            break;
        case ResponseCode.CollisionNotObservedInSiteSubtype:
            notify(i18n.t("rest-collisionNotObservedInSiteSubtype"), "error", 5000);
            break;
        case ResponseCode.SeverityWeightNotExist:
            notify(i18n.t("rest-severityWeightNotExist"), "error", 5000);
            break;
        case ResponseCode.AADTCollisionNotExist:
            notify(i18n.t("rest-aadtCollisionNotExist"), "error", 5000);
            break;
        default:
            break;
    }
}



export { TesGet, TesPost, TesPut, TesDelete, RequestErrorHandling, TesPutUploadFile, TesPostUploadFile, TesPostReport }