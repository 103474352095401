import i18n from "../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: i18n.t("siteList"),
    icon: "fa-solid fa-location-dot",
    content: "Details tab content",
  },
  {
    id: 1,
    text: i18n.t("analysis"),
    icon: "fa-solid fa-magnifying-glass",
    content: "Work Order tab content",
  },

];
export default tabTitles;