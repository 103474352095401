import i18n from "../../../../../react-i18next";


const speedTabTitles = [
    {
        id: 0,
        text: i18n.t('details'),
        icon: 'fa-solid fa-ellipsis',
        content: 'Details tab content',
    },
    {
        id: 1,
        text: i18n.t('location'),
        icon: 'fa-solid fa-map',
        content: 'Location tab content',
    },
    {
        id: 2,
        text: i18n.t('workOrder'),
        icon: 'fa-solid fa-hashtag',
        content: 'Work Order tab content',
    },
    {
        id: 3,
        text: i18n.t('reportingPeriods'),
        icon: 'fa-solid fa-calendar-days',
        content: 'Reporting Periods tab content',
    },
    {
        id: 4,
        text: i18n.t('volumeRawData'),
        icon: 'fa-solid fa-square-poll-vertical',
        content: 'Volume Raw Data tab content',
    },

    {
        id: 4,
        text: i18n.t('speedRawData'),
        icon: 'fa-solid fa-square-poll-vertical',
        content: 'Speed Raw Data tab content',
    },

    {
        id: 10,
        text: i18n.t('resultSummary'),
        icon: 'fa-solid fa-hashtag',
        content: 'result tab content',
    },
    {
        id: 5,
        text: i18n.t('speedStatistics'),
        icon: 'fa-solid fa-clipboard-check',
        content: 'Speed Results Data tab content',
    },


    {
        id: 9,
        text: i18n.t('roadSegmentAADT'),
        icon: 'fa-solid fa-road',
        content: 'All-Way Stop Warrants tab content',
    },

    {
        id: 11,
        text: i18n.t('attachments'),
        icon: 'fa-solid fa-paperclip',
        content: 'attachments tab content',
    },
    {
        id: 12,
        text: i18n.t('validations'),
        icon: 'fa-solid fa-check-double',
        content: 'logs tab content',
    },
    {
        id: 13,
        text: i18n.t('relatedStudies'),
        icon: 'fa-solid fa-circle-nodes',
        content: 'logs tab content',
    },

];

const volumeTabTitles = [
    {
        id: 0,
        text: i18n.t('details'),
        icon: 'fa-solid fa-ellipsis',
        content: 'Details tab content',
    },
    {
        id: 1,
        text: i18n.t('location'),
        icon: 'fa-solid fa-map',
        content: 'Location tab content',
    },
    {
        id: 2,
        text: i18n.t('workOrder'),
        icon: 'fa-solid fa-hashtag',
        content: 'Work Order tab content',
    },
    {
        id: 3,
        text: i18n.t('reportingPeriods'),
        icon: 'fa-solid fa-calendar-days',
        content: 'Reporting Periods tab content',
    },
    {
        id: 4,
        text: i18n.t('volumeRawData'),
        icon: 'fa-solid fa-square-poll-vertical',
        content: 'Volume Raw Data tab content',
    },
    {
        id: 10,
        text: i18n.t('resultSummary'),
        icon: 'fa-solid fa-hashtag',
        content: 'result tab content',
    },
    {
        id: 9,
        text: i18n.t('roadSegmentAADT'),
        icon: 'fa-solid fa-road',
        content: 'All-Way Stop Warrants tab content',
    },
    {
        id: 11,
        text: i18n.t('attachments'),
        icon: 'fa-solid fa-paperclip',
        content: 'attachment tab content',
    },
    {
        id: 12,
        text: i18n.t('validations'),
        icon: 'fa-solid fa-check-double',
        content: 'logs tab content',
    },
    {
        id: 13,
        text: i18n.t('relatedStudies'),
        icon: 'fa-solid fa-circle-nodes',
        content: 'logs tab content',
    },

];


const tmcTabTitles = [
    {
        id: 0,
        text: i18n.t('details'),
        icon: 'fa-solid fa-ellipsis',
        content: 'Details tab content',
    },
    {
        id: 1,
        text: i18n.t('location'),
        icon: 'fa-solid fa-map',
        content: 'Location tab content',
    },
    {
        id: 2,
        text: i18n.t('workOrder'),
        icon: 'fa-solid fa-hashtag',
        content: 'Work Order tab content',
    },
    {
        id: 3,
        text: i18n.t('reportingPeriods'),
        icon: 'fa-solid fa-calendar-days',
        content: 'Reporting Periods tab content',
    },
    {
        id: 4,
        text: i18n.t('volumeRawData'),
        icon: 'fa-solid fa-square-poll-vertical',
        content: 'Volume Raw Data tab content',
    },
    {
        id: 10,
        text: i18n.t('resultSummary'),
        icon: 'fa-solid fa-hashtag',
        content: 'result tab content',
    },
    {
        id: 6,
        text: i18n.t('signalWarrants'),
        icon: 'fa-solid fa-traffic-light',
        content: 'Signal Warrants tab content',
    },
    {
        id: 7,
        text: i18n.t('allWayStopWarrant'),
        icon: 'fa-solid fa-arrows-to-circle',
        content: 'All-Way Stop Warrants tab content',
    },
    {
        id: 8,
        text: i18n.t('intersectionAADT'),
        icon: 'fa-solid fa-compress',
        content: 'All-Way Stop Warrants tab content',
    },

    {
        id: 11,
        text: i18n.t('attachments'),
        icon: 'fa-solid fa-paperclip',
        content: 'attachment tab content',
    },
    {
        id: 12,
        text: i18n.t('validations'),
        icon: 'fa-solid fa-check-double',
        content: 'logs tab content',
    },
    {
        id: 13,
        text: i18n.t('relatedStudies'),
        icon: 'fa-solid fa-circle-nodes',
        content: 'logs tab content',
    },

];


export { speedTabTitles, tmcTabTitles, volumeTabTitles };

