import { BrowserRouter } from "react-router-dom";
import LoadPanel from "devextreme-react/load-panel";

import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider, useAuth } from "./contexts/auth";
import { ClientSettingProvider } from "./contexts/clientSetting";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import NotAuthenticatedContent from "./NotAuthenticatedContent";

import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import "./dx-styles.scss";
import "./themes/ourStyles/ourStyle.scss";
import "./react-i18next";
import useClaims from "./apis/claims";
import deMessages from "devextreme/localization/messages/de.json";
import frMessages from "devextreme/localization/messages/fr.json";
import itMessages from "devextreme/localization/messages/it.json";
import { loadMessages } from "devextreme/localization";
import { TranslationsProvider } from "./contexts/translations";
import { useCallback } from "react";
import i18n from "./react-i18next";
import { GridSettingsProvider } from "./contexts/gridSettings";
import { InfrastructureProvider } from "./contexts/infrastructure";
function App() {
  const { user, loading } = useAuth();
  const { data: claims, isLoading } = useClaims();
  let nameDict =
    claims?.find((claim: any) => claim.type === "name") ||
    claims?.find((claim: any) => claim.type === "sub");
  let username = nameDict?.value;
  let logoutUrl = claims?.find((claim: any) => claim.type === "bff:logout_url");
  loadMessages(deMessages);
  loadMessages(frMessages);
  loadMessages(itMessages);

  if (isLoading || loading) return <LoadPanel visible={true} />;

  if (username && user) {
    localStorage.setItem("logoutUrl", logoutUrl.value);
    return <Content />;
  }

  if (!username) {
    return <NotAuthenticatedContent />;
  }

  return <div></div>;
}

export default function TesApp() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <BrowserRouter>
      <AuthProvider>
        <NavigationProvider>
          <TranslationsProvider>
            <GridSettingsProvider>
              <InfrastructureProvider>
                <ClientSettingProvider>
                  <div className={`app ${screenSizeClass}`}>
                    <App />
                  </div>
                </ClientSettingProvider>
              </InfrastructureProvider>
            </GridSettingsProvider>
          </TranslationsProvider>
        </NavigationProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
