import { Button, Tabs } from "devextreme-react";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import { TMSPermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { AuthApiUrl, TranslationApiUrl } from "../../../environment/routeSettings";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import { INameId, NameValue, RequestResponseResult } from "../../../types/general/generalTypes";
import { CustomerLanguageDto, GetTranslationDto, LanguageDetailsDto, LanguageDetailsDtoListRequestResponseResult, LanguageDetailsDtoRequestResponseResult } from "../../../types/translation/dtos/translationDtos";
import { LanguageType } from "../../../types/translation/enums/translationEnums";
import { ILanguageParams } from "../../../types/translation/traslationTypes";
import { RequestErrorHandling, TesGet, TesPost, TesPut } from "../../../utils/rest";
import Details from "./components/details/details";
import Tags from "./components/tags/tags";
import { addTabTitles, updateTabTitles } from "./data/index";

const CustomerLanguageDetails = () => {

  const params = useParams<ILanguageParams>();
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const [isLocked, setIsLocked] = useState<boolean>(true);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedLang, setSelectedLang] = useState<LanguageDetailsDto>({ ...new LanguageDetailsDto, languageType: LanguageType.Customized })
  const [translations, setTranslations] = useState<GetTranslationDto[]>([])
  const [customerList, setCustomersList] = useState<CustomerLanguageDto[]>([])
  const [groupsList, setGroupsList] = useState<NameValue[]>([])
  const [initDataClient, setInitDataClient] = useState<INameId[]>([]);
  const { activeLoading } = useAuth();
  const history = useNavigate();

  useEffect(() => {
    if (params.languageId === "AddNew") setIsLocked(false)
  })

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        await getLanguageList()
        await getInitDataClient();
        if (params.languageId !== "AddNew") {
          await getTranslations();
          await getInitData();
        }
        // setLstLanguageType(Enum2Array(LanguageType))
      } catch (ex) {
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    try {
      const res = await TesGet(
        TranslationApiUrl() + "/api/ManageLanguages/GetLanguage" + `?languageId=${params.languageId}&languageType=${LanguageType.Customized}`,
        true
      );
      if (res.responseCode === ResponseCode.OK) {
        setSelectedLang(res.results);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  }

  async function getLanguageList() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        TranslationApiUrl() + "/api/Languages/GetAllBaseLanguages" + `?languageType=${LanguageType.Groupe}`,
        true
      )) as RequestResponseResult<LanguageDetailsDto[]>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setGroupsList(res.results.map((languageDetails: LanguageDetailsDto) => {
          return {
            name: languageDetails.languageName,
            value: languageDetails.id
          };
        }));
      } else {
        await RequestErrorHandling(res);
      }
    }
    catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  async function getTranslations() {
    try {
      const res = await TesGet(
        TranslationApiUrl() + "/api/ManageTranslations/GetTranslationOfLanguage" + `?languageId=${params.languageId}&languageType=${LanguageType.Customized}`,
        true
      );
      if (res.responseCode === ResponseCode.OK) {
        setTranslations(res.results);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  }

  async function getCustomersIdList() {
    try {
      const res = await TesGet(
        TranslationApiUrl() + "/api/ManageLanguages/GetCustomerByLanguageId" + `?languageId=${params.languageId}&languageType=${LanguageType.Customized}`,
        true
      );
      if (res.responseCode === ResponseCode.OK) {
        setCustomersList(res.results);
        // setCustomersList([...res.results, "9e9c0adc-5e38-463c-a05b-08dac66a9453", "6d61a697-2b32-454d-01ac-08d9197ed66f"]);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  }

  async function getInitDataClient() {
    const res = await TesGet(AuthApiUrl() + "/api/CustomerXUsers/ClientsOfUser", true);
    setInitDataClient(res);
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  async function onSave() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await (TesPost(TranslationApiUrl() + "/api/ManageCustomerLanguages/AddCustomLanguageToCustomer",
        {
          languageDto: { ...selectedLang, languageType: LanguageType.Customized },
          customerId: selectedLang.customerId
        }
        , true))) as LanguageDetailsDtoRequestResponseResult
      if (res.responseCode === ResponseCode.OK) {
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        history(-1)
      } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
        if (activeLoading) activeLoading(false);
        notify(t("customerNotPermitted"), "error", 5000);
      }
      if (activeLoading) activeLoading(false);
      //await RequestErrorHandling(res);;
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await (TesPut(TranslationApiUrl() + "/api/ManageLanguages/EditLanguage" + `?languageId=${params.languageId}&languageType=${LanguageType.Customized}`, { ...selectedLang, languageType: LanguageType.Customized }, true)))
      if (res.responseCode === ResponseCode.OK) {
        if (activeLoading) activeLoading(false);
        setDataChanged(false)
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
        if (activeLoading) activeLoading(false);
        notify(t("customerNotPermitted"), "error", 5000);
      }
      if (activeLoading) activeLoading(false);
      await RequestErrorHandling(res);;
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.languageId !== "AddNew") {
                  onUpdate();
                  setDataChanged(false)
                } else {
                  onSave();
                  setDataChanged(false)
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              // setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  return (
    <Permission
      allowed={[TMSPermissions.TMS_Admin, TMSPermissions.TMS_D_Translation]}
      hasFeedBackElement={true}
    >
      {/* <div className={`safety-workbookDetails ${compactViewModel ? "compactStyle" : ""}`}> */}
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card"}>
            {params.languageId !== "AddNew" && (
              <div style={{ marginTop: "1rem" }}>
                <p ref={headingParagraphRef} className={"detailsHeading"} style={{ display: "inline" }}>
                  <span>{t("customerLanguage")}: {selectedLang?.languageName!}</span>
                </p>
                <Button
                  onClick={() => copyTextToClipboard()}
                  icon="fa-solid fa-copy"
                  hint={t("copy")}
                />
                <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
              </div>
            )}
            <div className="row" style={{ marginTop: 15 }}>
              <div className="leftColumn">
                <Button
                  onClick={() => goBackPermission()}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <div className="rightColumn">
                {params.languageId === "AddNew" && (
                  <Button
                    onClick={() => onSave()}
                    style={{ marginRight: '10px' }}
                    icon="fa-solid fa-floppy-disk"
                    hint={t("save")}
                  />
                )}
                {params.languageId !== "AddNew" && isLocked === false && (
                  <Button
                    onClick={() => onUpdate()}
                    icon="fa-solid fa-floppy-disk"
                    style={{ marginRight: 10 }}
                    hint={t("update")}
                  />
                )}
                <Button
                  onClick={() => LockHandler()}
                  icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                  hint={isLocked ? t("unlock") : t("lock")}
                  visible={params.languageId !== "AddNew"}
                />
              </div>
            </div>
          </div>
          <div className={"dx-card "}>
            <Tabs
              width={"100%"}
              dataSource={params.languageId === "AddNew" ? addTabTitles : updateTabTitles}
              selectedIndex={selectedIndex}
              onOptionChanged={onTabsSelectionChanged}
            />

            {selectedIndex === 0 &&
              <Details
                isLocked={isLocked}
                selectedLang={selectedLang}
                setSelectedLang={setSelectedLang}
                setDataChanged={setDataChanged}
                groupsList={groupsList}
                params={params}
                initDataClient={initDataClient}
              // selectedCustomerId={selectedCustomerId}
              // setSelectedCustomerId={setSelectedCustomerId}
              />
            }
            {selectedIndex === 1 &&
              <Tags
                isLocked={isLocked}
                translations={translations}
                getTranslations={getTranslations}
                setDataChanged={setDataChanged}
              />
            }
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
}

export default CustomerLanguageDetails