const TrafficStudyPermissions = {
  TrafficStudy_D_Setups: "TrafficStudy_D_Setups",
  TrafficStudy_V_MobileApp: "TrafficStudy_V_MobileApp",
  TrafficStudy_V_MobileAppReport: "TrafficStudy_V_MobileAppReport",
  TrafficStudy_D_Study: "TrafficStudy_D_Study",
  TrafficStudy_E_Study: "TrafficStudy_E_Study",
  TrafficStudy_V_Study: "TrafficStudy_V_Study",
  TrafficStudy_R_Study: "TrafficStudy_R_Study",
  TrafficStudy_V_MMS: "TrafficStudy_V_MMS",
  TrafficStudy_D_MMS: "TrafficStudy_D_MMS",
  TrafficStudy_E_MisAADT: "TrafficStudy_E_MisAADT",
  TrafficStudy_V_MisAADT: "TrafficStudy_V_MisAADT",
  TrafficStudy_E_TrafficSectionAADT: "TrafficStudy_E_TrafficSectionAADT",
  TrafficStudy_V_TrafficSectionAADT: "TrafficStudy_V_TrafficSectionAADT",
  TrafficStudy_E_AADT: "TrafficStudy_E_AADT",
  TrafficStudy_V_Matching: "TrafficStudy_V_Matching",
  TrafficStudy_E_Matching: "TrafficStudy_E_Matching",
  TrafficStudy_V_Trending: "TrafficStudy_V_Trending",
  TrafficStudy_E_Trending: "TrafficStudy_E_Trending",
  TrafficStudy_V_AadtPrediction: "TrafficStudy_V_AadtPrediction",
  TrafficStudy_E_AadtPrediction: "TrafficStudy_E_AadtPrediction"
};
export default TrafficStudyPermissions;
