import i18n from "../../../../../../react-i18next";
i18n.loadNamespaces("tabs");

const tabTitles = [
  {
    id: 0,
    text: i18n.t("diagram"),
    icon: "fa-solid fa-magnifying-glass-chart",
    content: i18n.t("diagram"),
  },



]

export default tabTitles;