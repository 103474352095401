import { CheckBox, Form, ScrollView, SelectBox, TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { DataGridRef } from 'devextreme-react/data-grid';
import { SimpleItem } from "devextreme-react/form";
import { Popup } from 'devextreme-react/popup';
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import InfrastructureLocationPicker from "../../../../components/infrastructureLocationPicker/infrastructureLocationPicker";
import { useAuth } from "../../../../contexts/auth";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import {ResponseCode, TesMapModule} from "../../../../types/general/enums/generalEnums";
import { NameValue, RequestResponseResult } from "../../../../types/general/generalTypes";
import { VMLocation } from "../../../../types/infrastructure/dto/locationdDto";
import { LocationPickerMode, LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import { ImportCheckResponse } from "../../../../types/trafficStudy/dtos/importDto";
import { StudyBaseDTO, WorkOrderBaseDTO } from "../../../../types/trafficStudy/dtos/studyDto";
import { AggregationInterval, StudyType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { Enum2Array } from '../../../../utils/enumTools';
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";

interface IProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<any>>;
    label: string;
    importResponse: ImportCheckResponse;
    updateWorkOrder: (fileKey: string, workOrderNo: string, studyId: string, binMapId: string, hasData: boolean) => void;
    onAdd?: () => void;
    step2GridRef: React.RefObject<DataGridRef<any, any>>;
}


const LocationPopup = (props: IProps) => {
    const [showWorkOrderPicker, setShowWorkOrderPicker] = useState(false);
    //const [showModalAdd, setShowModalAdd] = useState(false);
    const [lstAggregationInterval, setLstAggregationInterval] = useState<NameValue[]>([]);
    const [studyBase, setStudyBase] = useState<StudyBaseDTO>(new StudyBaseDTO());
    const [woAuto, setwoAuto] = useState<boolean>(true);
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const { activeLoading } = useAuth();

    useEffect(() => {
        async function fetchMyAPI() {
            try {

            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        if (showWorkOrderPicker) {
            fetchMyAPI()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showWorkOrderPicker]);

    useEffect(() => {
        if (showWorkOrderPicker) {
            filterByLocationType();
        }
    }, [showWorkOrderPicker]);


    async function getNewWorkOrderNo() {
        try {
            // if (activeLoading) activeLoading(true);
            var res = (await TesGet(`${TrafficApiUrl()}/api/Study/GetNewWorkOrderNo/${localStorage.getItem("selectedCustomerId")}/${props.importResponse.studyType}`, true)) as RequestResponseResult<number>;
            if (res.responseCode === ResponseCode.OK) {
                onValueChange("no", res.results)
                return res.results;
            } else {
                await RequestErrorHandling(res);
            }
            //  if (activeLoading) activeLoading(false);
        } catch (ex) {
            //  if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }
    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                //setLstSiteType(Enum2Array(SectionType));
                setLstAggregationInterval(Enum2Array(AggregationInterval));
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);





    function onCloseModalAdd() {
        props.setShowModal(false);
        props.step2GridRef.current?.instance().refresh();
    }

    async function onAddWorkOrder() {
        try {
            var postObj = {
                ...studyBase,
                customerId: localStorage.getItem('selectedCustomerId'),
                studyType: props.importResponse.studyType,
                workOrder: new WorkOrderBaseDTO()
            };
            if (activeLoading) activeLoading(true);
            var wo;
            if (woAuto) {

                wo = await getNewWorkOrderNo();
            } else {
                wo = studyBase.workOrder?.no!;
            }
            postObj.workOrder.no = wo as string;
            // console.log("POST OBJ " + JSON.stringify(postObj))
            var res: RequestResponseResult<StudyBaseDTO> = await TesPost(TrafficApiUrl() + "/api/Study/AddStudyFromImport", postObj, true);
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyAdded"), "success", 5000);
                setShowWorkOrderPicker(false);
                props.updateWorkOrder(props.importResponse.fileKey, wo as string, res.results.id!, props.importResponse.binMapId, false);
                onCloseModalAdd();
                if (props.onAdd) {
                    props.onAdd();
                }
                return;
            } else if (res.responseCode === ResponseCode.WorkOrderExist) {
                onCloseModalAdd();
                notify(t("workOrderExist"), "error", 5000);
            } else {
                onCloseModalAdd();
                await RequestErrorHandling(res);;
            }
        } catch (x) {
            console.log(x);
            if (activeLoading) activeLoading(false);
            onCloseModalAdd();
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }

    function onValueChange(name: string, value: any) {
        if (name === "no") {
            setStudyBase({ ...studyBase, workOrder: { ...studyBase.workOrder, [name]: value } })
        } else {
            setStudyBase({ ...studyBase, [name]: value })
        }
    }

    function setInfrastructureData(inData: VMLocation) {
        setStudyBase({
            ...studyBase, locationId: inData.id,
            locationDescription: inData.description,
            locationType: inData.locationType,
            geoId: inData.geoId,
            speedLimit: inData.postedSpeed,
            jurisdictionId: inData.jurisdictionId,
            municipalityId: inData.municipalityId,
            divisionId: inData.divisionId,
            sectionType: inData.sectionDetailType
        })
    }

    function filterByLocationType() {
        dataGridRef?.current?.instance().deselectAll();
        dataGridRef?.current?.instance().beginUpdate();
        dataGridRef?.current?.instance().columnOption("studyType", 'selectedFilterOperation', "equal");
        dataGridRef?.current?.instance().columnOption("studyType", 'filterValues', props.importResponse.studyType.toString());
        dataGridRef?.current?.instance().endUpdate();
    }

    return (
        <React.Fragment>
            {/* popup for adding new Work Order */}

            <Popup
                width={"50%"}
                height={"auto"}
                visible={props.showModal}
                resizeEnabled={true}
                showTitle={true}
                title={t("add")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onCloseModalAdd()}
            >
                <ScrollView width="100%" height="100%">
                    <Form colCount={2} className="popupFields">
                        <SimpleItem colSpan={1}>
                            <InfrastructureLocationPicker
                                didMount={false}
                                renderMap={false}
                                setInfrastructureData={setInfrastructureData}
                                componentMode={LocationPickerMode.TextBox}
                                renderCoordinate={false}
                                showIcons={true}
                                locationType={props.importResponse.studyType == StudyType.TMC ? LocationType.Intersection : LocationType.Midblock}
                                tesModule={TesMapModule.Infrastructure}
                            />
                        </SimpleItem>
                        <SimpleItem colSpan={1}>
                            <SelectBox
                                placeholder=""
                                label={t('aggregationInterval')}
                                valueExpr="value"
                                displayExpr="value"
                                labelMode='floating'
                                defaultValue={15}
                                onValueChange={(e) => onValueChange("aggregationInterval", e)}
                                className="modalInput"
                                items={SortObjectByPropName(lstAggregationInterval, "name")}
                                showClearButton={true}
                                searchEnabled={true}
                            />
                        </SimpleItem>
                        <SimpleItem colSpan={1}>
                            <CheckBox
                                value={woAuto}
                                text={t("GenerateWOAutomatically")}
                                onValueChanged={() => setwoAuto(!woAuto)}
                            />
                        </SimpleItem>

                        <SimpleItem colSpan={1} >
                            <TextBox
                                visible={!woAuto}
                                label={t('workOrder')}
                                labelMode='floating'
                                onValueChange={(e) => onValueChange("no", e)}
                                className="modalInput"
                            />
                        </SimpleItem>

                    </Form>
                    <div>
                        <div className="rightColumn">
                            <Button
                                className="tes-modal-btn-cancel"
                                style={{ marginRight: 20 }}
                                onClick={() => onCloseModalAdd()}
                                text={t("cancel")}
                            />
                            <Button
                                className="tes-modal-btn-add"
                                onClick={onAddWorkOrder}
                                text={t("add")}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </React.Fragment>
    );
}
export default LocationPopup;