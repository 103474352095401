//#region imports
import { Button, ContextMenu } from 'devextreme-react';
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Export,
    FilterBuilderPopup,
    FilterPanel,
    FilterRow,
    Grouping,
    GroupItem,
    GroupPanel,
    HeaderFilter,
    Lookup,
    Pager, Paging,
    Search,
    SearchPanel,
    Selection,
    SortByGroupSummaryInfo,
    StateStoring,
    Summary
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GeneralGisMapManager from '../../../../components/gisMap/generalGisMapManager';
import GridFilter from '../../../../components/gridFilter/gridFilter';
import GridProfilePicker from '../../../../components/gridProfilePicker/gridProfilePicker';
import Permission from '../../../../components/permission/permision';
import getTitle from '../../../../components/title/getTitle';
import { TrafficStudyPermissions } from '../../../../constants/Permissions';
import { useAuth } from '../../../../contexts/auth';
import { useClientSetting } from '../../../../contexts/clientSetting';
import { FieldApiUrl, TrafficApiUrl } from '../../../../environment/routeSettings';
import { GridType, ResponseCode, TesMapModule } from '../../../../types/general/enums/generalEnums';
import { GridSetting, LazyLoadingRequest, NameValue, RequestResponseResult } from '../../../../types/general/generalTypes';
import { TesCodeValue } from '../../../../types/infrastructure/infrastructureTypes';
import { ClientReport } from '../../../../types/report/reportTypes';
import { AADTSourceType, AADTType, CountCodeType, MatchingAlgorithmResultType, PCSPatternType, TrendingResultStatus } from '../../../../types/trafficStudy/enums/trafficStudyEnums';
import { SortObjectByPropName } from '../../../../utils/arrayTools';
import { OnExporting } from '../../../../utils/dataGridTools';
import { Enum2Array } from '../../../../utils/enumTools';
import { RequestErrorHandling, TesGet, TesPost } from '../../../../utils/rest';
import { RightClickMenu } from '../../../../utils/rightClickMenu';
import './trafficSectionAADTs.scss';
import { AlphaCodeType } from '../../../../types/trafficStudy/trafficStudyTypes';
//#endregion imports

const dataSource = new CustomStore({
    load: async (loadOption) => {
        return await TesPost(
            `${TrafficApiUrl()
            }/api/AADT/GetCustomerAADTLazyLoading`,
            {
                customerId: localStorage.getItem("selectedCustomerId"),
                divisionId: localStorage.getItem("selectedDivisionId"),
                loadOptions: loadOption,
                filter: { type: AADTType.TrafficSection }
            } as LazyLoadingRequest,
            true
        );
    },
});

const TrafficSectionAADTs = () => {
    //#region consts 
    const [showMap, setShowMap] = useState(false);
    const [initDataTesCodeValues, setInitDataTesCodeValues] = useState<TesCodeValue>(new TesCodeValue());
    const [showFilter, setShowFilter] = useState(false);
    const history = useNavigate();
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const { t } = useTranslation();
    const { activeLoading } = useAuth();
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const items = [
        { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
        { text: t('openLocation'), icon: "fa-solid fa-location-dot" }
    ];
    const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    const { customerDivisions } = useClientSetting();
    const lstPatternType = Enum2Array(PCSPatternType)
    const lstCountCode = Enum2Array(CountCodeType)
    const lstAlphaCodeType = Enum2Array(AlphaCodeType)
    const lstMatchingAlgorithmResultType = Enum2Array(MatchingAlgorithmResultType)
    const lstTrendingResultStatus = Enum2Array(TrendingResultStatus)


    //#endregion consts
    //#region functions

    const title = getTitle('/trafficStudy/aadts/trafficSectionAADTs', '');
    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInfrastructureTesCodeValues();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onRowClicked(e: any) {
        const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
        if (e.data.id !== undefined) {

            if (isCtrlKeyPressed) {
                window.open(`/trafficStudy/aadts/trafficSectionAADTDetails/${e.data.id}`, "_blank");
            } else {
                history(`/trafficStudy/aadts/trafficSectionAADTDetails/${e.data.id}`);
            }
        }
    }

    async function onRecalculate() {
        if (activeLoading) activeLoading(true);
        var res = await TesGet(TrafficApiUrl() + "/api/AADT/RecalculateAllAADTs/" + AADTType.TrafficSection + "/" + localStorage.getItem('selectedCustomerId'), true) as RequestResponseResult<any>;
        if (res.responseCode === ResponseCode.OK) {
            notify(t("dataSuccessfullyUpdated"), "success", 5000);
        } else {
            await RequestErrorHandling(res);
        }
        dataGridRef.current?.instance().refresh();
        if (activeLoading) activeLoading(false);
    }

    async function getInfrastructureTesCodeValues() {
        setInitDataTesCodeValues(await TesGet(FieldApiUrl() + "/api/codeValues/infrastructureTesCodeValues/" + localStorage.getItem('selectedCustomerId'), true));
    }

    //#endregion functions
    return (
        <Permission
            allowed={[
                TrafficStudyPermissions.TrafficStudy_E_TrafficSectionAADT,
                TrafficStudyPermissions.TrafficStudy_V_TrafficSectionAADT,
            ]}
            hasFeedBackElement={true}
        >
            <div className={compactViewModel ? "compactStyle" : ""}>
                <React.Fragment>
                    <ContextMenu
                        dataSource={items}
                        width={100}
                        target=".dx-data-row"
                        onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, `/trafficStudy/aadts/aadtDetails/${AADTType.TrafficSection}/`, selectedRowData.row.data.locationType, selectedRowData.row.data.locationId)}
                    />
                    <h2 className={'content-block'}>{t('trafficSectionAADTs')}</h2>
                    <div className={'content-block'}>
                        <div className={'dx-card responsive-paddings'}>
                            <div className="row">
                                <div className='leftColumn' style={{ display: "flex" }}>
                                    <Button onClick={() => setShowMap(true)}
                                        icon="fa-solid fa-map-location-dot"
                                    />
                                    <GridProfilePicker
                                        customerId={localStorage.getItem("selectedCustomerId")!}
                                        gridType={GridType.TrafficSectionAADTs}
                                        dataGridRef={dataGridRef}
                                        setGridSetting={setGridSetting}
                                    />
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        onClick={() => setShowFilter(true)}
                                        icon="fa-solid fa-filter"
                                        hint={t("filter")}
                                    />
                                    <Button
                                        onClick={onRecalculate}
                                        icon="fa-solid fa-calculator"
                                        hint={t('recalculate')}
                                    />
                                </div>
                            </div>
                            <DataGrid id="gridContainer"
                                ref={dataGridRef}
                                dataSource={dataSource}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                onRowClick={onRowClicked}
                                hoverStateEnabled={true}
                                remoteOperations={true}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                style={{ margin: "0 1rem" }}
                                onExporting={OnExporting}
                                onContextMenuPreparing={e => { setSelectedRowData(e) }}
                                columnAutoWidth={gridSetting.horizontalScrolling}
                            //onContentReady={e => {e.component.deselectAll()}}
                            //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
                            >
                                <Export enabled={true}
                                    allowExportSelectedData={true}
                                />
                                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                                <GroupPanel visible={true} /> {/* or "auto" */}
                                <FilterPanel visible={true} />
                                <FilterBuilderPopup position={'top'} />
                                <StateStoring enabled={true} type="localStorage" storageKey={GridType.TrafficSectionAADTs.toString()} savingTimeout={500} />
                                <SortByGroupSummaryInfo
                                    summaryItem="Total Count"
                                    sortOrder="desc"
                                />
                                <Summary>
                                    <GroupItem
                                        summaryType="count"
                                        alignByColumn
                                        name="Total Count"
                                    />
                                </Summary>
                                <Paging enabled={true} defaultPageSize={100} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[100, 200, 300, 400, 500]}
                                    showNavigationButtons={true}
                                    showInfo={true}
                                    visible={true}
                                />
                                <Selection
                                    mode="multiple"
                                    selectAllMode={'allPages'}
                                    showCheckBoxesMode={'always'}
                                />
                                <FilterRow visible={true}
                                    applyFilter="auto" />

                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true}
                                    width={285}
                                    placeholder={t("search...")} />
                                <ColumnChooser width={350} height={400}
                                    enabled={true}
                                    mode="select"
                                    sortOrder="asc"
                                >
                                    <Search enabled />
                                </ColumnChooser>
                                <Column alignment="left" dataField="id" visible={false}
                                    caption={t('id')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="municipalityId"
                                    visible={initDataTesCodeValues.municipalities?.length !== 0 && initDataTesCodeValues.municipalities !== undefined}
                                    allowHeaderFiltering={true}
                                    caption={t("municipality")}
                                >
                                    <Search enabled />
                                    <Lookup
                                        dataSource={SortObjectByPropName(initDataTesCodeValues?.municipalities, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                    />
                                    <HeaderFilter dataSource={initDataTesCodeValues?.municipalities?.map(x => ({ text: x.name, value: x.id }))}>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="jurisdictionId"
                                    visible={initDataTesCodeValues.jurisdictions?.length !== 0 && initDataTesCodeValues.jurisdictions !== undefined}
                                    allowHeaderFiltering={true}
                                    caption={t("jurisdiction")}
                                >
                                    <Search enabled />
                                    <Lookup
                                        dataSource={SortObjectByPropName(initDataTesCodeValues?.jurisdictions, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                    />
                                    <HeaderFilter dataSource={initDataTesCodeValues?.jurisdictions?.map(x => ({ text: x.name, value: x.id }))}>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="divisionId"
                                    allowSorting={true}
                                    visible={false}
                                    caption={t("division")}
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup
                                        dataSource={SortObjectByPropName(customerDivisions!, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                    />
                                </Column>
                                <Column dataField="year" caption={t("year")} visible={true}
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="patternType" caption={t("patternType")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup
                                        dataSource={SortObjectByPropName(lstPatternType, "name")}
                                        valueExpr="value"
                                        displayExpr="name"
                                    />
                                </Column>
                                <Column
                                    dataField="countCode"
                                    allowSorting={false}
                                    caption={t('countCode')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup
                                        dataSource={SortObjectByPropName(lstCountCode, "name")}
                                        valueExpr="value"
                                        displayExpr="name"
                                    />
                                </Column>
                                <Column
                                    dataField="alphaCode"
                                    allowSorting={false}
                                    caption={t('alphaCode')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup
                                        dataSource={SortObjectByPropName(lstAlphaCodeType, "name")}
                                        valueExpr="value"
                                        displayExpr="name"
                                    />
                                </Column>
                                <Column dataField="aadt" caption={t("aadt")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="truckAADT" caption={t("truckAADT")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="mpAadt" caption={t("mpAadt")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="sadt" caption={t("sadt")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="sawdt" caption={t("sawdt")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="wadt" caption={t("wadt")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="factorSadt"
                                    allowSorting={true}
                                    allowHeaderFiltering={false}
                                    caption={t("factorSadt")}
                                    format={{ type: 'fixedPoint', precision: 2 }}
                                >
                                    <Search enabled />
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="factorWadt"
                                    allowSorting={true}
                                    allowHeaderFiltering={false}
                                    caption={t("factorWadt")}
                                    format={{ type: 'fixedPoint', precision: 2 }}
                                >
                                    <Search enabled />
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="dirSplitPercent"
                                    allowSorting={true}
                                    allowHeaderFiltering={false}
                                    caption={t("dirSplitPercent")}
                                    format={{ type: 'fixedPoint', precision: 2 }}
                                >
                                    <Search enabled />
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="dirSplitHigherVolDirection"
                                    allowSorting={true}
                                    allowHeaderFiltering={false}
                                    caption={t("dirSplitHigherVolDirection")}
                                    format={{ type: 'fixedPoint', precision: 2 }}
                                >
                                    <Search enabled />
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="dhvPercent"
                                    allowSorting={true}
                                    allowHeaderFiltering={false}
                                    caption={t("dhvPercent")}
                                    format={{ type: 'fixedPoint', precision: 2 }}
                                >
                                    <Search enabled />
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="truckPercent"
                                    allowSorting={true}
                                    allowHeaderFiltering={false}
                                    caption={t("truckPercent")}
                                    format={{ type: 'fixedPoint', precision: 2 }}
                                >
                                    <Search enabled />
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="yearlyPercentChangeInAADT"
                                    allowSorting={true}
                                    allowHeaderFiltering={false}
                                    caption={t("yearlyPercentChangeInAADT")}
                                    format={{ type: 'fixedPoint', precision: 2 }}
                                >
                                    <Search enabled />
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="tenYearPercentChangeInAADT"
                                    allowSorting={true}
                                    allowHeaderFiltering={false}
                                    caption={t("tenYearPercentChangeInAADT")}
                                    format={{ type: 'fixedPoint', precision: 2 }}
                                >
                                    <Search enabled />
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="confidenceIndex" caption={t("confidenceIndex")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="confidenceIndexPercent"
                                    allowSorting={true}
                                    allowHeaderFiltering={false}
                                    caption={t("confidenceIndexPercent")}
                                    format={{ type: 'fixedPoint', precision: 2 }}
                                >
                                    <Search enabled />
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="matchingAlgorithmResultType"
                                    allowSorting={false}
                                    caption={t('matchingAlgorithmResultType')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup
                                        dataSource={SortObjectByPropName(lstMatchingAlgorithmResultType, "name")}
                                        valueExpr="value"
                                        displayExpr="name"
                                    />
                                </Column>
                                <Column
                                    dataField="trendingStatus"
                                    allowSorting={false}
                                    caption={t('trendingStatus')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup
                                        dataSource={SortObjectByPropName(lstTrendingResultStatus, "name")}
                                        valueExpr="value"
                                        displayExpr="name"
                                    />
                                </Column>
                            </DataGrid>
                        </div>
                    </div>

                    {/* Map Popup */}
                    <Popup
                        width={"50%"}
                        height={"auto"}
                        visible={showMap}
                        resizeEnabled={true}
                        showTitle={true}
                        title={t("map")}
                        hideOnOutsideClick={false}
                        showCloseButton={true}
                        onHiding={() => setShowMap(false)}
                    >
                        <GeneralGisMapManager
                            tesModule={TesMapModule.AADT}
                            dataGridRef={dataGridRef}
                            showGis={showMap}
                            setShowMap={setShowMap}
                            locId="locationId"
                        />
                    </Popup>

                    {/*Grid Filter*/}
                    <GridFilter
                        dataGridRef={dataGridRef}
                        setShowModal={setShowFilter}
                        showModal={showFilter}
                        tesModule={TesMapModule.Study}
                    />
                </React.Fragment>
            </div>
        </Permission >
    );
}
export default TrafficSectionAADTs;