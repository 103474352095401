import {
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";
import Expand from "@arcgis/core/widgets/Expand";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import { IGeoJson } from "../../../../types/arcGis/arcGisTypes";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import Graphic from "@arcgis/core/Graphic";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import Point from "@arcgis/core/geometry/Point";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import LabelClass from "@arcgis/core/layers/support/LabelClass";

type TProps = {
  showLayer: boolean;
  legendExpand: Expand | null;
  map: Map | null;
  view: MapView | null;
  initDataGis: MapSetting | null | undefined;
  collisionGeoJsonData: IGeoJson | null;
  reCreateLayerEvenItExists?: boolean;
};

export const useCollisionBaseOnCoordinateLayer = ({
  showLayer,
  legendExpand,
  map,
  view,
  initDataGis,
  collisionGeoJsonData,
  reCreateLayerEvenItExists = false,
}: TProps) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "ClusterOfCollisionCoordination")) {
          removeLayer(map, "ClusterOfCollisionCoordination");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      collisionGeoJsonData &&
      collisionGeoJsonData?.features?.length > 0
    ) {
      if (isLayerExist(map, "ClusterOfCollisionCoordination")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "ClusterOfCollisionCoordination");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);

      if (isCancelled.current) {
        setIsLayerLoading(false);
        isUnderConstruction.current = false;
        return;
      }
      setIsLayerLoading(true);
      isUnderConstruction.current = true;
      const clusterConfig = {
        type: "cluster",
        clusterRadius: "100px",
        popupTemplate: {
          title: "Collision summary",
          content: [
            {
              type: "text",
              text: "{cluster_count} collision occurred in this area",
            },
          ],
        },
        clusterMinSize: "24px",
        clusterMaxSize: "60px",
        labelingInfo: [
          {
            deconflictionStrategy: "none",
            labelExpressionInfo: {
              expression: "Text($feature.cluster_count, '#,###')",
            },
            symbol: new TextSymbol({
              color: "#424242",
              font: {
                weight: "bold",
                family: "Noto Sans",
                size: "12px",
              },
            }),
            labelPlacement: "center-center",
          },
        ],
      };

      const graphics = collisionGeoJsonData.features.map((place) => {
        return new Graphic({
          attributes: {
            ObjectId: place.id,
          },
          geometry: new Point({
            longitude: place.geometry.coordinates[0],
            latitude: place.geometry.coordinates[1],
            // type: "point",
          }),
        });
      });
      const clusterLayer = new FeatureLayer({
        id: "ClusterOfCollisionCoordination",
        title: "Cluster of Collision Coordinations",
        source: graphics,
        objectIdField: "ObjectId",
        featureReduction: {
          type: "cluster",
          clusterRadius: "120px",
          clusterMinSize: 22,
          clusterMaxSize: 48,
          labelingInfo: [
            new LabelClass({
              deconflictionStrategy: "none",
              labelExpressionInfo: {
                expression: "Text($feature.cluster_count, '#,###')",
              },
              symbol: new TextSymbol({
                color: "#424242",
                font: {
                  weight: "bold",
                  family: "Noto Sans",
                  size: 9,
                },
              }),
              labelPlacement: "center-center",
            }),
          ],
          popupTemplate: {
            title: "Collision summary",
            content: [
              {
                type: "text",
                text: "{cluster_count} collision occurred in this area",
              },
            ],
          },
        },
        renderer: new SimpleRenderer({
          symbol: new SimpleMarkerSymbol({
            size: 4,
            color: "#f0b70d",
            outline: {
              color: "rgba(42, 42, 42, 0.5)",
              width: 5,
            },
          }),
        }),
        popupTemplate: {
          title: "Collision",
            content: [
                {
                type: "fields",
                fieldInfos: [
                    {
                    fieldName: "ObjectId",
                    label: "Collision ID",
                    },
                ],
                },
            ],
        },
      });

      clusterLayer.load().then(() => {
        if (isCancelled.current) {
          setIsLayerLoading(false);
          isUnderConstruction.current = false;
          return;
        }
        map.add(clusterLayer);
        if (legendExpand) legendExpand.expanded = false;
      });

      layerSuccessfullyLoadedInDomCallback(view, clusterLayer, () => {
        setIsLayerLoading(false);
        isUnderConstruction.current = false;
      });
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    collisionGeoJsonData,
    reCreateLayerEvenItExists,
  ]);

  return {
    isCollisionBaseOnCoordinateLayerLoading: isLayerLoading,
  };
};
