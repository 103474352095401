import { Form, Switch, TextBox } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import { t } from "i18next";
import React from "react";
import Permission from "../../../../../components/permission/permision";
import { TMSPermissions } from "../../../../../constants/Permissions";

interface IProps {
  selectedLang: any;
  setSelectedLang: React.Dispatch<React.SetStateAction<any>>
  setDataChanged: React.Dispatch<React.SetStateAction<any>>
}

const Details = (props: IProps) => {

  function onValueChanged(key: string, value: any) {
    props.setSelectedLang({ ...props.selectedLang, [key]: value })
    props.setDataChanged(true)
  }

  return (
    <Permission
      allowed={[TMSPermissions.TMS_Admin]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className="row">
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("name")}
                labelMode='floating'
                value={props.selectedLang.languageName}
                onValueChange={(e) => onValueChanged("languageName", e)}
                className="modalInput"
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("languageCode")}
                labelMode='floating'
                value={props.selectedLang.languageCode}
                onValueChange={(e) => onValueChanged("languageCode", e)}
                className="modalInput"
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("imageUrl")}
                labelMode='floating'
                value={props.selectedLang.imageAddress}
                onValueChange={(e) => onValueChanged("imageAddress", e)}
                className="modalInput"
                style={{ margin: "-2rem 0 -0.5rem 0" }}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("description")}
                labelMode='floating'
                value={props.selectedLang.description}
                onValueChange={(e) => onValueChanged("description", e)}
                className="modalInput"
                style={{ margin: "-2rem 0 -0.5rem 0" }}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
                <div>{t("isGlobalDefault")}</div>
                <Switch
                  value={props.selectedLang.isGlobalDefault}
                  onValueChange={(e) => onValueChanged("isGlobalDefault", e)}
                />
            </SimpleItem>
          </Form>
        </div>
      </React.Fragment>
    </Permission>
  )
}

export default Details;