import i18n from "../../../../react-i18next";

const tabTitles = [
    {
        id: 0,
        text: i18n.t('details'),
        icon: 'fa-solid fa-ellipsis',
        content: 'Details tab content',
    },
    {
        id: 1,
        text: i18n.t('location'),
        icon: 'fa-solid fa-location-dot',
        content: 'Location tab content',
    },
    {
        id: 2,
        text: i18n.t('share'),
        icon: 'fa-solid fa-share-nodes',
        content: 'Permissions tab content',
    },
];
export default tabTitles;

