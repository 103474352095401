import { t } from "i18next";

function getTitle(pathname: string, additionalOptions: any): string {
    let title = '';
    
    // Add case pathname === if path doesn't include Params. Otherwise add case pathname.startsWith
    switch (true) {

      //home
      case pathname === ('/home'):
        title = `${t("home")} ${additionalOptions}`;
        break;

      //Dashboard
      case pathname === ('/dashboard/trafficOperation'):
        title = `${t("trafficOperation")} ${additionalOptions}`;
        break;
      case pathname === ('/dashboard/transportationSafety'):
        title = `${t("transportationSafety")} ${additionalOptions}`;
        break;

      //Infrastructure
      case pathname === ('/infrastructure/intersections'):
        title = `${t("intersections")} ${additionalOptions}`;
        break;
      case pathname.startsWith('/infrastructure/intersectionDetails'):
        title = `${t("intersectionDetails")} ${additionalOptions}`;
        break;
      case pathname === ('/infrastructure/roadSegments'):
        title = `${t("roadSegments")} ${additionalOptions}`;
        break;
      case pathname.startsWith('infrastructure/roadSegmentDetails'):
        title = `${t("roadSegmentDetails")} ${additionalOptions}`;
        break;
      case pathname === ('/infrastructure/streets'):
        title = `${t("streets")} ${additionalOptions}`;
        break;
      case pathname === ('/infrastructure/siteList'):
        title = `${t("siteList")} ${additionalOptions}`;
        break;
      case pathname.startsWith('/infrastructure/siteListDetails'):
        title = `${t("siteListDetails")} ${additionalOptions}`;
        break;
      case pathname === ('/infrastructure/countStation'):
        title = `${t("countStation")} ${additionalOptions}`;
        break;
      case pathname.startsWith('/infrastructure/countStationDetails'):
        title = `${t("countStationDetails")} ${additionalOptions}`;
        break;
      case pathname === ('/infrastructure/trafficSection'):
        title = `${t("trafficSection")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/infrastructure/trafficSectionDetails'):
        title = `${t("trafficSectionDetails")} ${additionalOptions}`;
        break;
      case pathname === ('/infrastructure/updateImport/fieldDataSource'):
        title = `${t("fieldDataSource")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/infrastructure/updateImport/fieldDataSourceDetails'):
        title = `${t("fieldDataSourceDetails")} ${additionalOptions}`;
        break;
      case pathname === ('/infrastructure/updateImport/loadData'):
        title = `${t("loadData")} ${additionalOptions}`;
      break;
      case pathname === ('/infrastructure/settings/fields'):
        title = `${t("fields")} ${additionalOptions}`;
      break;
      case pathname === ('/infrastructure/settings/codeGroups'):
        title = `${t("codeGroups")} ${additionalOptions}`;
      break;
      case pathname === ('/infrastructure/settings/municipalityPopulation'):
        title = `${t("municipalityPopulation")} ${additionalOptions}`;
      break;
      case pathname === ('/infrastructure/settings/intersectionTesFields'):
        title = `${t("intersectionTesFields")} ${additionalOptions}`;
      break;
      case pathname === ('/infrastructure/settings/roadSegmentTesFields'):
        title = `${t("roadSegmentTesFields")} ${additionalOptions}`;
      break;
      case pathname === ('/infrastructure/settings/roadSegmentTesFields'):
        title = `${t("roadSegmentTesFields")} ${additionalOptions}`;
      break;

      //Collisions
      case pathname === ('/collision/collisions'):
        title = `${t("collisions")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/collision/collisionDetails/'):
        title = `${t("collisionDetails")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/peopleInvolved'):
        title = `${t("peopleInvolved")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/vehicles'):
        title = `${t("vehicles")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/drafts'):
        title = `${t("drafts")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/import/xml/xmlImportSettings'):
        title = `${t("xmlImportSettings")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/collision/import/xml/xmlImportSettingDetails'):
        title = `${t("xmlImportSettingDetails")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/import/xml/xmlImport'):
        title = `${t("xmlImport")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/import/xml/xmlImportLogs'):
        title = `${t("xmlImportLogs")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/collision/import/xml/xmlImportLogDetails'):
        title = `${t("xmlImportLogDetails")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/geoCodeGroup/geoCodeGroups'):
        title = `${t("geoCodeGroups")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/collision/geoCodeGroup/geoCodeGroupDetails'):
        title = `${t("geoCodeGroupDetails")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/simplifiedCollisions'):
        title = `${t("standardCollision")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/settings/fields'):
        title = `${t("fields")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/settings/codeGroups'):
        title = `${t("codeGroups")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/settings/tesFields'):
        title = `${t("tesFields")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/settings/validationRules'):
        title = `${t("validationRules")} ${additionalOptions}`;
      break;
      case pathname === ('/collision/settings/confirmationLevels'):
        title = `${t("confirmationLevels")} ${additionalOptions}`;
      break;

      //Hospitalizations
      case pathname === ('/hospitalization/hospitalizations'):
        title = `${t("hospitalizations")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/hospitalization/hospitalizationDetails'):
        title = `${t("hospitalizationDetails")} ${additionalOptions}`;
      break;
      
      //Traffic Study
      case pathname === ('/trafficStudy/aadts/intersectionAADTs'):
        title = `${t("intersectionAADTs")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/aadts/roadSegmentAADTs'):
        title = `${t("roadSegmentAADTs")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/trafficStudy/aadts/aadtDetails'):
        title = `${t("aadtDetails")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/trafficStudy/aadts/trafficSectionAADTs'):
        title = `${t("trafficSectionAADTs")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/trafficStudy/aadts/trafficSectionAADTDetails'):
        title = `${t("trafficSectionAADTDetails")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/studies/allStudies'):
        title = `${t("allStudies")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/studies/tmc'):
        title = `${t("tmcStudies")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/studies/volume'):
        title = `${t("volumeStudies")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/studies/speed'):
        title = `${t("speedStudies")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/trafficStudy/studies/studyDetails'):
        title = `${t("studyDetails")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/import/importData'):
        title = `${t("importData")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/import/numetricConvertor'):
        title = `${t("numetricConvertor")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/import/importLogs'):
        title = `${t("importLogs")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/setups/reportingPeriod'):
        title = `${t("reportingPeriod")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/setups/trafficCounters'):
        title = `${t("trafficCounters")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/trafficStudy/setups/trafficCountersDetails'):
        title = `${t("trafficCountersDetails")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/setups/signalWarrantSetting'):
        title = `${t("signalWarrantSetting")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/setups/allWayStopWarrantSetting'):
        title = `${t("allWayStopWarrantSetting")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/setups/AADTPatterns'):
        title = `${t("AADTPatterns")} ${additionalOptions}`;
      break;
      case pathname.startsWith('/trafficStudy/setups/AADTPatternDetails/'):
        title = `${t("AADTPatternDetails")} ${additionalOptions}`;
      break;
      case pathname === ('/trafficStudy/setups/countDurations'):
        title = `${t("countDurations")} ${additionalOptions}`;
      break;

      //Safety


      //Default
      default:
        title = '';
      
    }
    return title !== '' ? `${title} | TES` : '';
  }
  
  export default getTitle;
  